import { LegalNotice, getLegalNoticeEndpoint } from '@cp-ie/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<LegalNotice>({
    contentName: 'legalNotice',
    contentEndpoint: getLegalNoticeEndpoint,
});

export default reducer;
export const fetchLegalNotice = fetchContent;
