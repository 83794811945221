import moment from 'moment-timezone';
import numeral from 'numeral';
import { formatCpDate, CPDate } from '@cp-shared-6/common-utilities';

import 'moment/locale/en-ie';
import 'numeral/locales/en-gb';

// Check out https://momentjs.com/timezone/
export const IE_TIMEZONE = 'Europe/Dublin';

moment.tz.setDefault(IE_TIMEZONE);

export const DATE_FORMAT = 'DD/MM/YYYY';

// TODO Check the format definitions
const locale = 'en';
export const numberFormat = '0,0';
export const numberFormatRoundedOne = '0,0.0';
const numeralFormat = '0o';
const currencyFormat = '0,0.00';
const dateFormat = 'DD/MM/YYYY';
export const isoDateFormat = 'YYYY-MM-DD';
export function changeLocale(locale: 'en'): void {
    moment.locale(locale);
    numeral.locale(locale);
}

changeLocale(locale);

export function formatAsCurrency(value?: number): string {
    return typeof value === 'number' ? `€${numeral(value).format(currencyFormat)}` : '';
}

export function formatAsDate(date?: moment.Moment | CPDate): string {
    return date ? formatCpDate(date).format(dateFormat) : '';
}

export function formatAsNumber(value?: number, format: string = numberFormat): string {
    return typeof value === 'number' ? numeral(value).format(format) : '';
}

export function formatAsNumeral(value?: number): string {
    return typeof value === 'number' ? numeral(value).format(numeralFormat) : '';
}

export function formatAsDistance(value?: number): string {
    return typeof value === 'number' ? numeral(value).format(numberFormat) : '';
}

export function formatAsEmission(value?: number): string {
    return typeof value === 'number' ? `${numeral(value).format(numberFormat)} g/km` : '';
}

export function formatAsIban(iban?: string): string {
    return (
        iban
            ?.replace(/[^\dA-Z]/g, '')
            .replace(/(.{4})/g, '$1 ')
            .trim() ?? ''
    );
}

export function formatAsFileSize(bytes: number, decimal: number): string {
    const unit = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (!bytes && '0 Bytes') || (bytes / Math.pow(1024, i)).toFixed(decimal) + ' ' + unit[i];
}
