import React, { useState } from 'react';
import { CpDataApi } from 'cp-xhr';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-6/common-utilities';
import { parseErrorResponse } from '@cp-shared-6/frontend-integration';
import {
    CustomerRegistrationData,
    CustomerRegistrationError,
    getAccountVerificationConfirmationEndpoint,
    getAccountVerificationRequestEndpoint,
    getContractNumberVerificationEndpoint,
    RegistrationSmsConfirmation,
    RegistrationSmsConfirmationError,
} from '@cp-ie/common';
import { Spinner, useAnalyticsActionTracker, useAnalyticsPageViewTracker } from '@cp-shared-6/frontend-ui';
import { introductionPagePath } from 'components/navigation/paths';
import { FormProps, SmsIdentificationForm } from './sms-identification-form';
import { isAfterRegistrationLocalStorageKey, isFirstRegistrationLocalStorageKey } from '../../../../config';
import { Layout } from '@vwfs-bronson/bronson-react';

type SmsIdentificationProps = {
    lastFourDigitsOfPhoneNumber: string;
    customerId: string;
    customerData: CustomerRegistrationData;
    contractNumber: string;
};

type RequestProps = {
    isLoading: boolean;
    errorCode?: WithDefaultCpIntegrationErrors<CustomerRegistrationError | RegistrationSmsConfirmationError>;
};

export const SmsIdentification: React.FC<SmsIdentificationProps> = ({
    lastFourDigitsOfPhoneNumber,
    customerData,
    customerId,
    contractNumber,
}) => {
    const [request, setRequest] = useState<RequestProps>({ isLoading: false });

    useAnalyticsPageViewTracker('enterSmsCode', !!lastFourDigitsOfPhoneNumber && !!customerData && !!customerId);
    const { onAction: onSuccess } = useAnalyticsActionTracker('onEnterSmsCodeSuccess');
    const { onAction: onError } = useAnalyticsActionTracker('onConfirmIdentiyAuthFailed');

    const handleResendCode = (): void => {
        setRequest({ isLoading: true });
        const endpoint = !!contractNumber
            ? getContractNumberVerificationEndpoint()
            : getAccountVerificationRequestEndpoint();

        const payload = !!contractNumber ? { contractNumber } : customerData;

        CpDataApi.post(endpoint, payload)
            .then(() => {
                setRequest({ isLoading: false });
            })
            .catch(error => {
                const errorCode = !!contractNumber
                    ? parseErrorResponse<RegistrationSmsConfirmationError>(error).code
                    : parseErrorResponse<CustomerRegistrationError>(error).code;
                setRequest({
                    isLoading: false,
                    errorCode,
                });
            });
    };

    const handleSmsCodeConfirmation = (values: FormProps): void => {
        setRequest({ isLoading: true });
        const requestBody: RegistrationSmsConfirmation = {
            verificationCode: values.authorizationCode,
            customerId,
        };
        CpDataApi.post(getAccountVerificationConfirmationEndpoint(), requestBody)
            .then(() => {
                setRequest({ isLoading: false });
                onSuccess(true);
                window.localStorage.setItem(isFirstRegistrationLocalStorageKey, 'true');
                window.localStorage.setItem(isAfterRegistrationLocalStorageKey, 'true');
                window.location.href = introductionPagePath();
            })
            .catch(error => {
                const errorCode = parseErrorResponse<RegistrationSmsConfirmationError>(error).code;
                setRequest({
                    isLoading: false,
                    errorCode,
                });
                onError();
            });
    };

    const { isLoading, errorCode } = request;

    return (
        <>
            {isLoading && (
                <Layout.Item>
                    <Spinner fullPage={true} />
                </Layout.Item>
            )}
            <SmsIdentificationForm
                onResendCode={handleResendCode}
                onSmsCodeConfirmation={handleSmsCodeConfirmation}
                errorCode={errorCode}
                lastFourDigitsOfPhoneNumber={lastFourDigitsOfPhoneNumber}
            />
        </>
    );
};
