import { AnyAction, combineReducers, createAction } from '@reduxjs/toolkit';
import contractsReducer from 'components/contracts/ContractsSlice';
import dealerDetailsReducer from 'components/contracts/dealer-details/DealerDetailsSlice';
import financialDetailsReducer from 'components/contracts/financial-details/FinancialDetailsSlice';
import vehicleDetailsReducer from 'components/contracts/vehicle-details-and-image/VehicleDetailsSlice';
import vehicleImageReducer from 'components/contracts/vehicle-details-and-image/VehicleImageSlice';
import forbiddenErrorPageReducer from 'components/error-pages/forbidden/ForbiddenErrorPageSlice';
import genericErrorPageReducer from 'components/error-pages/generic/GenericErrorPageSlice';
import notFoundErrorPageReducer from 'components/error-pages/not-found/NotFoundErrorPageSlice';
import unauthorizedErrorPageReducer from 'components/error-pages/unauthorized/UnauthorizedErrorPageSlice';
import faqReducer from 'components/faq/FaqSlice';
import iconFooterReducer from 'components/footer/icon-footer/IconFooterSlice';
import legalFooterReducer from 'components/footer/legal-footer/LegalFooterSlice';
import greetingReducer from 'components/greeting/GreetingSlice';
import introductionPageTeaserReducer from 'components/introduction-page-teaser/IntroductionPageTeaserSlice';
import landingPageTeaserReducer from 'components/landing-page-teaser/LandingPageTeaserSlice';
import legalNoticeReducer from 'components/legal-notice/LegalNoticeSlice';
import logoutSuccessReducer from 'components/logout-success/LogoutSuccessSlice';
import marketingCardsReducer from 'components/marketing-cards/MarketingCardsSlice';
import productRoutingReducer from 'components/marketing-cards/productRoutingSlice';
import marketingPreferencesReducer from 'components/my-profile/marketing-preferences-sections/MarketingPreferencesSlice';
import myProfileReducer from 'components/my-profile/MyProfileSlice';
import requestReducer from 'components/request/RequestSlice';
import thirdPartyLicensesReducer from 'components/third-party-licenses/ThirdPartyLicensesSlice';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const appReducer = combineReducers({
    router: connectRouter(history),
    greeting: greetingReducer,
    contracts: contractsReducer,
    myProfile: myProfileReducer,
    financialDetails: financialDetailsReducer,
    marketingPreferences: marketingPreferencesReducer,
    request: requestReducer,
    vehicleDetailsAndImage: combineReducers({
        vehicleImages: vehicleImageReducer,
        vehicleDetails: vehicleDetailsReducer,
    }),
    productRouting: productRoutingReducer,
    dealerDetails: dealerDetailsReducer,
    content: combineReducers({
        landingPageTeaser: landingPageTeaserReducer,
        introductionPageTeaser: introductionPageTeaserReducer,
        legalNotice: legalNoticeReducer,
        notFoundErrorPage: notFoundErrorPageReducer,
        forbiddenErrorPage: forbiddenErrorPageReducer,
        genericError: genericErrorPageReducer,
        legalFooter: legalFooterReducer,
        unauthorizedErrorPage: unauthorizedErrorPageReducer,
        iconFooter: iconFooterReducer,
        faq: faqReducer,
        thirdPartyLicenses: thirdPartyLicensesReducer,
        marketingCards: marketingCardsReducer,
        logoutSuccess: logoutSuccessReducer,
    }),
});

export type RootState = ReturnType<typeof appReducer>;

export const resetStore = createAction('store/reset');

const rootReducer = (state: RootState | undefined, action: AnyAction): RootState => {
    if (action.type === resetStore.toString()) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
