import React from 'react';
import { formatCpDate } from '@cp-shared-6/common-utilities';
import { LegalFooter as LegalFooterShared } from '@cp-shared-6/frontend-ui';
import { LegalFooterLinkCMS } from '@cp-shared-6/apis';
import { LegalFooter } from '@cp-ie/common';

import { legalNoticePath, thirdPartyLicensesPagePath } from '../../../navigation/paths';

const urls = [
    { pathName: 'legal-notice', path: legalNoticePath() },
    {
        pathName: 'cookies-policy',
        externalPath: 'https://www.vwfs.ie/cookies_policy.html',
    },
    {
        pathName: 'privacy-policy',
        externalPath: 'https://www.vwfs.ie/privacy_policy.html',
    },
    { pathName: 'third-party-licenses', path: thirdPartyLicensesPagePath() },
];

const getURL = (name: string, isExternal?: boolean): string | undefined => {
    const { externalPath, path } = urls.find(({ pathName }) => pathName === name) || { externalPath: '', path: '' };
    return isExternal ? externalPath : path;
};

const createLinksArray = (names: object): LegalFooterLinkCMS[] =>
    Object.entries(names).map(
        ([name, text]: [string, string]): LegalFooterLinkCMS => ({
            text,
            url: getURL(name),
            externalUrl: getURL(name, true),
        }),
    );

export const LegalFooterUi: React.FC<{ legalFooter?: LegalFooter }> = ({ legalFooter }) => {
    const currentYear = formatCpDate()
        .toMoment()
        .year();

    return legalFooter ? (
        <LegalFooterShared
            footnotes={legalFooter?.footnotes}
            copyrightText={legalFooter?.copyrightText && `${legalFooter?.copyrightText} ${currentYear}`}
            links={createLinksArray(legalFooter?.links)}
        />
    ) : null;
};
