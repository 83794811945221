import React from 'react';
import { useTranslation } from 'react-i18next';
import { Greeting as GreetingShared } from '@cp-shared-6/frontend-ui';
import { GreetingMessage } from '@cp-ie/common';

export const GreetingUi: React.FC<{ greeting?: GreetingMessage }> = ({ greeting }) => {
    const { t } = useTranslation('greeting');
    const { firstName } = greeting || { name: '' };

    const fullGreetingText = t('messageText', { greetingName: firstName });

    return <GreetingShared fullGreetingText={fullGreetingText} />;
};
