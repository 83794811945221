import { DigitalSignRequest, irelandCountryCode, SepaMandateForDownload } from '@cp-ie/common';

export const SepaDownloadResponseData: SepaMandateForDownload = {
    fileName: 'fileName.pdf',
    fileContent: 'aGFsbG8=',
};

export const DigitalSignData: DigitalSignRequest = {
    bankAccountHolder: 'Mickey Mouse',
    iban: 'DE89370400440532013000',
    email: 'test@example.com',
    mobilePhone: { countryCode: irelandCountryCode, number: '838289455' },
    allContracts: false,
};
