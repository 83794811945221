import { Spinner } from '@cp-shared-6/frontend-ui';
import { ContentSection, Heading, Layout, Logo, PageWrap, Paragraph } from '@vwfs-bronson/bronson-react';
import React from 'react';

type CommonUserDetailsComponentProps = {
    title?: string;
    subtitle?: string;
    subsubtitle?: string;
    isLoadingSpinner?: boolean;
};

export const CommonUserDetailsComponent: React.FC<CommonUserDetailsComponentProps> = ({
    title,
    subtitle,
    subsubtitle,
    isLoadingSpinner,
    children,
}) => {
    return (
        <>
            {isLoadingSpinner && <Spinner fullPage />}
            <ContentSection>
                <PageWrap size={'xsmall'}>
                    <Layout className={'u-mt-large'}>
                        <Layout.Item default={'1/1'}>
                            <Logo className={'u-block-center'} />
                            <Heading className={'u-text-center u-mt'} level={1}>
                                {title}
                            </Heading>
                            <Heading className={'u-text-center u-mt'} level={3}>
                                {subtitle}
                            </Heading>
                            <Paragraph className={'u-text-center u-mt'}>{subsubtitle}</Paragraph>
                        </Layout.Item>
                        <Layout.Item default={'1/1'}>{children}</Layout.Item>
                    </Layout>
                </PageWrap>
            </ContentSection>
        </>
    );
};
