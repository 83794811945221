import React, { useState } from 'react';
import { ContractDataWithLinks } from '@cp-ie/common';
import { LicensePlate } from '../../license-plate';
import { Card, Heading } from '@vwfs-bronson/bronson-react';
import { ContractHeader } from '@cp-shared-6/frontend-ui';
import { useTranslation } from 'react-i18next';
import { OptionsOverview } from './options-overview';
import { ContractEndOptionsView } from './enums';
import { ErrorNotification } from '../../notifications/error/ErrorNotification';
import { DealerInformationView } from './dealer-information-view';

export const ContractEndOptionsUi: React.FC<{
    contract?: ContractDataWithLinks;
}> = ({ contract }) => {
    const { t } = useTranslation('contract-end-options');
    const [contractEndOptionsView, setContractEndOptionsView] = useState(ContractEndOptionsView.OPTIONS_OVERVIEW);

    if (!contract) {
        return <ErrorNotification testId={'no-connection-error'} />;
    }

    const { licensePlate, contractNumber = '', brand, model = '' } = contract;

    const licensePlateComponent = <LicensePlate registrationNumber={licensePlate} />;
    const carInformation = `${!!brand ? `${brand} ` : ''}${model}`;

    return (
        <>
            <Heading className="u-mt" level={1}>
                {contractEndOptionsView === ContractEndOptionsView.OPTIONS_OVERVIEW
                    ? t('options-overview.headline')
                    : t('dealer-information.headline')}
            </Heading>
            <Card element="article">
                <ContractHeader
                    carInformation={carInformation}
                    contractCategory={t('product-type')}
                    contractNumber={contractNumber}
                    contractIdentifier={licensePlateComponent}
                />
            </Card>
            {contractEndOptionsView === ContractEndOptionsView.DEALER_INFORMATION ? (
                <DealerInformationView handleBackClick={setContractEndOptionsView} contractNumber={contractNumber} />
            ) : (
                <OptionsOverview handleSubmit={setContractEndOptionsView} brand={brand} />
            )}
        </>
    );
};
