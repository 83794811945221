import { getUserRegistryStatusEndpoint, UserRegistryStatus } from '@cp-ie/common';
import {
    AuthenticationProvider,
    AutoLogout,
    createAuthenticationComponent,
    createAuthorization,
    createDecoratedRoute,
    Spinner,
} from '@cp-shared-6/frontend-ui';
import { autoLogoutPath, landingPagePath, registrationPagePath } from 'components/navigation/paths';
import { CpDataApi } from 'cp-xhr';
import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';

const commonAuthorizationOptions = {
    onLoading: <Spinner center />,
    onError: <Redirect to={landingPagePath()} />,
};

const Authentication = createAuthenticationComponent({
    onMissingAuthentication: <Redirect to={landingPagePath()} />,
});

const {
    Authorization: UserRegistrationAuthorization,
    AuthorizationProvider,
    useAuthorizationFlow,
} = createAuthorization({
    displayName: 'UserRegistrationAuthorization',
    authorizationDataProvider: async () => {
        try {
            const { data: registrationData } = await CpDataApi.get<UserRegistryStatus>(getUserRegistryStatusEndpoint());
            return { isAuthorized: registrationData.isRegistered, lastAccess: registrationData.lastAccess };
        } catch (e) {
            return { isAuthorized: false };
        }
    },
    onMissingAuthorization: <Redirect to={registrationPagePath()} />,
    ...commonAuthorizationOptions,
});

const AuthProviders: FC = ({ children }) => (
    <AuthenticationProvider loadingComponent={<Spinner center />}>
        <AuthorizationProvider>
            <AutoLogout idleTimeInMinutes={10} redirectUri={window.location.origin + autoLogoutPath()}>
                {children}
            </AutoLogout>
        </AuthorizationProvider>
    </AuthenticationProvider>
);

export const AuthenticatedRoute = createDecoratedRoute('AuthenticatedRoute', Authentication);
export const AuthorizedRoute = createDecoratedRoute('AuthorizedRoute', UserRegistrationAuthorization);
export * from './AxiosConfiguration';
export { AuthProviders, useAuthorizationFlow };
