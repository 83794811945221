import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MyProfile } from '../../components/my-profile';

export const MyProfilePage: React.FC = () => {
    const { t } = useTranslation('my-profile');
    const title = t('headline');

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{title}</Heading>
            <p>{t('description')}</p>
            <MyProfile />
        </ContentSection>
    );
};
