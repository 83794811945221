import { ContractsLoadingPlaceholder } from '@cp-shared-6/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { ContractsUi } from './ui';
import { useContracts } from './useContracts';

const ContractsWithHandlers = withLoadingAndNoConnectionHandler(ContractsUi);

export const Contracts: React.FC = () => {
    const { data: contracts, isLoading, loadingError } = useContracts();

    return (
        <ContractsWithHandlers
            isLoading={isLoading}
            contracts={contracts}
            hasError={!!loadingError}
            loadingPlacehloder={
                <ContractsLoadingPlaceholder
                    firstContractExpanded={true}
                    contractDetailsRows={2}
                    numberOfContracts={2}
                />
            }
        />
    );
};
