import React from 'react';
import { LegalNotice, SimpleTeaser } from '@cp-ie/common';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { HeroImage } from '@cp-shared-6/frontend-ui';

type LegalNoticeUIProps = {
    legalNotice?: LegalNotice;
};

export const LegalNoticeTeaserUI: React.FC<{ teaser?: SimpleTeaser }> = ({ teaser }) =>
    teaser ? (
        <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} shallow={false} inverted={!!teaser.isInverted} />
    ) : null;

export const LegalNoticeContentUI: React.FC<{ content?: string }> = ({ content }) =>
    content ? (
        <ContentSection pageWrapSize="medium">
            <div dangerouslySetInnerHTML={{ __html: content }} />
        </ContentSection>
    ) : null;

export const LegalNoticeUi: React.FC<LegalNoticeUIProps> = ({ legalNotice }) =>
    legalNotice ? (
        <>
            <LegalNoticeTeaserUI teaser={legalNotice.teaser} />
            <LegalNoticeContentUI content={legalNotice.legalNoticeContent} />
        </>
    ) : null;
