import { Contract } from '@cp-ie/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-6/common-utilities';
import { AbstractDataState, useGetSimpleApiDataWithTransformer } from '@cp-shared-6/frontend-integration';
import { useMemo } from 'react';
import { ContractsDataSelector } from './ContractsDataSelector';
import { fetchContracts } from './ContractsSlice';

type ContractTransformer = (contracts?: Contract[]) => Contract | undefined;

function getFilterForContractNumber(contractNumber?: string, encryptedContractNumber?: boolean): ContractTransformer {
    return (contracts?: Contract[]): Contract | undefined =>
        contracts?.filter(contract => {
            const filterVal = encryptedContractNumber ? contract.encryptedContractNumber : contract.contractNumber;
            return filterVal === contractNumber;
        })[0];
}

type ConfigProps = {
    encryptedContractNumber?: boolean;
};

/**
 * Fetches the contracts and filters for the contract with given contract id. If not found, undefined will be returned as data.
 * @param contractNumber
 */

export function useContract(
    contractNumber: string,
    config: ConfigProps = {},
): AbstractDataState<Contract | undefined, DefaultBusinessMarketApiErrorCode> {
    const { encryptedContractNumber } = config;
    const contractFilter = useMemo(() => getFilterForContractNumber(contractNumber, encryptedContractNumber), [
        contractNumber,
        encryptedContractNumber,
    ]);

    return useGetSimpleApiDataWithTransformer(fetchContracts, ContractsDataSelector, contractFilter);
}
