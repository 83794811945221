import { MarketingPreference } from '@cp-ie/common';
import { MarketingPreferencesFormValues } from './initialValues';

export const isDisabledMarketingPreferencesSubmit = (
    isEmailSelected: boolean,
    isContactEmailDefined: boolean,
    touchedEmailAddress: boolean,
    isValid: boolean,
    isSubmitEnabled = true,
): boolean => (isEmailSelected && !isContactEmailDefined && !touchedEmailAddress) || !isValid || !isSubmitEnabled;

export const isShownMarketingPreferencesEmail = (
    key: string,
    values: MarketingPreferencesFormValues,
    isContactEmailDefined: boolean,
): boolean => {
    return key === 'email' && !!values.preferences.email && !isContactEmailDefined;
};

export const prepareMarketingPreferencesRequestBody = (values: MarketingPreferencesFormValues): MarketingPreference => {
    return {
        ...values.preferences,
        ...(values.emailAddress ? { emailAddress: values.emailAddress } : {}),
        ...(values.confirmPrivacyPolicy ? { confirmPrivacyPolicy: values.confirmPrivacyPolicy } : {}),
    };
};
