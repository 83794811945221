import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { FinancialDetailsUi } from './ui';
import { ContractDetailsLoadingPlaceholder } from '@cp-shared-6/frontend-ui';
import { useFinancialDetails } from './useFinancialDetails';
import { ContractType } from '@cp-ie/common';

export type FinancialDetailsProps = {
    contractNumber: string;
    encryptedContractNumber: string;
    contractType?: ContractType;
    outstandingBalance?: number;
    inArrears?: boolean;
};

const FinancialDetailsWithHandlers = withLoadingAndNoConnectionHandler(FinancialDetailsUi);

export const FinancialDetails: React.FC<FinancialDetailsProps> = ({
    encryptedContractNumber,
    contractNumber,
    contractType,
    outstandingBalance,
    inArrears,
}) => {
    const { data: details, isLoading, loadingError } = useFinancialDetails(contractNumber);

    return (
        <FinancialDetailsWithHandlers
            isLoading={isLoading}
            financialDetails={details}
            encryptedContractNumber={encryptedContractNumber}
            hasError={!!loadingError}
            contractType={contractType}
            outstandingBalance={outstandingBalance}
            loadingPlacehloder={<ContractDetailsLoadingPlaceholder sections={[6, 1, 1]} />}
            inArrears={inArrears}
        />
    );
};
