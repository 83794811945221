import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { LogoutSuccessUi } from './ui';
import { useLogoutSuccess } from './useLogoutSuccess';
import { Skeleton, StaticPageLoadingPlaceholder } from '@cp-shared-6/frontend-ui';
import { Layout } from '@vwfs-bronson/bronson-react';

const LogoutWithHandlers = withLoadingAndNoConnectionHandler(LogoutSuccessUi);

type LogoutSuccessPageProps = {
    manualLogout: boolean;
};

export const LogoutSuccess: React.FC<LogoutSuccessPageProps> = ({ manualLogout }) => {
    const { cmsContent: logoutSuccess, isLoading, loadingError } = useLogoutSuccess();

    return (
        <LogoutWithHandlers
            isLoading={isLoading}
            logoutSuccess={logoutSuccess}
            hasError={!!loadingError}
            manualLogout={manualLogout}
            loadingPlacehloder={
                <StaticPageLoadingPlaceholder withPretitle withSubTitle withButton>
                    <Layout>
                        <Layout.Item default={`1/3`} s={'1/1'}>
                            <Skeleton height={244} />
                        </Layout.Item>
                        <Layout.Item default={`1/3`} s={'1/1'}>
                            <Skeleton height={244} />
                        </Layout.Item>
                        <Layout.Item default={`1/3`} s={'1/1'}>
                            <Skeleton height={244} />
                        </Layout.Item>
                    </Layout>
                </StaticPageLoadingPlaceholder>
            }
        />
    );
};
