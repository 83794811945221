import { Brand, ContractType } from '@cp-ie/common';
import { CPDate } from '@cp-shared-6/common-utilities';
import { Notification, Skeleton } from '@cp-shared-6/frontend-ui';
import { Button } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useGreeting } from '../../../greeting';
import { contractEndOptionsPath } from '../../../navigation/paths';
import { isLessThan9MonthsBeforeEndDate } from './helpers';

export type EndOfContractOptionsNotificationProps = {
    contractType?: ContractType;
    contractEndDate?: CPDate;
    contractNumber?: string;
    encryptedContractNumber?: string;
    isEligibility?: boolean;
    contractBrand?: Brand;
    className?: string;
};

export const EndOfContractOptionsNotification: React.FC<EndOfContractOptionsNotificationProps> = ({
    contractEndDate,
    encryptedContractNumber,
    isEligibility,
    contractType,
    contractBrand,
    className,
}) => {
    const { t } = useTranslation('contracts');
    const { data: greeting, isLoading } = useGreeting();

    const history = useHistory();

    const isValidBrand = (brand?: Brand): boolean =>
        brand === Brand.VW ||
        brand === Brand.AUDI ||
        brand === Brand.SEAT ||
        brand === Brand.SKODA ||
        brand === Brand.CUPRA;

    if (
        isEligibility ||
        contractType !== ContractType.PERSONAL_CONTRACT_PLAN ||
        !encryptedContractNumber ||
        !isLessThan9MonthsBeforeEndDate(contractEndDate) ||
        !isValidBrand(contractBrand)
    ) {
        return null;
    }

    const prefix = 'notifications.end-of-contract-options';
    const firstName = greeting?.firstName ? ` ${greeting.firstName}` : '';

    return isLoading ? (
        <Skeleton height={200} className={'u-mb'} />
    ) : (
        <Notification
            className={className}
            text={t(`${prefix}.text`)}
            headline={t(`${prefix}.headline`, { firstName })}
        >
            <Button
                secondary
                className="u-mt"
                testId={'button'}
                onClick={() => {
                    history.push(contractEndOptionsPath(encryptedContractNumber));
                }}
            >
                {t(`${prefix}.button`)}
            </Button>
        </Notification>
    );
};
