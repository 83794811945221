import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-6/frontend-ui';

export type ErrorNotificationProps = {
    testId: string;
    headline?: string;
    text?: string | React.ReactNode;
    className?: string;
};

export const ErrorNotification: React.FC<ErrorNotificationProps> = ({ testId, headline, text, className }) => {
    const { t } = useTranslation();

    let headlineText = headline;
    let errorText = text;

    if (!text) {
        headlineText = t('error.no-connection.headline');
        errorText = t('error.no-connection.text');
    }

    return (
        <Notification status={NotificationStatus.error} headline={headlineText} testId={testId} className={className}>
            {errorText}
        </Notification>
    );
};
