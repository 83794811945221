import { MyProfileLoadingPlaceholder, useAnalyticsProfilePageViewTracker } from '@cp-shared-6/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { useMarketingPreferences } from './marketing-preferences-sections';
import { MyProfileUi } from './ui';
import { useMyProfile } from './useMyProfile';

const MyProfileWithHandlers = withLoadingAndNoConnectionHandler(MyProfileUi);

export const MyProfile: React.FC = () => {
    const { data: personalDetails, isLoading, loadingError } = useMyProfile();
    const { data: marketingPreferences } = useMarketingPreferences();
    useAnalyticsProfilePageViewTracker('profile', !!personalDetails && !!marketingPreferences, marketingPreferences);

    return (
        <MyProfileWithHandlers
            isLoading={isLoading}
            personalDetails={personalDetails}
            hasError={!!loadingError}
            loadingPlacehloder={<MyProfileLoadingPlaceholder sectionCounts={[2, 5, 3, 5, 5]} />}
        />
    );
};
