import React from 'react';
import { Icon } from '@vwfs-bronson/bronson-react';
import { IconFooter as IconFooterShared, IconFooterItem, useAnalyticsActionTracker } from '@cp-shared-6/frontend-ui';
import { IconFooter } from '@cp-ie/common';
import { useLocation } from 'react-router-dom';
import { getCurrentPageName } from '../../../../utils';

export const IconFooterUi: React.FC<{ iconFooter?: IconFooter }> = ({ iconFooter }) => {
    const location = useLocation();
    const { onAction } = useAnalyticsActionTracker('onClickCallUsLink');

    if (!iconFooter) {
        return null;
    }

    const getModalContent = (modalContent: string): React.ReactNode => {
        if (!modalContent) return null;
        return (
            <div>
                <Icon name="phone-lines" size="large" className="u-mb-small" />
                <div dangerouslySetInnerHTML={{ __html: modalContent }}></div>
            </div>
        );
    };

    const mapItems = (items: IconFooterItem[]): IconFooterItem[] => {
        return items.map((item: IconFooterItem) => {
            return {
                ...item,
                modalContent: getModalContent(item.modalContent as string),
                normalLink: !!item.linkUrl,
            };
        });
    };

    return (
        <IconFooterShared
            items={mapItems(iconFooter.items)}
            trackingFunction={onAction}
            currentPageName={getCurrentPageName(location)}
        />
    );
};
