import React from 'react';
import { SourceSelection } from './source-selection/SourceSelection';
import { FormSelection } from './form-selection/FormSelection';
import { ReasonSelection } from './reason-selection/ReasonSelection';
import { Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-6/frontend-ui';

export const BalanceEnquiryForm: React.FC = () => {
    const { t } = useTranslation('request');

    const notificationInfo = t('category-selection.balance-enquiry-form.notification-info');
    const description = t('category-selection.balance-enquiry-form.description');
    const mandatoryFieldsInfo = t('category-selection.mandatory-fields-info');

    const notificationInfoText = (
        <span
            dangerouslySetInnerHTML={{
                __html: notificationInfo,
            }}
        />
    );

    return (
        <>
            <Layout.Item>
                <Notification status={NotificationStatus.info} className={`u-mb`}>
                    {notificationInfoText}
                </Notification>
                <p
                    dangerouslySetInnerHTML={{
                        __html: description,
                    }}
                />
            </Layout.Item>
            <Layout.Item>
                <SourceSelection />
            </Layout.Item>
            <Layout.Item>
                <FormSelection />
            </Layout.Item>
            <Layout.Item>
                <ReasonSelection />
            </Layout.Item>
            <Layout.Item>
                <p>{mandatoryFieldsInfo}</p>
            </Layout.Item>
        </>
    );
};
