import { object, string, ValidationError } from 'yup';
import { CpDataApi } from '../../../../../../cp-xhr';
import { BankData, getIbanValidationEndpoint, SignedData } from '@cp-shared-6/apis';
import { parseErrorResponse } from '@cp-shared-6/frontend-integration';
import { WithDefaultBusinessMarketApiError } from '@cp-shared-6/common-utilities';
import { IbanValidationError } from './IbanValidationError';
import { areDifferent, isValidCountryCode, isValidLength } from './ibanValidations';
import { TFunction } from 'i18next';
import { IbanStateHandler, SetIsValidating } from './types';

const translationErrorPrefix = 'bank-account-section.iban.edit-view.iban-validation.input.error';

export const syncIbanValidation = (t: TFunction, previousIban?: string) => {
    return string()
        .required(t(`${translationErrorPrefix}.required`))
        .test('notSameAsPrevious', t(`${translationErrorPrefix}.same-iban`), areDifferent(previousIban))
        .test('validCountryCode', t(`${translationErrorPrefix}.invalid-iban`), isValidCountryCode)
        .matches(RegExp('^[A-Z]{2}[0-9]{2}[a-zA-Z0-9_ ]*$'), t(`${translationErrorPrefix}.invalid-iban`))
        .test('ibanLength', t(`${translationErrorPrefix}.invalid-iban`), isValidLength);
};

export const ibanValidationSchema = (
    t: TFunction,
    ibanStateHandler: IbanStateHandler,
    isValidating: boolean,
    setIsValidating: SetIsValidating,
    previousIban?: string,
) => {
    const { savedIban, setSavedIban, setSignedBankData } = ibanStateHandler;

    const getError = (errorCode: string, iban: string): ValidationError => {
        switch (errorCode) {
            case 'BFF_API_ERROR':
            case 'SAME_BANK_ACCOUNT':
            case 'INCORRECT_IBAN':
                return new ValidationError(t(`${translationErrorPrefix}.invalid-iban`), iban, 'iban');
            default:
                return new ValidationError(t(`${translationErrorPrefix}.iban-validator-unavailable`), iban, 'iban');
        }
    };

    const ibanValidation = syncIbanValidation(t, previousIban);

    return object().shape({
        iban: ibanValidation.test('asyncIban', t(`${translationErrorPrefix}.invalid-iban`), async (iban?: string) => {
            if (isValidating) return true;
            if (!ibanValidation.isValidSync(iban)) {
                setSignedBankData(undefined);
                setSavedIban({});
                return true;
            }
            if (savedIban.iban === iban) {
                if (!savedIban.error) {
                    return true;
                }
                return getError(savedIban.error, savedIban.iban);
            }
            await setIsValidating(true);
            return await CpDataApi.post(getIbanValidationEndpoint(), { iban })
                .then(({ data }: { data: SignedData<BankData> }) => {
                    const { isValid } = data.data;
                    setSignedBankData(isValid ? data : undefined);
                    setSavedIban({ iban, error: isValid ? undefined : 'INCORRECT_IBAN' });
                    return isValid;
                })
                .catch(error => {
                    const errorCode = parseErrorResponse<WithDefaultBusinessMarketApiError<IbanValidationError>>(error)
                        .code;
                    setSavedIban({ iban, error: errorCode });
                    return getError(errorCode, iban);
                })
                .finally(async () => {
                    await setIsValidating(false);
                });
        }),
    });
};
