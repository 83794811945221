import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { Card, Layout, Button } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

export const StandaloneMatchmaker: React.FC = () => {
    const { t } = useTranslation('standalone-matchmaker');
    const contracts = useSelector((state: RootState) => state.contracts);
    const isAllContractsInActive = contracts?.data?.every(item => item.isActive === false);
    if (!isAllContractsInActive) return null;

    return (
        <>
            <h2>{t('heading')}</h2>
            <Card element="article">
                <Layout>
                    <Layout.Item default={'1/2'} m={'1/1'}>
                        <img src={t('re-finance-card.imageUrl')} alt="woman with laptop" />
                    </Layout.Item>
                    <Layout.Item default={'1/2'} m={'1/1'}>
                        <h4 className="u-pb-small">{t('re-finance-card.header')}</h4>
                        <p className="u-pb-xsmall">{t('re-finance-card.text')}</p>

                        <Button
                            className="u-mt-xsmall"
                            onClick={(): void => {
                                window.open(t('re-finance-card.btnLink'), '_blank');
                            }}
                        >
                            {t('re-finance-card.btnText')}
                        </Button>
                    </Layout.Item>
                </Layout>
            </Card>
            <br />
            <Card element="article">
                <Layout>
                    <Layout.Item default={'1/2'} m={'1/1'}>
                        <h4>{t('standalone-card.header')}</h4>
                        <p>{t('standalone-card.text')}</p>
                        <Button
                            className="c-btn--small"
                            onClick={(): void => {
                                window.open(t('standalone-card.btnLink'), '_blank');
                            }}
                        >
                            {t('standalone-card.btnText')}
                        </Button>
                    </Layout.Item>
                    <Layout.Item default={'1/2'} m={'1/1'}>
                        <img src={t('standalone-card.imageUrl')} alt="couple in car" />
                    </Layout.Item>
                </Layout>
            </Card>
        </>
    );
};
