import { Skeleton } from '@cp-shared-6/frontend-ui';
import React from 'react';
import { withLoadingAndNoConnectionHandler } from '../../../../../../integration-wrapper';
import { useMyProfile } from '../../../../../../my-profile';
import { DigitalSignFormUi } from './ui';

const DigitalSignFormWithHandlers = withLoadingAndNoConnectionHandler(DigitalSignFormUi);

export type DigitalSignFormProps = {
    digitalSignEndpoint: string;
    iban: string;
    allContracts: boolean;
    onCancelClick: () => void;
    onSubmit: () => void;
};

export const DigitalSignForm: React.FC<DigitalSignFormProps> = ({ ...props }) => {
    const { data: personalDetails, isLoading, loadingError } = useMyProfile();

    return (
        <DigitalSignFormWithHandlers
            personalDetails={personalDetails}
            isLoading={isLoading}
            hasError={!!loadingError}
            loadingPlacehloder={<Skeleton count={2} height={50} className="u-mb-small" />}
            {...props}
        />
    );
};
