import {
    AbstractDataState,
    useGetContractBasedApiData,
    hasFetchedSuccessfully,
} from '@cp-shared-6/frontend-integration';
import { FinancialDetails } from '@cp-ie/common';
import { useContract } from 'components/contracts/useContract';
import { fetchFinancialDetails } from './FinancialDetailsSlice';
import { FinancialDetailsDataSelector } from './FinancialDetailsDataSelector';
import { useMemo } from 'react';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-6/common-utilities';

export function useFinancialDetails(
    contractNumber: string,
): AbstractDataState<FinancialDetails, DefaultBusinessMarketApiErrorCode> {
    const contractState = useContract(contractNumber);
    const financialDetailsState = useGetContractBasedApiData(
        contractNumber,
        fetchFinancialDetails,
        FinancialDetailsDataSelector,
        contractState.data?._links?.financialDetails,
    );
    return useMemo(
        () => ({
            isLoading: contractState.isLoading || financialDetailsState.isLoading,
            hasReceivedResponse: contractState.loadingError
                ? contractState.hasReceivedResponse
                : contractState.hasReceivedResponse && financialDetailsState.hasReceivedResponse,
            failedLoadingAttempts: hasFetchedSuccessfully(contractState)
                ? financialDetailsState.failedLoadingAttempts
                : contractState.failedLoadingAttempts,
            data: financialDetailsState.data,
            loadingError: hasFetchedSuccessfully(contractState)
                ? financialDetailsState.loadingError
                : contractState.loadingError,
        }),
        [contractState, financialDetailsState],
    );
}
