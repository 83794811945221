import React, { useState } from 'react';
import { Layout, Fieldset, Form, Button, Card, Link } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { CustomerRegistrationError, CustomerRegistrationData, DATE_FORMAT } from '@cp-ie/common';
import { WithDefaultCpIntegrationErrors, formatCpDate } from '@cp-shared-6/common-utilities';
import {
    ValidatedInput,
    ValidatedCheckbox,
    preventSubmit,
    CleaveInput,
    ScrollToTopOnNavigation,
    Notification,
    NotificationStatus,
    useAnalyticsFormTracker,
    useAnalyticsActionTracker,
} from '@cp-shared-6/frontend-ui';
import { identificationFormValidationSchema } from './validation';

type IdentificationFormProps = {
    legalNotice: string;
    onSubmit: Function;
    errorCode?: WithDefaultCpIntegrationErrors<CustomerRegistrationError>;
    setLastTouchedField: Function;
};

type FormProps = {
    name: string;
    surname: string;
    birthDate: string;
    confirmPrivacyPolicy: boolean;
    confirmLegalNotice: boolean;
};

export const IdentificationForm: React.FC<IdentificationFormProps> = ({
    legalNotice,
    onSubmit,
    errorCode,
    setLastTouchedField,
}) => {
    const { t } = useTranslation('registration');
    const [showLegalNotice, setShowLegalNotice] = useState(false);

    const { onTyping, onSubmit: trackOnSubmit } = useAnalyticsFormTracker({
        startTyping: 'onStartTypingConfirmIdentity',
        confirm: 'onConfirmConfirmIdentiy',
    });
    const { onAction } = useAnalyticsActionTracker('onFormValidationErrorConfirmIdentity');

    const getErrorNotificationLabel = (): string | React.ReactNode => {
        let label;
        switch (errorCode) {
            case 'CUSTOMER_NOT_FOUND':
                label = t('error.user-not-found');
                break;
            case 'PHONE_NUMBER_NOT_FOUND':
                label = (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('error.phone-number-not-found'),
                        }}
                    />
                );
                break;
            case 'ACCOUNT_ID_NOT_FOUND':
                label = t('error.account-not-found');
                break;
            case 'CUSTOMER_ALREADY_REGISTERED':
                label = t('error.user-already-registered');
                break;
            case 'EXCEEDED_LIMIT':
                label = (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('error.exceeded-limit'),
                        }}
                    />
                );
                break;
            case 'THROTTLER_EXCEPTION':
                label = (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('error.too-many-requests'),
                        }}
                    />
                );
                break;
            default:
                label = t('error.default');
                break;
        }
        return label;
    };

    const handleSubmit = (values: FormProps): void => {
        const { name, surname, birthDate } = values;
        const registrationData: CustomerRegistrationData = {
            firstName: name.trim(),
            lastName: surname.trim(),
            dateOfBirth: formatCpDate(birthDate, DATE_FORMAT).toCpDate(),
        };
        trackOnSubmit();
        onSubmit(registrationData);
    };

    const getErrors = (errors: { [k: string]: string | undefined }): string => Object.keys(errors).join(`, `);
    const initialListErrors = 'name, surname, birthDate, confirmPrivacyPolicy, confirmLegalNotice';

    return (
        <>
            <Layout.Item default={'1/1'} className={'u-mb u-text-center'}>
                <h1>{t('head.title')}</h1>
                <p>{t('head.info-identification')}</p>
                <p
                    dangerouslySetInnerHTML={{
                        __html: t('head.info-legal-identification', {
                            privacyPolicyPath: 'https://www.vwfs.ie/privacy_policy.html',
                        }),
                    }}
                />
                {errorCode && (
                    <ScrollToTopOnNavigation>
                        <Notification testId={'errorNotificationIdentificationForm'} status={NotificationStatus.error}>
                            {getErrorNotificationLabel()}
                        </Notification>
                    </ScrollToTopOnNavigation>
                )}
            </Layout.Item>
            <Layout.Item>
                <Formik
                    initialValues={{
                        name: '',
                        surname: '',
                        birthDate: '',
                        confirmPrivacyPolicy: false,
                        confirmLegalNotice: false,
                    }}
                    validationSchema={identificationFormValidationSchema(t)}
                    onSubmit={handleSubmit}
                >
                    {(formik): JSX.Element => (
                        <Form onSubmit={preventSubmit()} onChange={(): void => onTyping(formik.errors, formik.touched)}>
                            <Fieldset>
                                <Fieldset.Row>
                                    <ValidatedInput
                                        label={t('form.input-labels.name')}
                                        name="name"
                                        onFocus={(): void => setLastTouchedField('name')}
                                        tooltip={t('form.input-tooltips.name')}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <ValidatedInput
                                        label={t('form.input-labels.surname')}
                                        name="surname"
                                        onFocus={(): void => setLastTouchedField('surname')}
                                        tooltip={t('form.input-tooltips.surname')}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <CleaveInput
                                        cleaveOptions={{
                                            delimiter: '/',
                                            blocks: [2, 2, 4],
                                            numericOnly: true,
                                        }}
                                        inputMode={'numeric'}
                                        label={t('form.input-labels.birth-date')}
                                        name={'birthDate'}
                                        testId={'birthDate'}
                                        onFocus={(): void => setLastTouchedField('birthDate')}
                                        tooltip={t('form.input-tooltips.birth-date')}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <ValidatedCheckbox
                                        label={
                                            <>
                                                {t('form.input-labels.accept')}{' '}
                                                <Link
                                                    href={'https://www.vwfs.ie/privacy_policy.html'}
                                                    normalLink={true}
                                                    testId="showPrivacyPolicy"
                                                    target={'_blank'}
                                                >
                                                    {t('form.button-labels.privacy-policy')}
                                                </Link>
                                            </>
                                        }
                                        name="confirmPrivacyPolicy"
                                        onFocus={(): void => setLastTouchedField('confirmPrivacyPolicy')}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <ValidatedCheckbox
                                        label={
                                            <>
                                                {t('form.input-labels.accept')}{' '}
                                                <Button
                                                    onClick={(e: MouseEvent): void => {
                                                        e.preventDefault();
                                                        setShowLegalNotice(!showLegalNotice);
                                                    }}
                                                    testId="showLegalNotice"
                                                    link={true}
                                                >
                                                    {t('form.button-labels.legal-notice')}
                                                </Button>
                                            </>
                                        }
                                        name="confirmLegalNotice"
                                        onFocus={(): void => setLastTouchedField('confirmLegalNotice')}
                                    />
                                </Fieldset.Row>
                                {showLegalNotice && (
                                    <Fieldset.Row testId="legalNotice">
                                        <Card element={'article'} scrollable={true} collapsible={true}>
                                            <div
                                                className={'u-text-left'}
                                                dangerouslySetInnerHTML={{ __html: legalNotice }}
                                            />
                                        </Card>
                                    </Fieldset.Row>
                                )}
                                <Fieldset.Row>
                                    <Button
                                        full={true}
                                        onClick={async (): Promise<void> => {
                                            await formik.submitForm();
                                            if (!isEmpty(formik.errors) || isEmpty(formik.touched)) {
                                                const errorToString = getErrors(formik.errors).toString();
                                                if (!errorToString) {
                                                    onAction(initialListErrors);
                                                } else onAction(getErrors(formik.errors));
                                            }
                                        }}
                                        testId={'submitRegistrationButton'}
                                    >
                                        {t('form.button-labels.submit')}
                                    </Button>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            </Layout.Item>
        </>
    );
};
