import React from 'react';
import { Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { Textarea } from 'components/request/form-view/textarea/Textarea';
import { ValidatedInput } from '@cp-shared-6/frontend-ui';

export const RefundQuery: React.FC = () => {
    const { t } = useTranslation('request');
    const mandatoryLabel = `${t('category-selection.refund-query.label')} *`;
    const description = t('category-selection.refund-query.description');
    const mandatoryFieldsInfo = t('category-selection.mandatory-fields-info');

    return (
        <>
            <Layout.Item>
                <p>{description}</p>
            </Layout.Item>
            <Layout.Item>
                <div>{mandatoryLabel}</div>
                <Textarea name={'refundQuery.refundQuery'} testId={'refund-query'} />
            </Layout.Item>
            <Layout.Item>
                <ValidatedInput
                    label={t('category-selection.refund-query.email.label')}
                    name={'refundQuery.customerEmail'}
                    type="email"
                />
            </Layout.Item>
            <Layout.Item>
                <p>{mandatoryFieldsInfo}</p>
            </Layout.Item>
        </>
    );
};
