import React from 'react';
import { Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-6/frontend-ui';

export const FinancialStatement: React.FC = () => {
    const { t } = useTranslation('request');
    const notificationInfo = t('category-selection.financial-statement.notification-info');

    const notificationInfoText = (
        <span
            dangerouslySetInnerHTML={{
                __html: notificationInfo,
            }}
        />
    );

    return (
        <Layout.Item>
            <Notification status={NotificationStatus.info} className={`u-mb`}>
                {notificationInfoText}
            </Notification>
        </Layout.Item>
    );
};
