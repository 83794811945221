import { Spinner } from '@cp-shared-6/frontend-ui';
import { HookPageRedirection } from 'components/hook-page/HookPageRedirection';
import {
    autoLogoutPath,
    contactDetailsHookPagePath,
    contractEndOptionsPath,
    dashboardPagePath,
    digitalRenewalPath,
    scriveRejectionPath,
    faqPagePath,
    forbiddenPagePath,
    HookPageRedirectionPath,
    introductionPagePath,
    landingPagePath,
    legalNoticePath,
    manualLogoutPath,
    marketingPreferencesHookPagePath,
    myProfilePagePath,
    myRequestsPath,
    notAuthorizedPagePath,
    notFoundPagePath,
    registrationEntry,
    registrationPagePath,
    scriveSuccessPath,
    thirdPartyLicensesPagePath,
    errorPagePath,
} from 'components/navigation/paths';
import { ConnectedRouter } from 'connected-react-router';
import {
    LogoutSuccessPage,
    ContractEndOptionsPage,
    DashboardPage,
    FaqPage,
    ForbiddenPage,
    IntroductionPage,
    LandingPage,
    LegalNoticePage,
    MarketingPreferencesPage,
    MasterPage,
    MyProfilePage,
    NotAuthorizedPage,
    NotFoundPage,
    RegistrationPage,
    RegistrationRedirectPage,
    RequestPage,
    ThirdPartyLicensesPage,
    DigitalSignErrorPage,
    DigitalSignSuccessPage,
    ErrorPage,
} from 'pages';
import { ContactDetailsHookPage } from 'pages/contact-details-hookpage/ContactDetailsHookPage';
import { DigitalRenewalPage } from 'pages/digital-renewal-page/DigitalRenewalPage';
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { AuthenticatedRoute, AuthorizedRoute, AuthProviders, AxiosConfiguration } from './auth';
import { history } from './store/rootReducer';

const App: React.FC = () => {
    return (
        <div className="App">
            <Suspense fallback={<Spinner center />}>
                <AuthProviders>
                    <AxiosConfiguration>
                        <ConnectedRouter history={history}>
                            <LastLocationProvider>
                                <Switch>
                                    <Route exact path={registrationEntry()}>
                                        <RegistrationRedirectPage />
                                    </Route>
                                    <MasterPage>
                                        <Route path="*">
                                            <Switch>
                                                <Route exact path={landingPagePath()}>
                                                    <LandingPage />
                                                </Route>
                                                <Route path={autoLogoutPath()}>
                                                    <LogoutSuccessPage />
                                                </Route>
                                                <Route path={manualLogoutPath()}>
                                                    <LogoutSuccessPage manualLogout={true} />
                                                </Route>
                                                <Route path={faqPagePath()}>
                                                    <FaqPage />
                                                </Route>
                                                <Route path={legalNoticePath()}>
                                                    <LegalNoticePage />
                                                </Route>
                                                <Route path={thirdPartyLicensesPagePath()}>
                                                    <ThirdPartyLicensesPage />
                                                </Route>
                                                <AuthorizedRoute exact path={introductionPagePath()}>
                                                    <IntroductionPage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={dashboardPagePath()}>
                                                    <DashboardPage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={myProfilePagePath()}>
                                                    <MyProfilePage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={myRequestsPath()}>
                                                    <RequestPage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={HookPageRedirectionPath()}>
                                                    <HookPageRedirection />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={digitalRenewalPath()}>
                                                    <DigitalRenewalPage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={contactDetailsHookPagePath()}>
                                                    <ContactDetailsHookPage />
                                                </AuthorizedRoute>
                                                <AuthorizedRoute path={marketingPreferencesHookPagePath()}>
                                                    <MarketingPreferencesPage />
                                                </AuthorizedRoute>
                                                <AuthenticatedRoute path={registrationPagePath()}>
                                                    <RegistrationPage />
                                                </AuthenticatedRoute>
                                                <AuthenticatedRoute path={contractEndOptionsPath()}>
                                                    <ContractEndOptionsPage />
                                                </AuthenticatedRoute>
                                                <Route path={scriveRejectionPath()}>
                                                    <DigitalSignErrorPage />
                                                </Route>
                                                <Route path={scriveSuccessPath()}>
                                                    <DigitalSignSuccessPage />
                                                </Route>
                                                <Route path={forbiddenPagePath()}>
                                                    <ForbiddenPage />
                                                </Route>
                                                <Route path={notAuthorizedPagePath()}>
                                                    <NotAuthorizedPage />
                                                </Route>
                                                <Route path={errorPagePath()}>
                                                    <ErrorPage />
                                                </Route>
                                                <Route path={notFoundPagePath()}>
                                                    <NotFoundPage />
                                                </Route>
                                                <Route path="*">
                                                    <Redirect to={notFoundPagePath()} />
                                                </Route>
                                            </Switch>
                                        </Route>
                                    </MasterPage>
                                </Switch>
                            </LastLocationProvider>
                        </ConnectedRouter>
                    </AxiosConfiguration>
                </AuthProviders>
            </Suspense>
        </div>
    );
};

export default App;
