import { DealerDetails, DealerDetailsAddress } from '@cp-ie/common';

export const dealerAddressWithAllValues: DealerDetailsAddress = {
    city: 'Dublin',
    country: 'Ireland',
    geocodeAccuracy: '80',
    latitude: 53.401907,
    longitude: -6.313807,
    postalCode: 'D11 V960',
    state: 'Leinster',
    street: 'Charlestown',
};

export const dealerDetailsWithAllValues: DealerDetails = {
    name: 'Dealer Audi',
    phoneNumber: '838289455',
    email: 'dealer_audi@example.com',
    website: 'https://google.com',
    billingAddress: dealerAddressWithAllValues,
};

export const dealerDetailsWithMissingValues: DealerDetails = {
    name: 'Dealer Audi',
    email: 'dealer_audi@example.com',
    billingAddress: { city: 'Dublin', street: 'Charlestown' },
};
