export type CustomerHappinessIndexResponse = {
    redirectUrl?: string;
};

export enum TrustedShopsIntegrationCustomType {
    CUSTOMERPORTAL = 'customerportal',
    CUSTOMERPORTAL_FAQ = 'customerportal_faq',
    CUSTOMERPORTAL_PROFILE = 'customerportal_profile',
    CUSTOMERPORTAL_REQUEST = 'customerportal_request',
}
