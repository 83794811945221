import { CPDate, formatCpDate } from '@cp-shared-6/common-utilities';

export const isLessThan9MonthsBeforeEndDate = (date?: CPDate): boolean => {
    if (!date) {
        return false;
    }
    const todayMoment = formatCpDate().toMoment();
    const endDate = formatCpDate(date).toMoment();
    const date9MonthsBeforeEndDate = endDate.clone().subtract(9, 'months');

    return todayMoment.isBetween(date9MonthsBeforeEndDate, endDate);
};
