import { Contract, ProductRoutingResponse } from '@cp-ie/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-6/common-utilities';
import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-6/frontend-integration';
import { ProductRoutingDataSelector } from './productRoutingSelector';
import { fetchProductRouting } from './productRoutingSlice';

export const useProductRouting = (
    contract?: Contract,
): AbstractDataState<ProductRoutingResponse, DefaultBusinessMarketApiErrorCode> => {
    const isFetchingAllowed = !!(contract && contract.brand);
    const requestConfig =
        contract && contract.brand
            ? {
                  data: {
                      brand: contract.brand,
                      licensePlate: contract.licensePlate,
                      model: contract.model,
                  },
              }
            : undefined;

    return useGetSimpleApiData(
        fetchProductRouting,
        ProductRoutingDataSelector,
        false,
        requestConfig,
        undefined,
        isFetchingAllowed,
    );
};
