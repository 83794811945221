import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AnalyticsProvider, MasterPage as MasterPageShared, useAuthentication } from '@cp-shared-6/frontend-ui';
import { loginAnalyticsPath, registrationPagePath } from 'components/navigation/paths';
import { NavigationBar } from 'components/navigation/NavigationBar';
import { LegalFooter } from 'components/footer/legal-footer';
import { IconFooter } from 'components/footer/icon-footer';
import { currentBrand } from 'config';

export const MasterPage: React.FC = ({ children }) => {
    const { isAuthenticated } = useAuthentication();
    return <MasterPageComponent isAuthenticated={isAuthenticated}>{children}</MasterPageComponent>;
};

export const MasterPageComponent: React.FC<{ isAuthenticated: boolean }> = memo(({ isAuthenticated, children }) => {
    const { t } = useTranslation();
    const userGroup = isAuthenticated ? 'private' : undefined;

    return (
        <AnalyticsProvider
            market="ie"
            isAuthenticated={isAuthenticated}
            version="1"
            releaseDate="2020-11-20"
            language="ie"
            registrationPath={registrationPagePath()}
            loginPath={loginAnalyticsPath()}
            userGroup={userGroup}
            brand={currentBrand}
        >
            <MasterPageShared
                navigationBar={<NavigationBar />}
                iconFooter={<IconFooter />}
                legalFooter={<LegalFooter />}
                label={t('back-to-top')}
            >
                {children}
            </MasterPageShared>
        </AnalyticsProvider>
    );
});

MasterPageComponent.displayName = 'MasterPageComponent';
