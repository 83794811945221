import React from 'react';
import { Button, ButtonContainer, Fieldset, Form } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { openInSameWindow } from '@cp-shared-6/frontend-ui';

type ScriveRedirectionViewProps = {
    scriveLink: string;
    onCancelClick: () => void;
};

export const ScriveRedirectionView: React.FC<ScriveRedirectionViewProps> = ({ scriveLink, onCancelClick }) => {
    const { t } = useTranslation('financial-details');
    const translationPrefix = 'bank-account-section.iban.edit-view.digital-sign';

    const redirectToScrive = () => {
        openInSameWindow(scriveLink)();
    };

    return (
        <Form>
            <Fieldset>
                <Fieldset.Row>
                    <ButtonContainer center>
                        <Button secondary onClick={onCancelClick} testId="cancelButton" type="button">
                            {t('translation:editable-section-nav.cancel')}
                        </Button>
                        <Button onClick={redirectToScrive} testId="scriveButton" type="button">
                            {t(`${translationPrefix}.button`)}
                        </Button>
                    </ButtonContainer>
                </Fieldset.Row>
            </Fieldset>
        </Form>
    );
};
