import React from 'react';
import { ContractEndOptionsView } from '../enums';
import { useDealerDetails } from '../../../contracts/dealer-details/useDealerDetails';
import { DealerInformationViewUi } from './ui';
import { withLoadingAndNoConnectionHandler } from '../../../integration-wrapper';
import { DealerInformationViewLoadingPlaceholder } from './DealerInformationViewLoadingPlaceholder';

const DealerInformationWithHandlers = withLoadingAndNoConnectionHandler(DealerInformationViewUi);

export const DealerInformationView: React.FC<{
    handleBackClick: (contractEndOptionsView: ContractEndOptionsView) => void;
    contractNumber: string;
}> = ({ handleBackClick, contractNumber }) => {
    const {
        data: dealerDetails,
        isLoading: isLoadingDealerDetails,
        loadingError: dealerDetailsLoadingError,
    } = useDealerDetails(contractNumber);

    return (
        <DealerInformationWithHandlers
            handleBackClick={handleBackClick}
            dealerDetails={dealerDetails}
            isLoading={isLoadingDealerDetails}
            hasError={!!dealerDetailsLoadingError}
            loadingPlacehloder={<DealerInformationViewLoadingPlaceholder />}
        />
    );
};
