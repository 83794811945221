import { RefObject, useEffect, useRef, useState } from 'react';
import { ViewType } from './types';
import { useLocation } from 'react-router-dom';

const elementYPosition = (element: HTMLElement): number => {
    let loopElement: HTMLElement | undefined = element;
    let yPosition = 0;
    do {
        yPosition += loopElement.offsetTop;
        loopElement = loopElement.offsetParent as HTMLElement;
    } while (loopElement);
    return yPosition;
};

const scrollToElement = (element: HTMLElement): void => {
    const yPosition = elementYPosition(element);
    window.scrollTo(0, yPosition);
};

export const useScrollTo = (
    currentView?: string,
    initialView?: string,
    milliseconds?: number,
): RefObject<HTMLElement> => {
    const scrollToRef = useRef<HTMLElement>(null);
    const [previousView, setPreviousView] = useState<string | undefined>(initialView);

    useEffect(() => {
        let timeout: NodeJS.Timeout | undefined = undefined;

        if (scrollToRef && scrollToRef.current && currentView !== previousView) {
            const element: HTMLElement = scrollToRef.current;
            timeout = setTimeout(() => {
                scrollToElement(element);

                setPreviousView(currentView);
            }, milliseconds || 250);
        }

        return (): void => {
            if (timeout !== undefined) {
                clearTimeout(timeout);
            }
        };
    }, [scrollToRef, currentView, previousView]); // eslint-disable-line react-hooks/exhaustive-deps

    return scrollToRef;
};

export const useScrollToView = (path: string) => {
    const { pathname } = useLocation();
    const [currentView, setCurrentView] = useState<ViewType>({
        name: path === pathname ? 'EDIT_VIEW' : 'CONSULT_VIEW',
        counter: 0,
    });
    const scrollToRef = useScrollTo(currentView.name, 'CONSULT_VIEW', 100);

    const setCurrentViewName = (name: string) => {
        const counter = currentView.counter + 1;
        setCurrentView({ name: `${name}.${counter}`, counter });
    };

    return { setCurrentView: setCurrentViewName, scrollToRef };
};
