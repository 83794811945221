import { County } from '../apis/services/types';

export const counties: County[] = [
    { label: 'Carlow', countyCode: 1 },
    { label: 'Cavan', countyCode: 2 },
    { label: 'Clare', countyCode: 3 },
    { label: 'Cork', countyCode: 4 },
    { label: 'Donegal', countyCode: 5 },
    { label: 'Dublin', countyCode: 6 },
    { label: 'Dublin 1', countyCode: 7 },
    { label: 'Dublin 2', countyCode: 8 },
    { label: 'Dublin 3', countyCode: 9 },
    { label: 'Dublin 4', countyCode: 10 },
    { label: 'Dublin 5', countyCode: 11 },
    { label: 'Dublin 6', countyCode: 12 },
    { label: 'Dublin 6W', countyCode: 13 },
    { label: 'Dublin 7', countyCode: 14 },
    { label: 'Dublin 8', countyCode: 15 },
    { label: 'Dublin 9', countyCode: 16 },
    { label: 'Dublin 10', countyCode: 17 },
    { label: 'Dublin 11', countyCode: 18 },
    { label: 'Dublin 12', countyCode: 19 },
    { label: 'Dublin 13', countyCode: 20 },
    { label: 'Dublin 14', countyCode: 21 },
    { label: 'Dublin 15', countyCode: 22 },
    { label: 'Dublin 16', countyCode: 23 },
    { label: 'Dublin 17', countyCode: 24 },
    { label: 'Dublin 18', countyCode: 25 },
    { label: 'Dublin 19', countyCode: 26 },
    { label: 'Dublin 20', countyCode: 27 },
    { label: 'Dublin 21', countyCode: 28 },
    { label: 'Dublin 22', countyCode: 29 },
    { label: 'Dublin 23', countyCode: 30 },
    { label: 'Dublin 24', countyCode: 31 },
    { label: 'Galway', countyCode: 32 },
    { label: 'Kerry', countyCode: 33 },
    { label: 'Kildare', countyCode: 34 },
    { label: 'Kilkenny', countyCode: 35 },
    { label: 'Laois', countyCode: 36 },
    { label: 'Leitrim', countyCode: 37 },
    { label: 'Limerick', countyCode: 38 },
    { label: 'Longford', countyCode: 39 },
    { label: 'Louth', countyCode: 40 },
    { label: 'Mayo', countyCode: 41 },
    { label: 'Meath', countyCode: 42 },
    { label: 'Monaghan', countyCode: 43 },
    { label: 'Offaly', countyCode: 44 },
    { label: 'Roscommon', countyCode: 45 },
    { label: 'Sligo', countyCode: 46 },
    { label: 'Tipperary', countyCode: 47 },
    { label: 'Waterford', countyCode: 48 },
    { label: 'Westmeath', countyCode: 49 },
    { label: 'Wexford', countyCode: 50 },
    { label: 'Wicklow', countyCode: 51 },
    { label: 'OTHER', countyCode: 52 },
];
