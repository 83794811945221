import { CpDataApi } from 'cp-xhr';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-6/frontend-storybook-extensions';
import React from 'react';
import { IbanValidationBusinessError, IbanValidationSuccess } from './ExampleData';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-6/common-utilities';
import { getIbanValidationEndpoint } from '@cp-shared-6/apis';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 201,
        responseBody: IbanValidationSuccess,
        delay: 1000,
    },
    'Same Bank Account': {
        status: IbanValidationBusinessError.status,
        responseBody: IbanValidationBusinessError,
        delay: 1000,
    },
    'Validation Service Not Availabe': {
        status: IbanValidationBusinessError.status,
        responseBody: IbanValidationBusinessError,
        delay: 1000,
    },
    'Incorrect Iban': {
        status: IbanValidationBusinessError.status,
        responseBody: IbanValidationBusinessError,
        delay: 1000,
    },
    MARKET_API_DEFAULT_BUSINESS_ERROR: {
        status: IbanValidationBusinessError.status,
        responseBody: IbanValidationBusinessError,
        delay: 1000,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const IbanValidationMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'IBAN Validation Response',
    method: 'post',
    defaultMockOption: 'Success',
    mockOptions,
    url: getIbanValidationEndpoint(),
};

function mockValidationResponse(): void {
    setupMockResponses(CpDataApi, [IbanValidationMock]);
}

export const IbanValidationResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    mockValidationResponse();

    return <div>{storyFn()}</div>;
};
