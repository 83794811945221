import { CpDataApi } from 'cp-xhr';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-6/frontend-storybook-extensions';
import React from 'react';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-6/common-utilities';
import { FinancialDetailsWithAllValues } from '../../../../ExampleData';
import { MyProfileMock } from '../../../../../../my-profile';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 201,
        responseBody: {},
        delay: 1000,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const DigitalSignMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Digital Sign Response',
    method: 'post',
    defaultMockOption: 'Success',
    mockOptions,
    url: FinancialDetailsWithAllValues?._links?.digitalSign || '',
};

function mockValidationResponse(): void {
    setupMockResponses(CpDataApi, [DigitalSignMock, MyProfileMock]);
}

export const DigitalSignResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    mockValidationResponse();

    return <div>{storyFn()}</div>;
};
