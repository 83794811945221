import React, { useCallback } from 'react';
import { HeroImage, StaticPageLoadingPlaceholder, useAnalyticsErrorPageTracker } from '@cp-shared-6/frontend-ui';
import { useLogin } from 'auth/useLogin';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useUnauthorizedErrorPage } from './useUnauthorizedErrorPage';

const HeroImageWithHandlers = withLoadingAndNoConnectionHandler(HeroImage);

export const UnauthorizedErrorPage: React.FC = () => {
    const { cmsContent: unauthorizedErrorPage, isLoading, loadingError } = useUnauthorizedErrorPage();

    useAnalyticsErrorPageTracker('unauthorizedError', !!unauthorizedErrorPage);
    const login = useLogin();
    const onClick = useCallback(() => {
        login();
    }, [login]);

    return (
        <HeroImageWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            title={unauthorizedErrorPage?.title || ''}
            subTitle={unauthorizedErrorPage?.subTitle}
            buttonText={unauthorizedErrorPage?.buttonText}
            clickHandler={onClick}
            inverted
            loadingPlacehloder={
                <StaticPageLoadingPlaceholder withPretitle withSubTitle withButton>
                    <></>
                </StaticPageLoadingPlaceholder>
            }
        />
    );
};
