export const currentBrand = process.env.REACT_APP_WEBSITE_BRAND || 'vwfs';
export const integrationApiBaseUrl = process.env.REACT_APP_INTEGRATION_API_BASE_URL;
export const integrationApiBaseUrlLocal = process.env.REACT_APP_API_BASE_URL_LOCAL;
export const idpHint = process.env.REACT_APP_IDP_HINT;
export const apiGatewayConsumerName = 'vwfs-du-cpie';
export const maxFileSize = 4 * 1024 * 1024;
export const isFirstRegistrationLocalStorageKey = 'isFirstRegistration';
export const isAfterRegistrationLocalStorageKey = 'isAfterRegistration';
export const contractsWithHiddenHookPageLocalStorageKey = 'contractsWithHiddenHookPage';
export const lastContactDetailsHookPageKey = 'lastContactDetailsHookPage';
export const digitalRenewalAudiLink = process.env.REACT_APP_DR_AUDI_LINK;
export const digitalRenewalSeatLink = process.env.REACT_APP_DR_SEAT_LINK;
export const digitalRenewalSkodaLink = process.env.REACT_APP_DR_SKODA_LINK;
export const digitalRenewalVwLink = process.env.REACT_APP_DR_VW_LINK;
export const digitalRenewalCupraLink = process.env.REACT_APP_DR_CUPRA_LINK;
