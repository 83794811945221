import {
    NavigationBar as NavigationBarShared,
    SiteNavItemPropsList,
    SiteNavProps,
    useAnalyticsActionTracker,
    useAuthentication,
} from '@cp-shared-6/frontend-ui';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useLogin } from '../../auth/useLogin';
import { isFirstRegistrationLocalStorageKey } from '../../config';
import { getCurrentPageName } from '../../utils';
import {
    autoLogoutPath,
    contactDetailsHookPagePath,
    dashboardPagePath,
    digitalRenewalPath,
    faqPagePath,
    introductionPagePath,
    landingPagePath,
    legalNoticePath,
    manualLogoutPath,
    myProfilePagePath,
    myRequestsPath,
    notAuthorizedPagePath,
    notFoundPagePath,
    registrationPagePath,
    thirdPartyLicensesPagePath,
} from './paths';

const pagesWithoutNavbar = [registrationPagePath(), contactDetailsHookPagePath()];

const pagesWithoutStaticHeader = [
    landingPagePath(),
    introductionPagePath(),
    digitalRenewalPath(),
    faqPagePath(),
    legalNoticePath(),
    notAuthorizedPagePath(),
    notFoundPagePath(),
    registrationPagePath(),
    thirdPartyLicensesPagePath(),
    autoLogoutPath(),
    manualLogoutPath(),
];

function publicNavigationLinks(t: TFunction, onLogin: () => Promise<void>): SiteNavItemPropsList {
    return [
        {
            label: t('navigation.login'),
            onClick: onLogin,
        },
    ];
}

function privateNavigationLinks(t: TFunction, onLogout: () => void): SiteNavItemPropsList {
    return [
        {
            url: dashboardPagePath(),
            label: t('navigation.dashboard'),
        },
        {
            url: myProfilePagePath(),
            label: t('navigation.profile'),
        },
        {
            url: myRequestsPath(),
            label: t('navigation.requests'),
        },
        {
            url: faqPagePath(),
            label: t('navigation.faq'),
        },
        {
            label: t('navigation.logout'),
            onClick: onLogout,
        },
    ];
}

export const NavigationBar: React.FC = () => {
    const { t } = useTranslation('navigation');

    const history = useHistory();
    const location = useLocation();
    const { isAuthenticated, logout } = useAuthentication();
    const login = useLogin();

    const currentPageName = getCurrentPageName(location);

    const { onAction: onLoginAction } = useAnalyticsActionTracker('login');
    const { onAction: onLogoutAction } = useAnalyticsActionTracker('logout');

    const navigationItems = (isAuthenticated
        ? privateNavigationLinks(t, () => {
              window.localStorage.removeItem(isFirstRegistrationLocalStorageKey);
              onLogoutAction(currentPageName);
              return logout({ redirectUri: window.location.origin + manualLogoutPath() });
          })
        : publicNavigationLinks(t, () => {
              window.localStorage.removeItem(isFirstRegistrationLocalStorageKey);
              onLoginAction(currentPageName);
              return login();
          })
    ).map(navItem => ({
        ...{ isActive: history.location.pathname === navItem.url },
        ...navItem,
    }));

    let currentPathName = history.location.pathname;

    if (currentPathName.slice(0, 17) === '/digital-renewal/') {
        currentPathName = digitalRenewalPath();
    }

    const hidden = pagesWithoutNavbar.includes(currentPathName);
    const withStaticHeader = !pagesWithoutStaticHeader.includes(currentPathName);

    const navigation: SiteNavProps = {
        navigationItems,
    };

    const logoRedirectPath = isAuthenticated ? dashboardPagePath() : landingPagePath();

    return (
        <NavigationBarShared
            logoAltText={t(`navigation.${isAuthenticated ? 'dashboard' : 'home'}`)}
            navigation={navigation}
            onLogoClickPath={logoRedirectPath}
            withStaticHeader={withStaticHeader}
            hidden={hidden}
        />
    );
};
