import { Card, Layout } from '@vwfs-bronson/bronson-react';
import { Skeleton } from '@cp-shared-6/frontend-ui';
import React from 'react';

export const DealerInformationViewLoadingPlaceholder: React.FC = () => (
    <Layout>
        <Layout.Item className={'u-mb'}>
            <Skeleton height={40} width={200} className={'u-mt'} />
            <Skeleton count={1} height={40} className={'u-mt'} />
        </Layout.Item>
        <Layout.Item>
            <Skeleton height={400} />
        </Layout.Item>
        <Layout.Item>
            <Card element="article">
                <Skeleton height={30} className="u-mb u-mt" />
                <Skeleton count={3} />
                <Skeleton height={25} className="u-mb u-mt" />
            </Card>
        </Layout.Item>
        <Layout.Item className={'u-mt u-text-center'}>
            <Skeleton height={40} width={300} className="u-m-xsmall" count={2} />
        </Layout.Item>
    </Layout>
);
