import React from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatedSelect } from '@cp-shared-6/frontend-ui';

export const CategorySelection: React.FC<{ isContractSelected: boolean }> = ({ isContractSelected }) => {
    const { t } = useTranslation('request');

    const selectItems = isContractSelected
        ? [
              { value: 'BALANCE_ENQUIRY_FORM', label: t('category-selection.options.balance-enquiry-form') },
              { value: 'FINANCIAL_STATEMENT', label: t('category-selection.options.financial-statement') },
              { value: 'REFUND_QUERY', label: t('category-selection.options.refund-query') },
          ]
        : [];

    const mandatoryLabel = `${t('category-selection.label')} *`;
    return (
        <ValidatedSelect
            label={mandatoryLabel}
            name={'categorySelection'}
            id={'category-selection'}
            testId={'category-selection'}
            selectItems={selectItems}
            placeholder={t('category-selection.placeholder')}
            disablePlaceholder={true}
        />
    );
};
