import React, { useEffect } from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { IntroductionPageTeaserUi } from './ui';
import { useIntroductionPageTeaser } from './useIntroductionPageTeaser';
import { useMyProfile } from '../my-profile';
import { dashboardPagePath } from '../navigation/paths';
import { useHistory } from 'react-router-dom';
import { Skeleton, StaticPageLoadingPlaceholder } from '@cp-shared-6/frontend-ui';
import { isFirstRegistrationLocalStorageKey } from '../../config';

const IntroductionPageTeaserWithHandlers = withLoadingAndNoConnectionHandler(IntroductionPageTeaserUi);

export const IntroductionPageTeaser: React.FC = () => {
    const history = useHistory();
    const {
        cmsContent: introductionPageTeaser,
        isLoading: isLoadingContent,
        loadingError: loadingErrorContent,
    } = useIntroductionPageTeaser();
    const { data: personalDetails, isLoading: isLoadingProfile } = useMyProfile();

    useEffect(() => {
        if (!window.localStorage.getItem(isFirstRegistrationLocalStorageKey)) {
            history.push(dashboardPagePath());
        }
    }, [history]);

    // ToDo CO-12995
    // const { isAuthenticated } = useAuthentication();
    // const { onAction } = useAnalyticsActionTracker('login');
    // useAnalyticsPageViewTracker('introductionPage', !!introductionPageTeaser, isAuthenticated);

    const firstName = personalDetails?.identification.name?.split(' ')[0];

    return (
        <IntroductionPageTeaserWithHandlers
            isLoading={isLoadingContent || isLoadingProfile}
            introductionPageTeaser={introductionPageTeaser}
            firstName={firstName}
            hasError={!!loadingErrorContent}
            loadingPlacehloder={
                <StaticPageLoadingPlaceholder>
                    <Skeleton height={200} />
                    <Skeleton height={300} />
                    <div className={'u-text-center u-mt-large'}>
                        <Skeleton height={40} width={200} />
                    </div>
                </StaticPageLoadingPlaceholder>
            }
        />
    );
};
