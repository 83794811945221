import { ErrorPage, getUnauthorizedErrorPageEndpoint } from '@cp-ie/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<ErrorPage>({
    contentName: 'unauthorizedErrorPage',
    contentEndpoint: getUnauthorizedErrorPageEndpoint,
});

export default reducer;
export const fetchUnauthorizedErrorPage = fetchContent;
