import { useContract } from 'components/contracts/useContract';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useMyProfile } from 'components/my-profile';
import { dashboardPagePath } from 'components/navigation/paths';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { DigitalRenewalUi } from './ui/DigitalRenewalUi';

type DigitalRenewalProps = {
    encryptedContractNumber: string;
};
const DigitalRenewalWithHandlers = withLoadingAndNoConnectionHandler(DigitalRenewalUi);

export const DigitalRenewal: React.FC<DigitalRenewalProps> = ({ encryptedContractNumber }) => {
    const history = useHistory();

    const { data: contract, isLoading: isContractLoading, loadingError: loadingContractError } = useContract(
        encryptedContractNumber,
        {
            encryptedContractNumber: true,
        },
    );

    const { data: personalDetails, isLoading: isProfileLoading, loadingError: loadingProfileError } = useMyProfile();

    useEffect(() => {
        if (!contract?.isEligibility || !contract.isDealerSelection) {
            history.push(dashboardPagePath());
        }
    }, [history, contract]);

    return (
        <DigitalRenewalWithHandlers
            contract={contract}
            name={personalDetails?.identification.name}
            isLoading={isContractLoading || isProfileLoading}
            hasError={!!(loadingContractError || loadingProfileError)}
        />
    );
};
