import React from 'react';
import { Heading, ButtonContainer, Button, Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { changeIbanPath } from '../../../../../../navigation/paths';

export const DigitalSignRejection: React.FC = () => {
    const { t } = useTranslation('digital-sign');
    const history = useHistory();
    const { encryptedContractNumber } = useParams<{ encryptedContractNumber: string }>();

    return (
        <Layout className="u-text-center">
            <Layout.Item>
                <Heading level="1" className="u-text-brand u-mt">
                    {t('rejectionPage.headline')}
                </Heading>
            </Layout.Item>
            <Layout.Item>
                <i className="c-icon  c-icon--[semantic-error]  c-icon--xxlarge u-text-alert" />
            </Layout.Item>
            <Layout.Item m="1/1" default="2/3">
                <Heading level="6" className="u-text-brand">
                    {t('rejectionPage.subHeadline')}
                </Heading>
            </Layout.Item>
            <Layout.Item m="1/1" default="1/2">
                <ButtonContainer center>
                    <Button
                        testId="tryAgainButton"
                        onClick={() => history.push(changeIbanPath(encryptedContractNumber))}
                    >
                        {t('rejectionPage.button')}
                    </Button>
                </ButtonContainer>
            </Layout.Item>
            <Layout.Item m="1/1" default="2/3">
                <div className="u-m" dangerouslySetInnerHTML={{ __html: t('rejectionPage.customerCareDetails') }} />
            </Layout.Item>
        </Layout>
    );
};
