import React from 'react';
import { DataOverview } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { Identification } from '@cp-ie/common';
import { DefinitionList, DefinitionListItem, Notification, NotificationStatus } from '@cp-shared-6/frontend-ui';

export const IdentificationSection: React.FC<{ identification: Identification }> = ({ identification }) => {
    const { t } = useTranslation('my-profile');

    if (!identification.id) {
        return (
            <Notification
                status={NotificationStatus.error}
                headline={t('identification.business-error.title')}
                text={t('identification.business-error.text')}
            />
        );
    }

    const identificationList: DefinitionListItem[] = [
        {
            label: t('identification.items.label.name'),
            value: identification.name,
            testId: 'identification-email',
        },
    ];

    return (
        <DataOverview title={t('identification.title')}>
            <DefinitionList split={true} list={identificationList} />
        </DataOverview>
    );
};
