import { AuthorizationResult, Spinner } from '@cp-shared-6/frontend-ui';
import { useContracts } from 'components/contracts';
import { useMarketingPreferences } from 'components/my-profile/marketing-preferences-sections';
import {
    contactDetailsHookPagePath,
    dashboardPagePath,
    introductionPagePath,
    marketingPreferencesHookPagePath,
} from 'components/navigation/paths';
import { lastContactDetailsHookPageKey } from 'config';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthorizationFlow } from '../../auth';
import { isIntroductionHookPage, isUserDetailsHookPage } from './utils';
import { useMyProfile } from '../my-profile';

export const HookPageRedirection: React.FC = () => {
    const history = useHistory();
    const { state } = useAuthorizationFlow();

    const { data: contracts, hasReceivedResponse: hasReceivedContractsResponse, loadingError } = useContracts();
    const {
        data: marketingPreferences,
        hasReceivedResponse: hasReceivedPreferencesResponse,
    } = useMarketingPreferences();

    const lastContactDetailsHookPage = window.localStorage.getItem(lastContactDetailsHookPageKey);

    const showUserDetailsHookPage = isUserDetailsHookPage(
        (state as AuthorizationResult<{ isAuthorized: boolean; lastAccess: string }>).lastAccess,
        lastContactDetailsHookPage || '',
    );
    const { data: personalDetails } = useMyProfile();

    const isEmailInMarketingPreferencesSet = marketingPreferences?.vwfs.email;

    const isContactEmailDefined = !!personalDetails?.contactDetails.email;

    useEffect(() => {
        if (hasReceivedPreferencesResponse && hasReceivedContractsResponse) {
            if (isIntroductionHookPage()) {
                history.push(introductionPagePath());
            } else if (showUserDetailsHookPage) {
                if (isEmailInMarketingPreferencesSet) {
                    history.push(contactDetailsHookPagePath());
                } else {
                    history.push(marketingPreferencesHookPagePath());
                }
            }
            if (!isContactEmailDefined) {
                history.push(contactDetailsHookPagePath());
            } else {
                history.push(dashboardPagePath());
            }
        }
    }, [
        history,
        hasReceivedContractsResponse,
        loadingError,
        contracts,
        marketingPreferences,
        hasReceivedPreferencesResponse,
        isEmailInMarketingPreferencesSet,
        showUserDetailsHookPage,
    ]);

    return <Spinner fullPage />;
};
