import React, { useState } from 'react';
import { ContractType, FinancialDetails } from '@cp-ie/common';
import { BaseSection } from '../base-section/BaseSection';
import { Hr } from '@vwfs-bronson/bronson-react';
import { BankAccountSection } from '../bank-account-section/BankAccountSection';
import { PaymentDaySection } from '../payment-day-section/PaymentDaySection';
import { ContractIbanNotification } from '../../notifications';
import { ContractProgress } from './contract-progress';

type FinancialDetailsUiProps = {
    financialDetails?: FinancialDetails;
    encryptedContractNumber: string;
    contractType?: ContractType;
    outstandingBalance?: number;
    inArrears?: boolean;
};

export const FinancialDetailsUi: React.FC<FinancialDetailsUiProps> = ({
    financialDetails,
    encryptedContractNumber,
    contractType,
    outstandingBalance,
    inArrears,
}) => {
    const [isIbanChanged, setIsIbanChanged] = useState(false);

    if (!financialDetails || !encryptedContractNumber || !outstandingBalance || !contractType) {
        return null;
    }

    const {
        monthlyPaymentDay,
        isActive,
        financedAmount,
        finalPaymentAmount,
        finalPaymentDate,
        guaranteedMinimumFutureValue,
        startDate,
    } = financialDetails;

    const onChangeIban = (): void => {
        setIsIbanChanged(true);
    };

    return (
        <>
            {!inArrears && (
                <ContractProgress
                    contractType={contractType}
                    outstandingBalance={outstandingBalance}
                    financedAmount={financedAmount}
                    finalPaymentAmount={finalPaymentAmount}
                    finalPaymentDate={finalPaymentDate}
                    startDate={startDate}
                    guaranteedMinimumFutureValue={guaranteedMinimumFutureValue}
                />
            )}{' '}
            <Hr className="u-mb-10" />
            <BaseSection details={financialDetails} contractType={contractType} />
            <Hr className="u-mb-none" />
            <ContractIbanNotification
                isIbanChanged={isIbanChanged}
                iban={financialDetails.iban}
                encryptedContractNumber={encryptedContractNumber}
            />
            <BankAccountSection
                details={financialDetails}
                encryptedContractNumber={encryptedContractNumber}
                onChangeIban={onChangeIban}
            />
            {!monthlyPaymentDay || !isActive ? null : (
                <>
                    <Hr className="u-mb-none" />
                    <PaymentDaySection details={financialDetails} encryptedContractNumber={encryptedContractNumber} />
                </>
            )}
        </>
    );
};
