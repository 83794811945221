import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContractType, formatAsCurrency, formatAsDate } from '@cp-ie/common';
import { ContractHeader, ContractSummary, ContractSummaryItemProps } from '@cp-shared-6/frontend-ui';
import { CPDate } from '@cp-shared-6/common-utilities';

export type HeaderProps = {
    isActive?: boolean;
    contractNumber: string;
    nextPaymentAmount?: number;
    contractType?: ContractType;
    paymentDate?: CPDate;
    outstandingBalance?: number;
    inArrears?: boolean;
};

export const Header: React.FC<HeaderProps> = ({
    isActive,
    contractNumber,
    nextPaymentAmount,
    contractType,
    paymentDate,
    outstandingBalance,
    inArrears,
}) => {
    const { t } = useTranslation('financial-contract-header');

    const contractCategory =
        contractType === ContractType.PERSONAL_CONTRACT_PLAN
            ? t('product-type.personal-contract-plan')
            : t('product-type.hire-purchase');

    const paymentDateValue = formatAsDate(paymentDate);
    const nextPaymentAmountValue = nextPaymentAmount ? formatAsCurrency(nextPaymentAmount) : '-';
    const items: Array<ContractSummaryItemProps> = isActive
        ? [
              { value: paymentDateValue || '-', label: t('payment-date'), testClass: 'payment-date' },
              {
                  value: nextPaymentAmountValue,
                  label: t('next-payment-amount'),
                  testClass: 'next-payment-amount',
              },
          ]
        : [];

    if (isActive && outstandingBalance && !inArrears)
        items.push({
            value: formatAsCurrency(outstandingBalance),
            label: t('outstanding-balance'),
            testClass: 'outstanding-balancee',
            tooltip: t('outstanding-balance-tooltip'),
        });

    const contractSummaryComponent = <ContractSummary items={items} />;

    const carInformation = `${contractCategory} ${contractNumber}`;

    return (
        <ContractHeader
            carInformation={carInformation}
            contractCategory={''}
            contractNumber={''}
            contractSummary={contractSummaryComponent}
        />
    );
};
