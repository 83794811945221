import { Brand, Contract, ContractDataWithLinks, FinancialDetails } from '@cp-ie/common';
import { contractsWithHiddenHookPageLocalStorageKey } from '../config/config';
import { AbstractContractBasedDataState } from '@cp-shared-6/frontend-integration/build/contractBasedDataFetchSlice';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-6/common-utilities';

type ContractWithBrand = Contract & { brand: Brand };

export type GroupedContractsByVin = Array<ContractDataWithLinks[]>;

export const getMostRecentContractWithBrand = (contracts: Contract[] | undefined): ContractWithBrand | undefined => {
    const contractsWithBrand: ContractWithBrand[] = (contracts?.filter(contract => !!contract.brand) ||
        []) as ContractWithBrand[];

    if (!contractsWithBrand?.length) return undefined;
    return contractsWithBrand.sort((a, b) => ((a.startDate || '') > (b.startDate || '') ? -1 : 1))[0];
};

export const areContractsOneBrand = (contracts?: Contract[] | undefined): boolean | undefined => {
    return contracts?.every(item => item.brand === contracts[0].brand);
};

export const findActiveContractWithLatestEndDate = (contracts?: Contract[]): Contract | undefined => {
    if (!contracts?.length) {
        return;
    }

    return contracts
        .filter(contract => contract.isActive)
        .reduce((a, b) => ((a.endDate || '') < (b.endDate || '') ? a : b));
};

export const findDigitalRenewalContractWithLatestEndDate = (contracts?: Contract[]): Contract | undefined => {
    if (!contracts?.length) {
        return;
    }

    const digitalRenewalContracts = contracts
        .filter(contract => contract.isEligibility && contract.isDealerSelection)
        .filter(contract => contract.brand !== Brand.VWCV)
        .sort((a, b) => ((a.endDate || '') < (b.endDate || '') ? -1 : 1));

    const contractsWithHiddenHookPage: string[] = (
        window.localStorage.getItem(contractsWithHiddenHookPageLocalStorageKey) || ''
    ).split(',');

    return digitalRenewalContracts.find(contract => {
        return contract.contractNumber && !contractsWithHiddenHookPage.includes(contract.contractNumber);
    });
};

export const groupContractsByVin = (contracts: ContractDataWithLinks[]): GroupedContractsByVin => {
    return contracts.reduce((acc: GroupedContractsByVin, current) => {
        const groupIndex = acc.findIndex(group => group.some(contract => contract.vin === current.vin));
        if (groupIndex > -1) {
            acc[groupIndex].push(current);
        } else {
            acc.push([current]);
        }
        return acc;
    }, []);
};

export const isBankLeavingIban = (iban: string) => {
    return /^.*(ICON|ULSB).*$/g.test(iban);
};

export const getAllActiveContractsIbans = (
    financialDetails: AbstractContractBasedDataState<FinancialDetails, DefaultBusinessMarketApiErrorCode>,
): string[] =>
    Object.values(financialDetails)
        .map(({ data }) => (data?.isActive ? data?.iban : undefined))
        .filter(Boolean) as string[];
