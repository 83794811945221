import { Dispatch, SetStateAction, useState } from 'react';
import { ActionType, useAnalyticsActionTracker } from '@cp-shared-6/frontend-ui';

export const useTrackerOnBeforeUnload = (
    shouldBeTracked = true,
    trackingId: ActionType = 'onConfirmIdentityAborted',
): Dispatch<SetStateAction<string | undefined>> => {
    const [lastTouchedField, setLastTouchedField] = useState<string>();

    const { onAction } = useAnalyticsActionTracker(trackingId);

    window.onbeforeunload = (): void => {
        if (shouldBeTracked) {
            onAction(lastTouchedField);
        }
        return;
    };

    return setLastTouchedField;
};
