import React from 'react';
import { Layout, Fieldset, Form, Button, Link } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import {
    ValidatedInput,
    preventSubmit,
    ScrollToTopOnNavigation,
    Notification,
    NotificationStatus,
} from '@cp-shared-6/frontend-ui';
import { CustomerRegistrationError, RegistrationSmsConfirmationError } from '@cp-ie/common';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-6/common-utilities';

import { identificationFormValidationSchema } from './validation';

export type FormProps = {
    authorizationCode: string;
};

type SmsIdentificationFormProps = {
    onResendCode: () => void;
    onSmsCodeConfirmation: (values: FormProps) => void;
    lastFourDigitsOfPhoneNumber: string;
    errorCode?: WithDefaultCpIntegrationErrors<CustomerRegistrationError | RegistrationSmsConfirmationError>;
};

export const SmsIdentificationForm: React.FC<SmsIdentificationFormProps> = ({
    onResendCode,
    onSmsCodeConfirmation,
    lastFourDigitsOfPhoneNumber,
    errorCode,
}) => {
    const { t } = useTranslation('registration');

    const getErrorNotificationLabel = (): string => {
        let label = '';
        switch (errorCode) {
            case undefined:
            case 'EXCEEDED_LIMIT':
                break;
            case 'INVALID_VERIFICATION_CODE':
                label = t('error.invalid-code');
                break;
            case 'EXPIRED_VERIFICATION_CODE':
                label = t('error.expired-code');
                break;
            case 'NO_VERIFICATION_CODE':
                label = t('error.code-not-found');
                break;
            default:
                label = t('error.default');
                break;
        }
        return label;
    };

    return (
        <>
            <Layout.Item className={'u-mb u-text-center'}>
                <h1>{t('head.title')}</h1>
                <p>
                    {t('head.info-sms-code')}
                    {` *** ***${lastFourDigitsOfPhoneNumber}.`}
                </p>
                {errorCode === 'EXCEEDED_LIMIT' && (
                    <p className="u-text-alert">
                        {t('head.error-info-receive-sms-code')}{' '}
                        <Link
                            className="u-text-alert"
                            href={`tel:${t('head.info-receive-sms-code.phone-number')}`}
                            normalLink
                        >
                            {
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t('head.info-receive-sms-code.phone-number'),
                                    }}
                                />
                            }
                        </Link>
                    </p>
                )}
                {errorCode !== 'EXCEEDED_LIMIT' && (
                    <p>
                        {t('head.info-receive-sms-code.start-text')}{' '}
                        <Link onClick={onResendCode} testId="onResendCodeRequestLink" normalLink>
                            {t('head.info-receive-sms-code.link')}
                        </Link>{' '}
                        {t('head.info-receive-sms-code.end-text')}{' '}
                        <Link href={`tel:${t('head.info-receive-sms-code.phone-number')}`} normalLink>
                            {
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t('head.info-receive-sms-code.phone-number'),
                                    }}
                                />
                            }
                        </Link>
                        {'.'}
                    </p>
                )}
                {errorCode && errorCode !== 'EXCEEDED_LIMIT' && (
                    <ScrollToTopOnNavigation>
                        <Notification
                            testId={'errorSmsIdentificationForm'}
                            status={NotificationStatus.error}
                            text={getErrorNotificationLabel()}
                        />
                    </ScrollToTopOnNavigation>
                )}
            </Layout.Item>
            <Layout.Item>
                <Formik
                    initialValues={{ authorizationCode: '' }}
                    validationSchema={identificationFormValidationSchema(t)}
                    onSubmit={onSmsCodeConfirmation}
                >
                    {formik => (
                        <Form onSubmit={preventSubmit()}>
                            <Fieldset>
                                <Fieldset.Row>
                                    <ValidatedInput
                                        label={t('sms-form.labels.authorization-code')}
                                        testId="authorizationCode"
                                        name="authorizationCode"
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <Button full={true} onClick={formik.submitForm} testId={'submitButton'}>
                                        {t('sms-form.labels.submit')}
                                    </Button>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            </Layout.Item>
        </>
    );
};
