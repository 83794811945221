import React, { useState } from 'react';
import { ContentSection, PageWrap, Layout, Logo } from '@vwfs-bronson/bronson-react';

import {
    LegalNotice,
    CustomerRegistrationData,
    CustomerRegistrationResult,
    CustomerRegistrationMultipleUserResult,
} from '@cp-ie/common';

import { Identification } from './identification';
import { SmsIdentification } from './sms-identification';
import { useTrackerOnBeforeUnload } from '../../../hooks';
import { KycContractNumber } from '../../hook-page';

type ViewTypes = 'REGISTRATION' | 'CONTRACT_NUMBER_VERIFICATION' | 'SMS_CONFIRMATION';

type CustomerRegistration = {
    customerData?: CustomerRegistrationData;
    lastFourDigitsOfPhoneNumber?: string;
    customerId?: string;
};

export const RegistrationUi: React.FC<{
    legalNotice?: LegalNotice;
}> = ({ legalNotice }) => {
    const [currentView, setCurrentView] = useState<ViewTypes>('REGISTRATION');
    const [customerRegistrationData, setCustomerRegistrationData] = useState<CustomerRegistration>({});
    const [contractNumber, setContractNumber] = useState('');

    const setLastTouchedField = useTrackerOnBeforeUnload(currentView === 'REGISTRATION');

    if (!legalNotice) {
        return null;
    }

    const onSubmitSuccess = (
        registrationResponse: CustomerRegistrationResult | CustomerRegistrationMultipleUserResult,
        customerData: CustomerRegistrationData,
    ): void => {
        if ('isMultipleUser' in registrationResponse) {
            setCustomerRegistrationData({ customerData });
            setCurrentView('CONTRACT_NUMBER_VERIFICATION');
        } else {
            setCustomerRegistrationData({
                lastFourDigitsOfPhoneNumber: registrationResponse.lastFourDigitsOfPhoneNumber,
                customerData,
                customerId: registrationResponse.customerId,
            });
            setCurrentView('SMS_CONFIRMATION');
        }
    };

    const onContractNumberSubmitSuccess = (lastFourDigitsOfPhoneNumber: string, customerId: string) => {
        setCustomerRegistrationData(prevState => {
            return {
                ...prevState,
                customerId,
                lastFourDigitsOfPhoneNumber,
            };
        });
        setCurrentView('SMS_CONFIRMATION');
    };

    const { lastFourDigitsOfPhoneNumber, customerData, customerId } = customerRegistrationData;

    return (
        <ContentSection>
            <PageWrap size={'xsmall'}>
                <Layout className={'u-mt-large'}>
                    <Layout.Item default={'1/1'}>
                        <Logo className={'u-block-center'} />
                    </Layout.Item>
                    {currentView === 'REGISTRATION' && (
                        <Identification
                            legalNotice={legalNotice.legalNoticeContent || ''}
                            onSubmitSuccess={onSubmitSuccess}
                            setLastTouchedField={setLastTouchedField}
                        />
                    )}
                    {currentView === 'CONTRACT_NUMBER_VERIFICATION' && (
                        <KycContractNumber
                            setContractNumber={setContractNumber}
                            onSubmitSuccess={onContractNumberSubmitSuccess}
                        />
                    )}
                    {currentView === 'SMS_CONFIRMATION' && customerData && customerId && (
                        <SmsIdentification
                            customerData={customerData}
                            customerId={customerId}
                            lastFourDigitsOfPhoneNumber={lastFourDigitsOfPhoneNumber || ''}
                            contractNumber={contractNumber}
                        />
                    )}
                </Layout>
            </PageWrap>
        </ContentSection>
    );
};
