import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { Step } from './edit-view.types';

const getPostcodeRegEx = (): RegExp => {
    const validLeters = ['A', 'C', 'D', 'E', 'F', 'H', 'K', 'N', 'P', 'R', 'T', 'V', 'W', 'X', 'Y'].join('');
    const pos1RegEx = `[${validLeters}]{1}`;
    const pos23RegEx = `[0-9]{2}`;
    const pos13OptionalRegEx = 'D6W';
    const spaceRegEx = `\\s?`;
    const pos47RegExp = `[0-9${validLeters}]{4}`;
    return new RegExp(`^(${pos1RegEx}${pos23RegEx}|${pos13OptionalRegEx})${spaceRegEx}${pos47RegExp}$`, 'i');
};

export const validationSchema = (t: TFunction, step: Step) => {
    const translationPrefix = 'edit-view.form-view.validation-errors';
    return Yup.object().shape({
        files: Yup.array().when('$other', {
            is: () => {
                return step === Step.SECOND;
            },
            then: Yup.array().required(t('edit-view.file-upload-view.validation-errors.no-file-error')),
        }),
        address: Yup.object().shape(
            {
                addressLine1: Yup.string().when('addressLine2', {
                    is: addressLine2 => !addressLine2,
                    then: Yup.string()
                        .trim()
                        .required(t(`${translationPrefix}.first-address-error`)),
                    otherwise: Yup.string().trim(),
                }),
                addressLine2: Yup.string().when('addressLine1', {
                    is: addressLine1 => !addressLine1,
                    then: Yup.string()
                        .trim()
                        .required(t(`${translationPrefix}.second-address-error`))
                        .max(60, t(`${translationPrefix}.second-address-error`)),
                    otherwise: Yup.string()
                        .trim()
                        .max(60, t(`${translationPrefix}.second-address-error`)),
                }),
            },
            [['addressLine1', 'addressLine2']],
        ),
        townOrCity: Yup.string()
            .trim()
            .required(t(`${translationPrefix}.town-or-city-error`))
            .max(40, t(`${translationPrefix}.town-or-city-error`)),
        countyCode: Yup.string().required(t(`${translationPrefix}.county-error`)),
        postcode: Yup.string()
            .required(t(`${translationPrefix}.postcode-error`))
            .matches(getPostcodeRegEx(), t(`${translationPrefix}.postcode-error`)),
    });
};
