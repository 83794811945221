import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const validationSchema = (t: TFunction) => {
    const translationPrefix = 'bank-account-section.iban.edit-view.digital-sign';
    const requiredBankAccountHolderError = t(`${translationPrefix}.bank-account-holder.validation.required`);
    const requiredEmailError = t(`${translationPrefix}.email.validation.required`);
    const invalidEmailError = t(`${translationPrefix}.email.validation.invalid`);
    return Yup.object().shape({
        bankAccountHolder: Yup.string()
            .trim()
            .required(requiredBankAccountHolderError),
        email: Yup.string()
            .trim()
            .required(requiredEmailError)
            .email(invalidEmailError),
    });
};
