import React from 'react';
import { RegistrationLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-6/frontend-ui';
import { useLegalNotice } from 'components/legal-notice';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { RegistrationUi } from './ui';

const RegistrationWithHandlers = withLoadingAndNoConnectionHandler(RegistrationUi);

export const Registration: React.FC = () => {
    const { cmsContent: legalNotice, isLoading, loadingError } = useLegalNotice();

    useAnalyticsPageViewTracker('confirmIdentity', !!legalNotice);

    return (
        <RegistrationWithHandlers
            isLoading={isLoading}
            legalNotice={legalNotice}
            hasError={!!loadingError}
            loadingPlacehloder={<RegistrationLoadingPlaceholder summaryElements={5} />}
        />
    );
};
