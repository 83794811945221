import { Files } from '@cp-ie/common';

export enum Step {
    FIRST,
    SECOND,
}

export type AddressFormSchema = {
    address?: {
        addressLine1?: string;
        addressLine2?: string;
    };
    townOrCity?: string;
    countyCode?: string;
    postcode?: string;
};

export type AddressWithFilesFormSchema = AddressFormSchema & Files;
