import React, { FC } from 'react';
import { HereMap } from '@vwfs-its/sf-heremaps-frontend';
import { mapToHereMapsMarkers, MarkersInfo } from './utils';
import { currentBrand } from 'config';
import { Brand } from '@vwfs-its/sf-heremaps-frontend/dist/components/HereMaps/HereMap';
import { TFunction } from 'i18next';

export type MarkerId = {
    companyId: string;
    regionId: string;
};

export type HereMapComponentProps = {
    latitude: number;
    longitude: number;
    markersInfo: MarkersInfo[];
    /*
     * height and width with px or % suffix. Example: 300px
     */
    height: string;
    width: string;
    hasBubble: boolean;
    translation?: TFunction;
    setDealerCode?: (markerId: MarkerId | string) => void;
    zoom?: number;
};

export const HereMapComponent: FC<HereMapComponentProps> = ({
    latitude,
    longitude,
    markersInfo,
    setDealerCode,
    height,
    width,
    hasBubble,
    zoom,
    translation,
}) => {
    const API_KEY = process.env.REACT_APP_HERE_API_KEY || '';

    const onClickMarker = (markerId: string): void => {
        if (setDealerCode) setDealerCode(markerId);
    };

    return (
        <HereMap
            apikey={API_KEY}
            height={height}
            width={width}
            mapLatitude={latitude}
            mapLongitude={longitude}
            mapLanguage={'en'}
            uiLanguage={'en-US'}
            onClickMarker={onClickMarker}
            className={'here-map-component'}
            markers={mapToHereMapsMarkers(markersInfo, hasBubble, translation)}
            zoom={zoom || 4}
            controlsVisibility={false}
            dynamicBubble={hasBubble}
            singleBubble={false}
            brandStyle={currentBrand.toString() as Brand}
            clustering={true}
            clusteringRadius={30}
        />
    );
};
