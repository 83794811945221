import { MarketingPreference } from '@cp-ie/common';

export type MarketingPreferencesFormValues = {
    preferences: MarketingPreference;
    emailAddress: string;
    confirmPrivacyPolicy: boolean;
};

export const getMarketingPreferencesInitialValues = (
    marketingPreference?: MarketingPreference,
): MarketingPreferencesFormValues => {
    return {
        preferences: {
            email: marketingPreference?.email || false,
            sms: marketingPreference?.sms || false,
            phone: marketingPreference?.phone || false,
            post: marketingPreference?.post || false,
        },
        confirmPrivacyPolicy: false,
        emailAddress: '',
    };
};
