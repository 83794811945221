import React, { useEffect, useState } from 'react';
import { HereMapComponent, MarkersInfo } from '../here-map';
import { DealerDetails } from '@cp-ie/common';
import { Skeleton } from '@cp-shared-6/frontend-ui';
import { CpDataApi } from '../../cp-xhr';
import { getAddressValidationEndpoint } from '@cp-shared-6/apis';

type dealerMapsProps = {
    dealerDetails: DealerDetails;
    height?: string;
    width?: string;
    zoom?: number;
};

export const DealerMap: React.FC<dealerMapsProps> = ({ dealerDetails, height = '100%', width = '100%', zoom = 10 }) => {
    const { billingAddress, name = '', phoneNumber } = dealerDetails;
    const { postalCode = '', city = '', street = '', latitude, longitude } = billingAddress;

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [markersInfo, setMarkersInfo] = useState<MarkersInfo>({
        bubbleInfo: {
            infoProps: {
                companyName: name,
                city: city,
                zipCode: postalCode,
                street: street,
                houseNumber: '',
                mobilePhoneNumber: phoneNumber,
            },
        },
        coordinates: {
            latitude: 0,
            longitude: 0,
        },
    });

    const getCoordinates = (): void => {
        if (latitude !== undefined && longitude !== undefined) {
            setMarkersInfo({ ...markersInfo, coordinates: { latitude, longitude } });
            setIsLoading(false);
            return;
        }
        // Ireland uses the eircode system, so the unique postal code is enough to identify an address
        CpDataApi.get(getAddressValidationEndpoint(), { params: { countryCode: 'IE', query: postalCode } })
            .then(({ data }) => {
                const { lat, lng } = data.queryLocation ?? {};
                if (lat !== undefined && lng !== undefined)
                    setMarkersInfo({
                        ...markersInfo,
                        coordinates: {
                            latitude: lat,
                            longitude: lng,
                        },
                    });
                else setError(true);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getCoordinates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealerDetails]);

    const dealerMapComponent = () => {
        if (isLoading) {
            return <Skeleton height={height} width={width} />;
        }
        if (!isLoading && !error) {
            return (
                <HereMapComponent
                    width={width}
                    height={height}
                    hasBubble={true}
                    zoom={zoom}
                    latitude={markersInfo.coordinates.latitude}
                    longitude={markersInfo.coordinates.longitude}
                    markersInfo={[markersInfo]}
                    setDealerCode={undefined}
                />
            );
        }
        return null;
    };

    return dealerMapComponent();
};
