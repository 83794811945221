import React, { useEffect, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import { useContracts } from '../../../../useContracts';
import { Button, ButtonContainer, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import {
    CleaveInput,
    DataOverview,
    preventSubmit,
    UiBlockingSpinner,
    useAnalyticsActionTracker,
    ValidatedCheckbox,
} from '@cp-shared-6/frontend-ui';
import { BankData, SignedData } from '@cp-shared-6/apis';
import { Iban } from '@cp-ie/common';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { ibanValidationSchema, syncIbanValidation } from './validationSchema';
import { IbanStateHandler, SavedIban } from './types';

export type IbanValidationViewProps = {
    previousIban?: string;
    onValidIban: (validateIban: Iban) => void;
    toPreviousView: () => void;
    isFirstView?: boolean;
};

type FormType = {
    iban: string;
    applyToAllContracts: boolean;
};
const translationPrefix = 'bank-account-section.iban.edit-view.iban-validation';
const translationInputPrefix = `${translationPrefix}.input`;
const cleaveOptionsIban = {
    delimiter: ' ',
    blocks: [4, 4, 4, 4, 4, 4, 4, 4],
    numericOnly: false,
};

export const IbanValidationView: React.FC<IbanValidationViewProps> = ({
    previousIban,
    toPreviousView,
    onValidIban,
    isFirstView,
}) => {
    const { t } = useTranslationWithFormatting('financial-details');
    const [isValidating, setIsValidating] = useState(false);
    const [signedBankData, setSignedBankData] = useState<SignedData<BankData>>();
    const [savedIban, setSavedIban] = useState<SavedIban>({});
    const { data: contracts } = useContracts();
    const activeContracts = contracts?.filter(contract => contract.isActive);
    const ibanStateHandler: IbanStateHandler = {
        signedBankData,
        setSignedBankData,
        savedIban,
        setSavedIban,
    };

    const { onAction: onEditBankAccount } = useAnalyticsActionTracker('onDashboardEditBankAccountChange');
    const { onAction: onTyping } = useAnalyticsActionTracker('financialDetailsEditIbanSectionStartTyping');
    const { onAction: onValidationError } = useAnalyticsActionTracker('financialDetailsEditIbanSectionValidationError');

    const initialValues: FormType = {
        iban: '',
        applyToAllContracts: false,
    };

    useEffect(() => {
        onEditBankAccount();
    }, []);

    const handleSubmit = ({ applyToAllContracts: allContracts }: FormType): void => {
        if (signedBankData) {
            onValidIban({ signedBankData, allContracts });
        }
    };

    const handleChange = (formik: FormikProps<FormType>) => (e: React.ChangeEvent<{ rawValue: string }>): void => {
        if (syncIbanValidation(t, previousIban).isValidSync(e.target.rawValue)) {
            setTimeout(() => formik.setFieldTouched('iban'), 0);
        }
    };

    return (
        <DataOverview title={t(`${translationPrefix}.title`)} withoutCardEffect={true}>
            <Formik
                initialValues={initialValues}
                validationSchema={ibanValidationSchema(
                    t,
                    ibanStateHandler,
                    isValidating,
                    setIsValidating,
                    previousIban,
                )}
                onSubmit={handleSubmit}
                validateOnBlur={!signedBankData}
            >
                {(formik): JSX.Element => (
                    <Form
                        onSubmit={preventSubmit()}
                        onChange={(): void => {
                            onTyping();
                        }}
                    >
                        <Fieldset className="u-mb-small">
                            <Fieldset.Row>
                                <span dangerouslySetInnerHTML={{ __html: t(`${translationPrefix}.subtitle`) }} />
                            </Fieldset.Row>
                        </Fieldset>
                        <Fieldset>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item default="1/2" s="1/1">
                                        <UiBlockingSpinner isBlocking={isValidating}>
                                            <CleaveInput
                                                cleaveOptions={cleaveOptionsIban}
                                                label={t(`${translationInputPrefix}.label`)}
                                                name="iban"
                                                testId="iban"
                                                tooltip={t(`${translationInputPrefix}.tooltip`)}
                                                placeholder={t(`${translationInputPrefix}.help-text`)}
                                                stateIcon
                                                handleChange={handleChange(formik)}
                                            />
                                            {signedBankData?.data.isValid && (
                                                <p className="u-text-success">
                                                    {signedBankData.data.bankDetails?.bankName}
                                                </p>
                                            )}
                                        </UiBlockingSpinner>
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                        </Fieldset>
                        {activeContracts && activeContracts?.length > 1 && (
                            <Fieldset>
                                <Fieldset.Row>
                                    <Layout>
                                        <Layout.Item default="1/2" s="1/1">
                                            <ValidatedCheckbox
                                                label={t(`${translationInputPrefix}.checkbox`)}
                                                name={'applyToAllContracts'}
                                                testId={'applyToAllContracts'}
                                            />
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                            </Fieldset>
                        )}
                        <Fieldset>
                            <Fieldset.Row>
                                <ButtonContainer center>
                                    <Button secondary onClick={toPreviousView} testId="cancelButton" type="button">
                                        {t(`translation:editable-section-nav.${isFirstView ? 'cancel' : 'back'}`)}
                                    </Button>
                                    <Button
                                        onClick={async (): Promise<void> => {
                                            if (
                                                (isEmpty(formik.touched) && isEmpty(formik.errors)) ||
                                                !isEmpty(formik.errors)
                                            )
                                                onValidationError('Iban');
                                            await formik.handleSubmit();
                                        }}
                                        testId="submitButton"
                                        type="submit"
                                    >
                                        {t('translation:editable-section-nav.continue')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </DataOverview>
    );
};
