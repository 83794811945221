import { Card, Layout } from '@vwfs-bronson/bronson-react';
import { ContractHeaderLoadingPlaceholder, Skeleton } from '@cp-shared-6/frontend-ui';
import React from 'react';

export const ContractEndOptionsLoadingPlaceholder: React.FC = () => (
    <Layout>
        <Layout.Item className={'u-mt-large'}>
            <Skeleton height={60} width={400} />
        </Layout.Item>
        <Layout.Item>
            <Card element="article">
                <ContractHeaderLoadingPlaceholder summaryElements={0} />
            </Card>
        </Layout.Item>
        <Layout.Item className={'u-mb'}>
            <Skeleton height={40} width={200} />
            <Skeleton count={1} height={40} className={'u-mt'} />
        </Layout.Item>
        {Array.from(Array(3).keys()).map(key => (
            <Layout.Item key={key} default={'1/3'} s={'1/1'}>
                <Card element="article">
                    <Skeleton height={50} count={1} className={'u-mt-large'} />
                    <Skeleton height={35} count={2} />
                </Card>
            </Layout.Item>
        ))}
        <Layout.Item className={'u-mt u-text-center'}>
            <Skeleton height={40} width={300} className="u-m-xsmall" count={2} />
        </Layout.Item>
    </Layout>
);
