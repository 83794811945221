import { Brand, ContractInfo, ContractType } from '@cp-ie/common';

export const ContractsInfoWithAllValues: ContractInfo[] = [
    {
        isActive: true,
        brand: Brand.VW,
        model: 'Golf',
        contractNumber: '1234567',
        encryptedContractNumber: 'AX12',
        licensePlate: '12-AB-34',
        contractType: ContractType.HIRE_PURCHASE,
    },
    {
        isActive: true,
        brand: Brand.VW,
        model: 'Passat',
        contractNumber: '1122334',
        encryptedContractNumber: 'AX11',
        licensePlate: '13-AB-34',
        contractType: ContractType.PERSONAL_CONTRACT_PLAN,
    },
];

export const ContractsInfoWithMissingValues: ContractInfo[] = [
    {
        isActive: true,
        contractNumber: '1234567',
        encryptedContractNumber: 'AX12',
        contractType: ContractType.HIRE_PURCHASE,
    },
    {
        isActive: true,
        brand: Brand.VW,
        model: 'Passat',
        contractNumber: '1122334',
        encryptedContractNumber: 'AX11',
        licensePlate: '13-AB-34',
        contractType: ContractType.PERSONAL_CONTRACT_PLAN,
    },
];

export const ContractsInfoWithExpiredContract: ContractInfo[] = [
    {
        isActive: false,
        brand: Brand.VW,
        model: 'Passat',
        contractNumber: '1122334',
        encryptedContractNumber: 'AX11',
        licensePlate: '13-AB-34',
        contractType: ContractType.PERSONAL_CONTRACT_PLAN,
    },
];
