import { getScriveRejectionPath, getScriveSuccessPath } from '@cp-ie/common';

// The following URLs needs to be whitelisted within IDK (including domain and port)
export function loginAnalyticsPath(): string {
    return 'login';
}

export function notFoundPagePath(): string {
    return '/not-found';
}

export function registrationPagePath(): string {
    return '/register';
}

export function notAuthorizedPagePath(): string {
    return '/not-authorized';
}

export function forbiddenPagePath(): string {
    return '/forbidden';
}

export function errorPagePath(): string {
    return '/error';
}

export function dashboardPagePath(): string {
    return '/dashboard';
}

export function changeIbanPath(encryptedContractNumber = ':encryptedContractNumber'): string {
    return `${dashboardPagePath()}/${encryptedContractNumber}/iban`;
}

export function faqPagePath(): string {
    return '/faq';
}

export function thirdPartyLicensesPagePath(): string {
    return '/third-party-licenses';
}

export function legalNoticePath(): string {
    return '/legal-notice';
}

export function landingPagePath(): string {
    return '/';
}

export function autoLogoutPath(): string {
    return '/auto-logout';
}

export function manualLogoutPath(): string {
    return '/logout';
}

export function introductionPagePath(): string {
    return '/introduction';
}

export function myProfilePagePath(): string {
    return '/my-profile';
}

export function changeContactDetailsPath(): string {
    return `${myProfilePagePath()}/change-contact-details`;
}

export function changeAddressDetailsPath(): string {
    return `${myProfilePagePath()}/change-address-details`;
}

export function changeVWFSMarketingPreferencesPath(): string {
    return `${myProfilePagePath()}/change-VWFS-marketing-preferences`;
}

export function changeBusinessPartnersMarketingPreferencesPath(): string {
    return `${myProfilePagePath()}/change-third-part-business-partners-marketing-preferences`;
}

export function changePaymentDayPath(encryptedContractNumber: string): string {
    return `${dashboardPagePath()}/${encryptedContractNumber}/change-payment-day`;
}

export function contractEndOptionsPath(encryptedContractNumber = ':encryptedContractNumber'): string {
    return `/contract-end-options/${encryptedContractNumber}`;
}

export function myRequestsPath(): string {
    return '/my-requests';
}

export function registrationEntry(): string {
    return '/registration-entry';
}

export function digitalRenewalPath(encryptedContractNumber = ':encryptedContractNumber'): string {
    return `/digital-renewal/${encryptedContractNumber}`;
}

export function HookPageRedirectionPath(): string {
    return '/hookpage';
}

export function contactDetailsHookPagePath(): string {
    return '/contact-details';
}

export function marketingPreferencesHookPagePath(): string {
    return '/marketing-preferences';
}

export function scriveSuccessPath(): string {
    return getScriveSuccessPath();
}

export function scriveRejectionPath(encryptedContractNumber = ':encryptedContractNumber'): string {
    return getScriveRejectionPath(encryptedContractNumber);
}
