import { InfoIcon } from '@cp-shared-6/frontend-ui';
import { ErrorMessage, Fieldset, FormField, Input, Layout } from '@vwfs-bronson/bronson-react';
import { useField } from 'formik';
import React from 'react';
import { CountryCodeSelection } from './country-code-selection/CountryCodeSelection';

export const PhoneInput: React.FC<{
    testId: string;
    inputName: string;
    selectName: string;
    tooltip?: string;
    label: string;
    disabled?: boolean;
}> = ({ testId, inputName, selectName, tooltip, children, label, disabled }) => {
    const [field, meta] = useField(inputName);
    const infoIcon = tooltip ? <InfoIcon text={tooltip} /> : null;

    return (
        <Fieldset.Row>
            <div className={'u-mb-xsmall c-form-field__label'}>
                {label}
                {infoIcon}
            </div>
            <Layout className={'o-layout--flush'}>
                <Layout.Item default={'1/3'}>
                    <CountryCodeSelection name={selectName} testId={testId} disabled={true} />
                </Layout.Item>
                <Layout.Item default={'2/3'}>
                    <FormField type="input">
                        <Input
                            reversed
                            error={!!(meta.touched && meta.error)}
                            success={meta.touched && !meta.error}
                            type={'tel'}
                            testId={`${testId}-input`}
                            inputMode={'tel'}
                            disabled={disabled}
                            {...field}
                        >
                            {children}
                        </Input>
                    </FormField>
                </Layout.Item>
                <ErrorMessage>{meta.touched && meta.error ? meta.error : ''}</ErrorMessage>
            </Layout>
        </Fieldset.Row>
    );
};
