import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const identificationFormValidationSchema = (t: TFunction) => {
    return Yup.object().shape({
        authorizationCode: Yup.string()
            .trim()
            .required(t('form.errors.authorization-code-missing'))
            .length(6, t('form.errors.invalid-authorization-code')),
    });
};
