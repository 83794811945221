import React from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { preventSubmit, ValidatedInput } from '@cp-shared-6/frontend-ui';
import { PersonalDetails } from '@cp-ie/common';
import { PhoneInput } from '../../../../../../../my-profile/contact-section/edit-view/phone-input/PhoneInput';
import { DigitalSignFormValues, getInitialValues } from './initialValues';
import { validationSchema } from './validationSchema';

type FormViewProps = {
    personalDetails?: PersonalDetails;
    onSubmit: (values: DigitalSignFormValues) => void;
    onCancelClick: () => void;
};

export const FormView: React.FC<FormViewProps> = ({ personalDetails, onSubmit, onCancelClick }) => {
    const { t } = useTranslation('financial-details');
    const translationPrefix = 'bank-account-section.iban.edit-view.digital-sign';
    const initialValues = getInitialValues(personalDetails);
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema(t)} onSubmit={onSubmit}>
            {({ submitForm }): JSX.Element => (
                <Form onSubmit={preventSubmit()}>
                    <Fieldset>
                        <Fieldset.Row>
                            <Layout>
                                <Layout.Item>
                                    <ValidatedInput
                                        name="bankAccountHolder"
                                        testId="bankAccountHolder"
                                        type="text"
                                        label={t(`${translationPrefix}.bank-account-holder.label`)}
                                    />
                                </Layout.Item>
                            </Layout>
                        </Fieldset.Row>
                        <Fieldset.Row>
                            <Layout>
                                <Layout.Item>
                                    <ValidatedInput
                                        name="email"
                                        testId="email"
                                        type="email"
                                        label={t(`${translationPrefix}.email.label`)}
                                    />
                                </Layout.Item>
                            </Layout>
                        </Fieldset.Row>
                    </Fieldset>
                    <Fieldset>
                        <Fieldset.Row>
                            <Layout>
                                <Layout.Item>
                                    <PhoneInput
                                        inputName={'mobilePhone.number'}
                                        selectName={'mobilePhone.countryCode'}
                                        testId={'mobile-phone'}
                                        label={t(`${translationPrefix}.mobile-phone.label`)}
                                        tooltip={t(`${translationPrefix}.mobile-phone.tooltip`)}
                                        disabled
                                    />
                                </Layout.Item>
                            </Layout>
                        </Fieldset.Row>
                    </Fieldset>
                    <Fieldset>
                        <Fieldset.Row>
                            <ButtonContainer center>
                                <Button secondary onClick={onCancelClick} testId="cancelButton" type="button">
                                    {t('translation:editable-section-nav.cancel')}
                                </Button>
                                <Button onClick={submitForm} testId="submitButton">
                                    {t('translation:editable-section-nav.submit')}
                                </Button>
                            </ButtonContainer>
                        </Fieldset.Row>
                    </Fieldset>
                </Form>
            )}
        </Formik>
    );
};
