import { DataOverview, useAnalyticsActionTracker, useAnalyticsPageViewTracker } from '@cp-shared-6/frontend-ui';
import { Button, ButtonContainer, Layout } from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DigitalSignForm } from './digital-sign-form';
import { DigitalSignLink } from './digital-sign-link';
import { DownloadLink } from './download-link/DownloadLink';

export type SepaDownloadViewProps = {
    sepaDownloadEndpoint: string;
    digitalSignEndpoint: string;
    iban: string;
    allContracts: boolean;
    toPreviousView: () => void;
    toNextView: () => void;
};

export const SepaDownloadView: React.FC<SepaDownloadViewProps> = ({
    sepaDownloadEndpoint,
    digitalSignEndpoint,
    iban,
    allContracts,
    toPreviousView,
    toNextView,
}) => {
    const { t } = useTranslation('financial-details');
    const [isDownloaded, setIsDownloaded] = useState<boolean | null>(null);
    const [hasClickedOnSubmit, setHasClickedOnSubmit] = useState<boolean>(false);
    const [showDigitalSignForm, setShowDigitalSignForm] = useState(false);
    const [showSepaDownloadLinks, setShowSepaDownloadLinks] = useState(true);

    useAnalyticsPageViewTracker('dashboardEditSepa');
    const { onAction: onSepaDigitalSign } = useAnalyticsActionTracker('onDashboardEditSepaSign');

    const onSubmitSepa = (): void => {
        setHasClickedOnSubmit(true);
        if (isDownloaded) {
            toNextView();
        }
    };

    const onDownloadSepaClick = () => {
        setShowDigitalSignForm(false);
    };

    const hideDownloadLinks = () => {
        setShowSepaDownloadLinks(false);
    };

    const onDigitalSignClick = () => {
        onSepaDigitalSign();
        setShowDigitalSignForm(true);
    };

    return (
        <DataOverview
            title={t('bank-account-section.iban.edit-view.sepa-download-view.headline')}
            withoutCardEffect={true}
        >
            <Layout>
                {showSepaDownloadLinks && (
                    <>
                        <Layout.Item>
                            {t('bank-account-section.iban.edit-view.sepa-download-view.help-text')}
                        </Layout.Item>
                        <Layout.Item default="1/3" s="1/1">
                            <DownloadLink
                                showError={hasClickedOnSubmit && !isDownloaded}
                                setIsDownloaded={setIsDownloaded}
                                sepaDownloadEndpoint={sepaDownloadEndpoint}
                                iban={iban}
                                allContracts={allContracts}
                                onClick={onDownloadSepaClick}
                            />
                        </Layout.Item>
                        <Layout.Item default="1/3" s="1/1">
                            <DigitalSignLink onClick={onDigitalSignClick} />
                        </Layout.Item>
                    </>
                )}
                <Layout.Item default="1/1">
                    {showDigitalSignForm ? (
                        <DigitalSignForm
                            digitalSignEndpoint={digitalSignEndpoint}
                            iban={iban}
                            onCancelClick={toPreviousView}
                            onSubmit={hideDownloadLinks}
                            allContracts={allContracts}
                        />
                    ) : (
                        <ButtonContainer center>
                            <Button secondary onClick={toPreviousView} testId="cancelButton" type="button">
                                {t('translation:editable-section-nav.back')}
                            </Button>
                            <Button onClick={onSubmitSepa} testId="submitButton" disabled={!isDownloaded}>
                                {t('translation:editable-section-nav.continue')}
                            </Button>
                        </ButtonContainer>
                    )}
                </Layout.Item>
            </Layout>
        </DataOverview>
    );
};
