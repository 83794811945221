import React, { useState } from 'react';
import { useAnalyticsActionTracker, useAnalyticsFormTracker } from '@cp-shared-6/frontend-ui';
import { FinancialDetails } from '@cp-ie/common';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { changeIbanPath } from '../../../navigation/paths';
import { EditView } from './edit-view/EditView';
import { ConsultView } from './consult-view/ConsultView';
import { EditStatus } from './edit-view/EditStatus';
import { showDigitalSignNotification } from './utils';

export type BankAccountSectionProps = {
    details: FinancialDetails;
    encryptedContractNumber: string;
    onChangeIban: () => void;
};

export const BankAccountSection: React.FC<BankAccountSectionProps> = ({
    details,
    encryptedContractNumber,
    onChangeIban,
}) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const [lastEditStatus, setLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);

    const { onAction: onEdit } = useAnalyticsActionTracker('financialDetailsEditIbanSection');
    const { onAction: onCancel } = useAnalyticsActionTracker('financialDetailsEditIbanSectionCancel');
    const { onError, onSuccess } = useAnalyticsFormTracker({
        confirmError: 'financialDetailsEditIbanSectionSubmitFailed',
        confirmSuccess: 'financialDetailsEditIbanSectionSuccess',
    });

    const startEditing = (): void => {
        onEdit();
        history.push(changeIbanPath(encryptedContractNumber));
    };

    const cancelEditing = (): void => {
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        onCancel();
        history.push(path);
    };

    const finishEditing = (newEditStatus: EditStatus): void => {
        newEditStatus === EditStatus.SUCCESS ? onSuccess() : onError();
        setLastEditStatus(newEditStatus);
        onChangeIban();
        history.push(path);
    };

    return (
        <Switch>
            <Route exact path={changeIbanPath(encryptedContractNumber)}>
                <EditView details={details} cancelEditing={cancelEditing} finishEditing={finishEditing} />
            </Route>
            <Route path={path}>
                <ConsultView
                    details={details}
                    startEditing={startEditing}
                    lastEditStatus={lastEditStatus}
                    showDigitalSignNotification={showDigitalSignNotification(encryptedContractNumber)}
                />
            </Route>
        </Switch>
    );
};
