import { FinancialDetails } from '@cp-ie/common';
import { createGetContractBasedDataFetchSlice } from '@cp-shared-6/frontend-integration';
import { CpDataApi } from 'cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-6/common-utilities';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<
    FinancialDetails,
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'financialDetails',
    fetchCallback(link: string) {
        return CpDataApi.get(link).then(response => response.data);
    },
});

export default reducer;
export const fetchFinancialDetails = fetchData;
