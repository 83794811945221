export type MarketingPreferences = {
    vwfs: MarketingPreference;
    businessPartners: MarketingPreference;
};

export type MarketingPreference = {
    email?: boolean;
    phone?: boolean;
    sms?: boolean;
    post?: boolean;
    emailAddress?: string;
    confirmPrivacyPolicy?: boolean;
};

export enum MarketingPreferenceType {
    VWFS = 'VWFS',
    BUSINESS_PARTNERS = 'BUSINESS_PARTNERS',
}

export type MarketingPreferenceError = 'BAD_TYPE';
