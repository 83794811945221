import React from 'react';
import { RequestLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-6/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useRequest } from './useRequest';
import { FormView } from './form-view/FormView';
import { useMyProfile } from '../my-profile';

const RequestWithHandlers = withLoadingAndNoConnectionHandler(FormView);

export const Request: React.FC = () => {
    const { data: contracts, isLoading: isLoadingContracts, loadingError } = useRequest();
    const { data: personalDetails, isLoading: isLoadingPersonalDetails } = useMyProfile();

    useAnalyticsPageViewTracker('requests', !!contracts);

    return (
        <RequestWithHandlers
            isLoading={isLoadingContracts || isLoadingPersonalDetails}
            contracts={contracts}
            personalDetails={personalDetails}
            hasError={!!loadingError}
            loadingPlacehloder={<RequestLoadingPlaceholder numberOfButtons={2} />}
        />
    );
};
