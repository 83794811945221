import { useAnalyticsHookPageViewTracker } from '@cp-shared-6/frontend-ui';
import { DigitalRenewal } from 'components/hook-page/digital-renewal-hookpage/DigitalRenewal';
import { HookPageRedirectionPath, loginAnalyticsPath } from 'components/navigation/paths';
import React from 'react';
import { useParams } from 'react-router';
import { useLastLocation } from 'react-router-last-location';

export const DigitalRenewalPage: React.FC = () => {
    const { encryptedContractNumber } = useParams<{ encryptedContractNumber: string }>();

    const lastLocation = useLastLocation();
    const lastPathname =
        lastLocation?.pathname === HookPageRedirectionPath() ? loginAnalyticsPath() : lastLocation?.pathname;

    useAnalyticsHookPageViewTracker('digitalRenewalPage', true, lastPathname || '');

    return <DigitalRenewal encryptedContractNumber={encryptedContractNumber} />;
};
