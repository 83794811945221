import { MarketingPreferences } from '@cp-ie/common';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonUserDetailsComponent } from '../../CommonUserDetailsComponent';
import { MarketingPreferencesEditView } from '../EditView/EditView';

type MarketingPreferencesUiProps = {
    marketingPreferences?: MarketingPreferences;
    isContactEmailDefined: boolean;
};

export const MarketingPreferencesUi: React.FC<MarketingPreferencesUiProps> = ({
    marketingPreferences,
    isContactEmailDefined,
}) => {
    const { t } = useTranslation('marketing-preferences-hookpage');
    const [isLoading, setIsLoading] = useState(false);

    return (
        <CommonUserDetailsComponent title={t('title')} subsubtitle={t('subsubtitle')} isLoadingSpinner={isLoading}>
            <MarketingPreferencesEditView
                setIsLoading={setIsLoading}
                marketingPreference={marketingPreferences?.vwfs}
                isContactEmailDefined={isContactEmailDefined}
            />
        </CommonUserDetailsComponent>
    );
};
