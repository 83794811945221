import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { ContractSelection } from './initialValues';

export const validationSchema = (t: TFunction) => {
    const requiredFieldError = t('category-selection.required-field-error');
    const maxLengthError = t('category-selection.max-length-error');
    const invalidEmailError = t('category-selection.refund-query.email.validation.invalid');
    return Yup.object().shape({
        contractSelection: Yup.object().test(
            'isContractSelected',
            requiredFieldError,
            (value: ContractSelection): boolean => {
                return !!value.contractNumber;
            },
        ),
        categorySelection: Yup.string().required(requiredFieldError),
        balanceEnquiryForm: Yup.object().when('categorySelection', {
            is: 'BALANCE_ENQUIRY_FORM',
            then: Yup.object().shape({
                sourceOfSettlement: Yup.string().required(requiredFieldError),
                formOfSettlement: Yup.string().required(requiredFieldError),
                reasonForSettlement: Yup.string().required(requiredFieldError),
                otherSourceOfSettlement: Yup.string().when('sourceOfSettlement', {
                    is: 'Other',
                    then: Yup.string().required(requiredFieldError),
                }),
                otherFormOfSettlement: Yup.string().when('formOfSettlement', {
                    is: 'Other',
                    then: Yup.string().required(requiredFieldError),
                }),
                otherReasonForSettlement: Yup.string().when('reasonForSettlement', {
                    is: 'Other',
                    then: Yup.string().required(requiredFieldError),
                }),
            }),
        }),
        refundQuery: Yup.object().when('categorySelection', {
            is: 'REFUND_QUERY',
            then: Yup.object().shape({
                refundQuery: Yup.string()
                    .trim()
                    .required(requiredFieldError)
                    .max(1000, maxLengthError),
                customerEmail: Yup.string()
                    .trim()
                    .email(invalidEmailError),
            }),
        }),
        pcpEndOfContractOption: Yup.object().when('categorySelection', {
            is: 'PCP_END_OF_CONTRACT_OPTION',
            then: Yup.object().shape({
                callbackRegarding: Yup.string().required(requiredFieldError),
                otherQuery: Yup.string().when('callbackRegarding', {
                    is: 'Other (please specify below)',
                    then: Yup.string()
                        .trim()
                        .required(requiredFieldError)
                        .max(1000, maxLengthError),
                }),
            }),
        }),
    });
};
