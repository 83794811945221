import React from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatedSelect, ValidatedSelectItem } from '@cp-shared-6/frontend-ui';
import { ReasonOther } from '../reason-selection/reason-other/ReasonOther';
import { useField } from 'formik';

export const SourceSelection: React.FC = () => {
    const { t } = useTranslation('request');

    const translationPrefix = 'category-selection.balance-enquiry-form';
    const fieldName = 'balanceEnquiryForm.sourceOfSettlement';
    const [field] = useField(fieldName);
    const keyValueMap = new Map([
        ['bonus', 'Bonus'],
        ['business-income', 'Business Income'],
        ['dividend', 'Dividend'],
        ['gift', 'Gift'],
        ['inheritance', 'Inheritance'],
        ['insurance-claim', 'Insurance Claim'],
        ['interest', 'Interest'],
        ['redundancy', 'Redundancy'],
        ['retirement', 'Retirement'],
        ['salary', 'Salary'],
        ['sale-of-asset', 'Sale of Asset'],
        ['savings', 'Savings'],
        ['other', 'Other'],
    ]);

    const selectItems = Array.from(keyValueMap).map(([key, value]: [string, string]) => {
        return { value, label: t(`${translationPrefix}.source-selection.options.${key}`) };
    }) as ValidatedSelectItem[];

    const mandatoryLabel = `${t(`${translationPrefix}.source-selection.label`)} *`;

    const isOtherSelected = (): boolean => {
        return field.value === keyValueMap.get('other');
    };

    return (
        <>
            <ValidatedSelect
                label={mandatoryLabel}
                name={'balanceEnquiryForm.sourceOfSettlement'}
                id={'source-selection'}
                testId={'source-selection'}
                selectItems={selectItems}
                placeholder={t(`${translationPrefix}.source-selection.placeholder`)}
                disablePlaceholder={true}
            />
            {isOtherSelected() && (
                <ReasonOther
                    label={t(`${translationPrefix}.reason-other.source-label`)}
                    name="balanceEnquiryForm.otherSourceOfSettlement"
                />
            )}
        </>
    );
};
