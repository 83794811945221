import React from 'react';
import { useTranslation } from 'react-i18next';
import { DefinitionListItem, withAutoScroll } from '@cp-shared-6/frontend-ui';
import { ConfirmationView } from '../../../../confirmation-view';
import { AddressWithFilesFormSchema } from '../../edit-view/edit-view.types';
import { counties as defaultCounties } from '@cp-ie/common';

type SummaryViewProps = {
    newData: AddressWithFilesFormSchema;
    onConfirm: () => void;
    onClose: () => void;
};

export const SummaryView: React.FC<SummaryViewProps> = withAutoScroll(({ newData, onConfirm, onClose }) => {
    const { t } = useTranslation('my-profile-address-details');
    const getCountyNameByCode = (code?: string): string | undefined =>
        defaultCounties.find(({ countyCode }) => String(countyCode) === code)?.label;

    const getAddressList = (): DefinitionListItem[] => {
        const noValuePlaceholder = '-';
        const translationPrefixSummary = 'edit-view.summary';

        return [
            {
                label: t(`${translationPrefixSummary}.first-address-line.label`),
                value: newData.address?.addressLine1 ?? noValuePlaceholder,
                testId: 'address-addressLine1',
            },
            {
                label: t(`${translationPrefixSummary}.second-address-line.label`),
                value: newData.address?.addressLine2 ?? noValuePlaceholder,
                testId: 'address-addressLine2',
            },
            {
                label: t(`${translationPrefixSummary}.town-or-city.label`),
                value: newData.townOrCity ?? noValuePlaceholder,
                testId: 'address-townOrCity',
            },
            {
                label: t(`${translationPrefixSummary}.county.label`),
                value: getCountyNameByCode(newData.countyCode) ?? noValuePlaceholder,
                testId: 'postal-address-county',
            },
            {
                label: t(`${translationPrefixSummary}.postcode.label`),
                value: newData.postcode ?? noValuePlaceholder,
                testId: 'postal-address-postcode',
            },
        ];
    };

    return (
        <ConfirmationView
            items={getAddressList()}
            onConfirm={onConfirm}
            onCancel={onClose}
            subHeadline={t('edit-view.confirmation-modal.headline')}
        />
    );
}, 'ChangeOfNameSummaryView');
