import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatedInput, ValidatedSelect } from '@cp-shared-6/frontend-ui';
import { ContentSection, Fieldset, Layout, Input } from '@vwfs-bronson/bronson-react';
import {
    counties as defaultCounties,
    AddressValidationAutocompleteHEREItem as hereItem,
    AddressWithFiles,
} from '@cp-ie/common';
import { getAddressValidationAutocompleteEndpoint } from '@cp-shared-6/apis';
import { CpDataApi } from '../../../../../cp-xhr';
import { FormikProps, useFormikContext } from 'formik';

export const AddressList: React.FC<{}> = () => {
    const { t } = useTranslation('my-profile-address-details');
    const formik: FormikProps<AddressWithFiles> = useFormikContext();

    const counties = defaultCounties.map(({ label, countyCode }) => ({ value: String(countyCode), label }));
    const initialAddressList: hereItem[] = [];
    const [addressList, setAddressList] = useState(initialAddressList);

    const getCountyCodeByName = (countyName?: string): string => {
        const county = defaultCounties.find(({ label }) => label.toUpperCase() === countyName?.toUpperCase());
        return !!county ? String(county.countyCode) : '';
    };
    const setAutocompleteInputFields = (addressItem: hereItem): void => {
        let addressLine1 = '';
        const addressLine1Array = [];
        const clearedCounty = addressItem.address.county?.replace('County', '').replace(/\s/g, '');
        const countyCode = getCountyCodeByName(clearedCounty);
        const splittedLabel = addressItem.address.label?.split(',');
        if (splittedLabel) {
            for (let i = 0; i < splittedLabel.length; i++) {
                if (splittedLabel[i].trim() === addressItem.address.city?.trim()) {
                    break;
                }
                addressLine1Array.push(splittedLabel[i]);
            }
            addressLine1 = addressLine1Array.toString();
        }

        formik.setFieldValue('address.addressLine1', addressLine1);
        formik.setFieldValue('address.addressLine2', '');
        formik.setFieldValue('townOrCity', addressItem.address.city);
        formik.setFieldValue('countyCode', countyCode);
        formik.setFieldValue('postcode', addressItem.address.zipCode);
    };
    const handleAddressAutocompleteInputChange = (query: string): boolean => {
        let selected = false;
        for (let i = 0; i < addressList.length; i++) {
            if (addressList[i].address.label === query) {
                setAutocompleteInputFields(addressList[i]);
                selected = true;
                break;
            }
        }
        if (!selected) {
            CpDataApi.get(getAddressValidationAutocompleteEndpoint(), {
                params: { countryCode: 'IE', query: query },
            })
                .then(({ data }) => {
                    if (data.items && data.items.length > 0) {
                        setAddressList(data.items);
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch(() => {
                    return false;
                });
        }
        return false;
    };

    return (
        <ContentSection>
            <Fieldset>
                <Fieldset.Row>
                    <Layout>
                        <Layout.Item default="1/2">
                            <label className="c-form-field__label" htmlFor="address-autocomplete-input">
                                {t('edit-view.form-view.search-address-line')}
                            </label>
                            <Input
                                name="address.addressAutocomplete"
                                id={'address-autocomplete-input'}
                                testId="address-autocomplete-input"
                                type="search"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                    handleAddressAutocompleteInputChange(event.target.value);
                                }}
                                list="suggested-addresses"
                                autoComplete="off"
                            />
                            <datalist id="suggested-addresses">
                                {addressList.map((item, index) => (
                                    <option key={index} value={item.address.label}>
                                        {item.address.label}
                                    </option>
                                ))}
                            </datalist>
                        </Layout.Item>
                    </Layout>
                </Fieldset.Row>
                <Fieldset.Row>
                    <Layout>
                        <Layout.Item default="1/2">
                            <ValidatedInput
                                label={t('edit-view.form-view.first-address-line')}
                                name="address.addressLine1"
                                id={'address-line1-input'}
                                testId="first-address-input"
                                type="text"
                            />
                        </Layout.Item>
                        <Layout.Item default="1/2">
                            <ValidatedInput
                                label={t('edit-view.form-view.second-address-line')}
                                name="address.addressLine2"
                                id={'address-line2-input'}
                                testId="second-address-input"
                                type="text"
                            />
                        </Layout.Item>
                        <Layout.Item default="1/2">
                            <ValidatedInput
                                label={t('edit-view.form-view.town-or-city')}
                                name="townOrCity"
                                id={'town-or-city-input'}
                                testId="town-or-city-input"
                                type="text"
                            />
                        </Layout.Item>
                        <Layout.Item default="1/2">
                            <ValidatedSelect
                                label={t('edit-view.form-view.county')}
                                name="countyCode"
                                id={'countySelection'}
                                testId="county-selection"
                                selectItems={counties}
                                emptyByDefault
                            />
                        </Layout.Item>
                        <Layout.Item default="1/2">
                            <ValidatedInput
                                label={t('edit-view.form-view.postcode')}
                                name="postcode"
                                id={'postcode-input'}
                                testId="postcode-input"
                                type="text"
                            />
                        </Layout.Item>
                    </Layout>
                </Fieldset.Row>
            </Fieldset>
        </ContentSection>
    );
};
