import { Brand } from '@cp-ie/common';
import {
    Notification,
    Skeleton,
    useAnalyticsActionTracker,
    useAnalyticsDashboardPageTrackerWithoutUnpaid,
} from '@cp-shared-6/frontend-ui';
import { Button } from '@vwfs-bronson/bronson-react';
import { useGreeting } from 'components/greeting';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    digitalRenewalAudiLink,
    digitalRenewalSeatLink,
    digitalRenewalSkodaLink,
    digitalRenewalVwLink,
    digitalRenewalCupraLink,
} from '../../../config';

const prefix = 'notifications.digital-renewal-notification';

export const redirectToDigitalRenewal = (brand?: Brand) => {
    let currentLink = '';
    switch (brand) {
        case Brand.VW:
            currentLink = digitalRenewalVwLink || '';
            break;
        case Brand.AUDI:
            currentLink = digitalRenewalAudiLink || '';
            break;
        case Brand.SEAT:
            currentLink = digitalRenewalSeatLink || '';
            break;
        case Brand.SKODA:
            currentLink = digitalRenewalSkodaLink || '';
            break;
        case Brand.CUPRA:
            currentLink = digitalRenewalCupraLink || '';
            break;
        default:
            currentLink = '';
    }
    return window.open(currentLink, '_blank');
};

export const DigitalRenewalNotification: React.FC<{ brand?: Brand }> = ({ brand }) => {
    const { t } = useTranslation('contracts');
    const { data: greeting, isLoading } = useGreeting();
    const { onAction: onDigitalRenewalStart } = useAnalyticsActionTracker('onDigitalRenewalStart');

    useAnalyticsDashboardPageTrackerWithoutUnpaid(true, '', true, undefined);

    const onSecondaryClick = (brand?: Brand): void => {
        redirectToDigitalRenewal(brand);
        onDigitalRenewalStart('Dashboard');
    };

    return isLoading ? (
        <Skeleton height={200} className={'u-mb'} />
    ) : (
        <Notification
            headline={t(`${prefix}.headline`, { firstName: greeting?.firstName })}
            text={t(`${prefix}.description`)}
            testId={'DRtestId'}
            className={'u-mb'}
        >
            <Button secondary className="u-mt" testId={'button'} onClick={() => onSecondaryClick(brand)}>
                {t(`${prefix}.button`)}
            </Button>
        </Notification>
    );
};
