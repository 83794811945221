import React from 'react';
import { ErrorNotification } from '../../../../notifications/error/ErrorNotification';
import { Button } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { DataOverview } from '@cp-shared-6/frontend-ui';

export type NoConnectionViewProps = {
    cancelEditing: () => void;
};

export const NoConnectionView: React.FC<NoConnectionViewProps> = ({ cancelEditing }) => {
    const { t } = useTranslation('my-profile-marketing-preferences');

    return (
        <DataOverview title={t('edit-view.title')}>
            <ErrorNotification testId={'no-connection'} className={`u-mb`} />
            <Button secondary onClick={cancelEditing} testId="cancelButton" type="button">
                {t('translation:editable-section-nav.back')}
            </Button>
        </DataOverview>
    );
};
