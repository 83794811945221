import { configureStore, createSlice } from '@reduxjs/toolkit';
import { ContractWithAllValues } from '../../ExampleData';
import { FinancialDetailsWithAllValues } from '../../financial-details/ExampleData';
import { ContractDataWithLinks } from '@cp-ie/common';

export const mockStore = (allIbansEqual: boolean) => {
    const defaultIban = 'IE29ICON93115212345678';
    const differentIban = 'IE29AIBK93115212345678';
    return configureStore({
        reducer: {
            contracts: createSlice({
                name: 'contracts',
                initialState: [
                    { ...ContractWithAllValues, contractNumber: 'A123' },
                    { ...ContractWithAllValues, contractNumber: 'B321' },
                    { ...ContractWithAllValues, contractNumber: 'C999', isActive: false },
                ],
                reducers: {},
            }).reducer,
            financialDetails: createSlice({
                name: 'financialDetails',
                initialState: {
                    A123: { data: { ...FinancialDetailsWithAllValues, iban: defaultIban } },
                    B321: {
                        data: { ...FinancialDetailsWithAllValues, iban: allIbansEqual ? defaultIban : differentIban },
                    },
                    C999: {
                        data: { ...FinancialDetailsWithAllValues, isActive: false },
                    },
                },
                reducers: {},
            }).reducer,
        },
    });
};

export const shouldExpandContract = (pathname: string, contract?: ContractDataWithLinks): boolean =>
    !!contract?.encryptedContractNumber && pathname.includes(contract.encryptedContractNumber);
