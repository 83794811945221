import React from 'react';
import { FaqLoadingPlaceholder, useAnalyticsPageViewTracker, useAuthentication } from '@cp-shared-6/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { FaqUi } from './ui';
import { useFaq } from './useFaq';

const FaqWithHandlers = withLoadingAndNoConnectionHandler(FaqUi);

export const Faq: React.FC = () => {
    const { cmsContent: faq, isLoading, loadingError } = useFaq();
    const { isAuthenticated } = useAuthentication();

    useAnalyticsPageViewTracker('faq', !!faq);

    return (
        <FaqWithHandlers
            isLoading={isLoading}
            faq={faq}
            hasError={!!loadingError}
            isLoggedIn={isAuthenticated}
            loadingPlacehloder={<FaqLoadingPlaceholder />}
        />
    );
};
