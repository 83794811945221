export function getAccountVerificationRequestEndpoint(): string {
    return '/registration/account-verification-request';
}

export function getAccountVerificationConfirmationEndpoint(): string {
    return '/registration/account-verification-confirmation';
}

export function getContractNumberVerificationEndpoint(): string {
    return '/registration/contract-number-verification';
}
