import { Button, ButtonContainer, Modal } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type ConfirmationModalProps = {
    showModal: boolean;
    title: string;
    text: string;
    testId?: string;
    onSecondaryClick: () => void;
    onSubmitClick: () => void;
    onModalClose?: () => void;
};

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    showModal,
    title,
    text,
    onSecondaryClick,
    onSubmitClick,
    onModalClose,
    testId,
}) => {
    const { t } = useTranslation('contact-details-hookpage');
    return (
        <Modal shown={showModal} title={title} onClose={onModalClose} testId={testId}>
            {text}
            <ButtonContainer center noWrap className={'u-mt'}>
                <Button secondary testId="backButtonModal" onClick={onSecondaryClick}>
                    {t('button.secondary')}
                </Button>
                <Button onClick={onSubmitClick} testId="submit-button-modal" type="submit">
                    {t('button.primary')}
                </Button>
            </ButtonContainer>
        </Modal>
    );
};
