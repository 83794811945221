export type ContractSelection = {
    isActive: boolean;
    brand: string;
    contractNumber: string;
    registrationNumber: string;
};

export type BalanceEnquiryForm = {
    sourceOfSettlement: string;
    formOfSettlement: string;
    reasonForSettlement: string;
    otherSourceOfSettlement: string;
    otherFormOfSettlement: string;
    otherReasonForSettlement: string;
};

export type RefundQuery = { refundQuery: string; customerEmail: string };

export type PcpEndOfContractOption = {
    callbackRegarding: string;
    otherQuery: string;
};

export type RequestInitialValues = {
    contractSelection: ContractSelection;
    categorySelection: string;
    balanceEnquiryForm: BalanceEnquiryForm;
    refundQuery: RefundQuery;
    pcpEndOfContractOption: PcpEndOfContractOption;
    dataProcessionCheckbox: boolean;
};

const contractSelectionInitialValues: ContractSelection = {
    isActive: true,
    brand: '',
    contractNumber: '',
    registrationNumber: '',
};

const balanceEnquiryFormInitialValues: BalanceEnquiryForm = {
    sourceOfSettlement: '',
    formOfSettlement: '',
    reasonForSettlement: '',
    otherSourceOfSettlement: '',
    otherFormOfSettlement: '',
    otherReasonForSettlement: '',
};

const pcpEndOfContractOptionInitialValues: PcpEndOfContractOption = { callbackRegarding: '', otherQuery: '' };

export const getInitialValues = (customerEmail = ''): RequestInitialValues => ({
    contractSelection: contractSelectionInitialValues,
    categorySelection: '',
    balanceEnquiryForm: balanceEnquiryFormInitialValues,
    refundQuery: { refundQuery: '', customerEmail },
    pcpEndOfContractOption: pcpEndOfContractOptionInitialValues,
    dataProcessionCheckbox: false,
});
