import React, { useCallback } from 'react';
import { Combobox, FormField, Layout } from '@vwfs-bronson/bronson-react';
import { useField } from 'formik';
import { InfoIcon, useAnalyticsActionTracker } from '@cp-shared-6/frontend-ui';
import { useTranslation } from 'react-i18next';
import { ComboboxOption } from '../../../../../../types/bronson-react';

export const MonthlyPaymentDaySelection: React.FC<{ name: string; testId: string }> = ({ name, testId }) => {
    const { t } = useTranslation('financial-details');
    const translationPrefix = 'payment-day-section.edit-view.monthly-payment-day';
    const [field, , helpers] = useField(name);

    const { onAction: onTyping } = useAnalyticsActionTracker('financialDetailsEditDueDateSectionStartTyping');

    const setValueChange = useCallback((selectedPaymentDay: ComboboxOption[]): void => {
        onTyping();
        return helpers.setValue(selectedPaymentDay?.[0]?.value);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const options = new Array(30).fill(null).map((_, index: number) => {
        const day = index + 1;
        return (
            <Combobox.Item
                testId={`payment-day-selection-item-${day}`}
                optionValue={`${day}`}
                optionKey={`${day}`}
                key={`${day}`}
            >
                <span>
                    <b>{day}</b>
                </span>
            </Combobox.Item>
        );
    });

    const defaultState = {
        value: [
            {
                value: field.value,
                key: field.value,
            },
        ],
    };

    return (
        <Layout className={'o-layout--flush'}>
            <Layout.Item>
                <div className={'u-mb-xsmall'}>
                    {t(`${translationPrefix}.label`)}
                    <InfoIcon text={t(`${translationPrefix}.tooltip`)} />
                </div>
            </Layout.Item>
            <Layout.Item default={'1/3'}>
                <FormField type="select" id={'payment-day-selection'}>
                    <Combobox
                        testId={testId}
                        icon="semantic-expand"
                        // defaultOptionKey={field.value}
                        defaultState={defaultState}
                        onChange={setValueChange}
                    >
                        {options}
                    </Combobox>
                </FormField>
            </Layout.Item>
        </Layout>
    );
};
