import { LandingPageTeaser } from '@cp-ie/common';
import { HeroImage } from '@cp-shared-6/frontend-ui';
import React from 'react';

export type LandingPageTeaserUiProps = {
    landingPageTeaser?: LandingPageTeaser;
    clickHandler?: Function;
};

export const LandingPageTeaserUi: React.FC<LandingPageTeaserUiProps> = ({ landingPageTeaser, clickHandler }) => {
    if (!landingPageTeaser || !clickHandler) {
        return null;
    }
    return (
        <HeroImage
            pretitle={landingPageTeaser?.pretitle}
            title={landingPageTeaser?.title || ''}
            imageUrl={landingPageTeaser?.imageUrl || ''}
            subTitle={landingPageTeaser?.subTitle}
            text={landingPageTeaser?.text}
            buttonText={landingPageTeaser?.buttonText}
            clickHandler={clickHandler}
            inverted={!!landingPageTeaser?.isInverted}
        />
    );
};
