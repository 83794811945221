import React from 'react';
import { HeroImage } from '@cp-shared-6/frontend-ui';
import { IntroductionPageTeaser, IconProps } from '@cp-ie/common';
import { Button, ContentSection, ItemTeaser, Layout, Video } from '@vwfs-bronson/bronson-react';
import { useHistory } from 'react-router';
import { dashboardPagePath } from '../../navigation/paths';

export type IntroductionPageTeaserUiProps = {
    introductionPageTeaser?: IntroductionPageTeaser;
    firstName?: string;
};

const IconsList: React.FC<{ iconList: IconProps[] }> = ({ iconList }) => {
    return (
        <Layout>
            {iconList.map(item => (
                <Layout.Item key={item.label} default={'1/2'} m={'1/1'}>
                    <ItemTeaser icon={item.icon} inline>
                        {item.label}
                    </ItemTeaser>
                </Layout.Item>
            ))}
        </Layout>
    );
};

export const IntroductionPageTeaserUi: React.FC<IntroductionPageTeaserUiProps> = ({
    introductionPageTeaser,
    firstName = '',
}) => {
    const history = useHistory();
    if (!introductionPageTeaser) {
        return null;
    }

    const { hero, benefits, video, beginJourney } = introductionPageTeaser;

    return (
        <>
            <HeroImage
                pretitle={hero?.pretitle}
                title={hero?.title.replace('{{firstName}}', firstName) || ''}
                imageUrl={hero?.imageUrl || ''}
                subTitle={hero?.subTitle}
                text={hero?.text}
                inverted={!!hero?.isInverted}
                shallow={true}
            />
            <ContentSection pageWrapSize="medium" className={'u-text-center'}>
                <div className={'u-mt-large'}>
                    <h2>{benefits?.title}</h2>
                    <p>{benefits?.subTitle}</p>
                    <IconsList iconList={benefits?.iconList || []} />
                </div>
                <div className={'u-mt-large'}>
                    <h2>{video?.title}</h2>
                    <p>{video?.subTitle}</p>
                    <Video service={video?.service} id={video?.id} />
                </div>
                <div className={'u-mt-large'}>
                    <h2>{beginJourney?.title}</h2>
                    <Button
                        onClick={() => {
                            history.push(dashboardPagePath());
                        }}
                        testId="begin-journey"
                    >
                        {beginJourney?.button}
                    </Button>
                </div>
            </ContentSection>
        </>
    );
};
