import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const contractNumberFormValidationSchema = (t: TFunction) => {
    return Yup.object().shape({
        contractNumber: Yup.number()
            .required(t('form.validation.required'))
            .typeError(t('form.validation.invalid-format')),
    });
};
