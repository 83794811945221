import React from 'react';
import { Textarea as TextareaShared, ErrorMessage, FormField } from '@vwfs-bronson/bronson-react';
import { useField } from 'formik';

export type TextareaProps = {
    label?: string;
    name: string;
    placeholder?: string;
    testId: string;
};

export const Textarea: React.FC<TextareaProps> = ({ label, name, placeholder, testId }) => {
    const [field, meta] = useField(name);

    return (
        <FormField
            type="textarea"
            testId={testId}
            labelText={label}
            errorMessage={
                <ErrorMessage testId={'textarea-error-message'}>
                    {meta.touched && meta.error ? meta.error : ''}
                </ErrorMessage>
            }
        >
            <TextareaShared placeholder={placeholder} {...field} />
        </FormField>
    );
};
