import { LogoutSuccess, getLogoutSuccessEndpoint } from '@cp-ie/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<LogoutSuccess>({
    contentName: 'logoutSuccess',
    contentEndpoint: getLogoutSuccessEndpoint,
});

export default reducer;
export const fetchLogoutSuccess = fetchContent;
