import React from 'react';
import { Textarea } from 'components/request/form-view/textarea/Textarea';

export const ReasonOther: React.FC<{ label: string; name: string }> = ({ label, name }) => {
    return (
        <>
            <br />
            <Textarea label={label} name={name} testId={'other-settlement-reason'} />
        </>
    );
};
