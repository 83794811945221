import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { DATE_FORMAT } from '@cp-ie/common';
import { formatCpDate } from '@cp-shared-6/common-utilities';

export const identificationFormValidationSchema = (t: TFunction) => {
    return Yup.object().shape({
        name: Yup.string()
            .trim()
            .required(t('form.errors.name-missing')),
        surname: Yup.string()
            .trim()
            .required(t('form.errors.surname-missing')),
        birthDate: Yup.string()
            .required(t('form.errors.birth-date.missing'))
            .test('format', t('form.errors.birth-date.format'), date => formatCpDate(date, DATE_FORMAT).isValid())
            .test('no future date', t('form.errors.birth-date.invalid'), date =>
                formatCpDate(date, DATE_FORMAT)
                    .toMoment()
                    .isSameOrBefore(formatCpDate().toMoment()),
            )
            .test('too old', t('form.errors.birth-date.invalid'), date =>
                formatCpDate(date, DATE_FORMAT)
                    .toMoment()
                    .isSameOrAfter(
                        formatCpDate()
                            .toMoment()
                            .subtract(100, 'years'),
                    ),
            )
            .test('underage', t('form.errors.birth-date.underage'), date =>
                formatCpDate(date, DATE_FORMAT)
                    .toMoment()
                    .isSameOrBefore(
                        formatCpDate()
                            .toMoment()
                            .subtract(18, 'years'),
                    ),
            ),
        confirmPrivacyPolicy: Yup.bool().oneOf([true], t('form.errors.privacy-policy')),
        confirmLegalNotice: Yup.bool().oneOf([true], t('form.errors.legal-notice')),
    });
};
