import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-6/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-6/common-utilities';

import { getMarketingPreferencesEndpoint } from '@cp-ie/common';

import {
    MarketingPreferencesWithAllValuesFalse,
    MarketingPreferencesWithAllValuesTrue,
    MarketingPreferencesWithMissingValues,
} from './ExampleData';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: MarketingPreferencesWithAllValuesTrue,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
    'With All False Values': {
        status: 200,
        responseBody: MarketingPreferencesWithAllValuesFalse,
    },
    'With Missing Values': {
        status: 200,
        responseBody: MarketingPreferencesWithMissingValues,
    },
};

export const MarketingPreferencesMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Marketing Preferences Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getMarketingPreferencesEndpoint(),
};

export const MarketingPreferencesResponseMockWrapper = (storyFn: () => React.ReactNode): JSX.Element => {
    setupMockResponses(CpDataApi, [MarketingPreferencesMock]);

    return <div>{storyFn()}</div>;
};
