import { MarketingPreference, MarketingPreferenceType } from '@cp-ie/common';
import { useAnalyticsActionTracker } from '@cp-shared-6/frontend-ui';
import React, { useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import { useScrollToView } from '../../../../utils/use-scroll-to';
import { changeBusinessPartnersMarketingPreferencesPath } from '../../../navigation/paths';
import { CommonConsultView } from '../common/consult-view';
import { CommonEditView } from '../common/edit-view/CommonEditView';
import { EditStatus } from '../common/EditStatus';

export const BusinessPartnersSection: React.FC<{
    marketingPreference: MarketingPreference;
    isContactEmailDefined: boolean;
}> = ({ marketingPreference, isContactEmailDefined }) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const [lastEditStatus, setLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);
    const { scrollToRef, setCurrentView } = useScrollToView(changeBusinessPartnersMarketingPreferencesPath());
    const { onAction: onEdit } = useAnalyticsActionTracker('onEditProfileConsentSettings');
    const { onAction: onCancel } = useAnalyticsActionTracker('onEditProfileCancel');

    const trackingSectionType = '(Business partner)';

    const startEditing = (): void => {
        onEdit(trackingSectionType);
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        setCurrentView('EDIT_VIEW');

        history.push(changeBusinessPartnersMarketingPreferencesPath());
    };

    const finishEditing = (newEditStatus: EditStatus): void => {
        setLastEditStatus(newEditStatus);
        setCurrentView('CONSULT_VIEW');
        history.push(path);
    };

    const cancelEditing = (): void => {
        onCancel(`Consent settings ${trackingSectionType}`);
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        setCurrentView('CONSULT_VIEW');
        history.push(path);
    };

    return (
        <section ref={scrollToRef}>
            <Switch>
                <Route exact path={changeBusinessPartnersMarketingPreferencesPath()}>
                    <CommonEditView
                        marketingPreference={marketingPreference}
                        cancelEditing={cancelEditing}
                        finishEditing={finishEditing}
                        marketingPreferenceType={MarketingPreferenceType.BUSINESS_PARTNERS}
                        isContactEmailDefined={isContactEmailDefined}
                    />
                </Route>
                <Route path={path}>
                    <CommonConsultView
                        marketingPreferenceType={MarketingPreferenceType.BUSINESS_PARTNERS}
                        marketingPreference={marketingPreference}
                        lastEditStatus={lastEditStatus}
                        startEditing={startEditing}
                    />
                </Route>
            </Switch>
        </section>
    );
};
