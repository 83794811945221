import { getContractDetailsItem } from './contracts';

export function getFinancialDetailsEndpoint(contractCustomerId: string, contractId: string): string {
    return `${getContractDetailsItem(contractCustomerId, contractId)}/financial-details`;
}

export function getIbanChangeEndpoint(contractCustomerId: string, contractId: string): string {
    return `${getFinancialDetailsEndpoint(contractCustomerId, contractId)}/iban`;
}

export function getSepaMandateDownloadEndpoint(contractCustomerId: string, contractId: string): string {
    return `${getFinancialDetailsEndpoint(contractCustomerId, contractId)}/sepa-mandate-download`;
}

export function getPaymentDayUpdateEndpoint(contractCustomerId: string, contractId: string) {
    return `${getFinancialDetailsEndpoint(contractCustomerId, contractId)}/payment-day`;
}

export function getScriveDigitalSignEndpoint(contractCustomerId: string, contractId: string): string {
    return `${getFinancialDetailsEndpoint(contractCustomerId, contractId)}/scrive-digital-sign`;
}

export function getScriveCallbackEndpoint(): string {
    return `/scrive-callback`;
}
