import React, { useEffect } from 'react';
import { IntroductionPageTeaser } from '../../components/introduction-page-teaser';
import { isAfterRegistrationLocalStorageKey } from '../../config';
import { useAnalyticsPageViewTracker } from '@cp-shared-6/frontend-ui';

export const IntroductionPage: React.FC = () => {
    const isAfterRegistration = !!window.localStorage.getItem(isAfterRegistrationLocalStorageKey);
    useAnalyticsPageViewTracker('introductionPage', true, isAfterRegistration);

    useEffect(() => {
        if (isAfterRegistration) {
            window.localStorage.removeItem(isAfterRegistrationLocalStorageKey);
        }
    }, [isAfterRegistration]);

    return <IntroductionPageTeaser />;
};
