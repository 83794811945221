import {
    Notification,
    NotificationStatus,
    useAnalyticsDashboardPageTrackerWithoutUnpaid,
} from '@cp-shared-6/frontend-ui';
import { ContentSection, Heading, Layout } from '@vwfs-bronson/bronson-react';
import { StandaloneMatchmaker } from 'components/contracts/standalone-matchmaker/StandaloneMatchmaker';
import { CustomerHappinessIndex } from 'components/customer-happiness-index/CustomerHappinessIndex';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';
import { Contracts, DashboardIbanNotification } from '../../components/contracts';
import { Greeting } from '../../components/greeting';
import { MarketingCards } from '../../components/marketing-cards';
import { HookPageRedirectionPath, loginAnalyticsPath } from '../../components/navigation/paths';
import { RootState } from '../../store/rootReducer';
import { getAllActiveContractsIbans, isBankLeavingIban } from '../../utils';
import { useMyProfile } from '../../components/my-profile';

export const DashboardPage: React.FC = () => {
    const { t } = useTranslation('dashboard');
    const title = t('headline');
    const info = t('info-notification');
    const lastLocation = useLastLocation();
    const lastPathname =
        lastLocation?.pathname === HookPageRedirectionPath() ? loginAnalyticsPath() : lastLocation?.pathname;

    const financialDetails = useSelector((state: RootState) => state.financialDetails);
    const ibans = getAllActiveContractsIbans(financialDetails);
    const showIbanNotification =
        ibans.some(iban => isBankLeavingIban(iban || '')) && ibans.every(val => val === ibans[0]);

    useAnalyticsDashboardPageTrackerWithoutUnpaid(true, lastPathname || '', undefined, showIbanNotification);

    const [visible, setIsVisible] = useState(true);

    const { data: personalDetails } = useMyProfile();

    const isContactEmailDefined = !!personalDetails?.contactDetails.email;

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{title}</Heading>
            <Greeting />
            <Layout>
                <Layout.Item default={'2/3'} m={'1/1'}>
                    {showIbanNotification && <DashboardIbanNotification />}
                    <Contracts />
                    <StandaloneMatchmaker />
                    {isContactEmailDefined && <CustomerHappinessIndex />}
                    <Notification
                        status={NotificationStatus.info}
                        text={info}
                        visible={visible}
                        testId="dashboard-info-notification"
                        className="u-mb-small"
                        onCloseClick={(): void => setIsVisible(!visible)}
                        showCloseButton
                    />
                </Layout.Item>
                <Layout.Item default={'1/3'} m={'1/1'}>
                    <MarketingCards />
                </Layout.Item>
            </Layout>
        </ContentSection>
    );
};
