import React from 'react';
import { useTranslation } from 'react-i18next';

export type DigitalSignLinkProps = {
    onClick: () => void;
};

export const DigitalSignLink: React.FC<DigitalSignLinkProps> = ({ onClick }) => {
    const { t } = useTranslation('financial-details');

    const translationPrefix = 'bank-account-section.iban.edit-view.digital-sign';

    return (
        <a
            onClick={event => {
                event.preventDefault();
                onClick();
            }}
            className="c-link c-download-link"
        >
            <i className="c-link__icon c-icon c-icon--[semantic-details]" />
            <span className="c-link__body">
                <span className="c-link__effect">{t(`${translationPrefix}.link`)}</span>
            </span>
        </a>
    );
};
