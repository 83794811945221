import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-6/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-6/common-utilities';
import {
    FinancialDetailsInactiveContract,
    FinancialDetailsWithAllValues,
    FinancialDetailsWithMissingValues,
} from './ExampleData';
import { ContractsWithAllValues as Contracts } from 'components/contracts/ExampleData';
import { ContractsMock } from './contractsMock';
import { IbanValidationMock } from './bank-account-section/edit-view/iban-validation-view/IbanValidationResponseMocks';
import { DigitalSignMock, SepaDownloadMock } from './bank-account-section/edit-view/sepa-download-view';
import { MyProfileMock } from '../../my-profile';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: FinancialDetailsWithAllValues,
    },
    'With Missing Values': {
        status: 200,
        responseBody: FinancialDetailsWithMissingValues,
    },
    'Inactive Contract': {
        status: 200,
        responseBody: FinancialDetailsInactiveContract,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const FinancialDetailsMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Financial Details Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: Contracts[0]._links?.financialDetails || '',
};

export const FinancialDetailsResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [
        ContractsMock,
        FinancialDetailsMock,
        IbanValidationMock,
        SepaDownloadMock,
        DigitalSignMock,
        MyProfileMock,
    ]);

    return <div>{storyFn()}</div>;
};
