import React from 'react';

type WizardWithoutAccordionProps = {
    collapseBreakpoint: string;
};

export const WizardWithoutAccordion: React.FC<WizardWithoutAccordionProps> = ({ collapseBreakpoint, children }) => (
    <nav
        className={'c-wizard js-wizard js-is-ready c-wizard--no-interaction'}
        data-wizard-collapse={collapseBreakpoint}
    >
        <ol className={'c-wizard__list is-opened js-wizard__list js-is-ready'}>
            {children /* Use 'Wizard.Item' component. */}
        </ol>
    </nav>
);
