import React from 'react';
import { FinancialDetails } from '@cp-ie/common';
import {
    DataOverview,
    DefinitionList,
    DefinitionListItem,
    NotificationStatus,
    Notification,
    useAnalyticsActionTracker,
} from '@cp-shared-6/frontend-ui';
import { useTranslation } from 'react-i18next';
import { EditStatus } from '../edit-view/EditStatus';
import useMobileDetect from '../../payment-day-section/consult-view/useMobileDetect';

const maskIban = (iban?: string) => {
    if (!iban) {
        return '-';
    }
    const numbersOfStars = iban.length - 8 - 4;
    const stars = new Array(numbersOfStars).fill('*').join('');
    const firstEightChars = iban.substring(0, 8);
    const lastFourDigits = iban.substring(iban.length - 4);
    return `${firstEightChars}${stars}${lastFourDigits}`;
};

export type ConsultViewProps = {
    startEditing: () => void;
    details: FinancialDetails;
    lastEditStatus?: EditStatus;
    showDigitalSignNotification?: boolean;
};
export const ConsultView: React.FC<ConsultViewProps> = ({
    details,
    startEditing,
    lastEditStatus,
    showDigitalSignNotification,
}) => {
    const { t } = useTranslation('financial-details');
    const { onAction: onSepaDigitalSignSuccess } = useAnalyticsActionTracker('onDashboardEditSepaSignSuccess');
    const { isMobile } = useMobileDetect();
    const itemList: DefinitionListItem[] = [
        {
            label: t('bank-account-section.iban.label'),
            value: maskIban(details.iban),
            tooltip: t('bank-account-section.iban.tooltip'),
            testId: 'bank-account-section-iban',
        },
    ];

    if (showDigitalSignNotification) {
        onSepaDigitalSignSuccess();
    }

    return (
        <DataOverview
            title={t('bank-account-section.headline')}
            buttonLabel={
                details.isActive
                    ? isMobile
                        ? ((true as unknown) as string)
                        : t('translation:editable-section-nav.start')
                    : undefined
            }
            buttonProps={{ onClick: startEditing, testId: 'edit-button', secondary: true, icon: '' }}
            withoutCardEffect={true}
        >
            <DefinitionList split={true} list={itemList} />
            {lastEditStatus === EditStatus.SUCCESS && (
                <Notification
                    status={NotificationStatus.warning}
                    headline={t('bank-account-section.iban.success-notification.headline')}
                    text={t('bank-account-section.iban.success-notification.text')}
                    testId={'success-notification'}
                />
            )}
            {showDigitalSignNotification && (
                <Notification
                    status={NotificationStatus.warning}
                    headline={t('bank-account-section.iban.digital-sign-notification.headline')}
                    testId={'success-notification'}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('bank-account-section.iban.digital-sign-notification.text'),
                        }}
                    />
                </Notification>
            )}
        </DataOverview>
    );
};
