import { formatCpDate } from '@cp-shared-6/common-utilities';

const getDigitalSignLocalStorageKey = (encryptedContractNumber: string): string => {
    return `DIGITAL_SIGN_${encryptedContractNumber}`;
};

export const showDigitalSignNotification = (encryptedContractNumber: string): boolean => {
    const localStorageKey = getDigitalSignLocalStorageKey(encryptedContractNumber);
    const today = formatCpDate().toMoment();
    const threeDaysAgo = today.clone().subtract(3, 'days');
    const storageDate = localStorage.getItem(localStorageKey);

    if (
        storageDate &&
        formatCpDate(storageDate)
            .toMoment()
            .isBetween(threeDaysAgo, today, 'day', '[]')
    ) {
        return true;
    }
    localStorage.removeItem(localStorageKey);
    return false;
};

export const setDigitalSignNotifiation = (encryptedContractNumber: string): void => {
    localStorage.setItem(getDigitalSignLocalStorageKey(encryptedContractNumber), formatCpDate().toCpDate());
};
