import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const marketingPreferencesEmailValidationSchema = (t: TFunction, isContactAddressDefined: boolean) => {
    const requiredEmailError = 'edit-view.email.validation.required';
    const invalidEmailError = 'edit-view.email.validation.invalid';
    const options = { ns: 'my-profile-contact-details' };
    return Yup.object().shape({
        emailAddress: Yup.mixed().when('preferences.email', {
            is: (email: boolean) => email && !isContactAddressDefined,
            then: Yup.string()
                .trim()
                .required(t(requiredEmailError, options))
                .email(t(invalidEmailError, options)),
        }),
    });
};
