import React from 'react';
import { Heading, ButtonContainer, Button, Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from '../../../../../../navigation/paths';

export const DigitalSignSuccess: React.FC = () => {
    const { t } = useTranslation('digital-sign');
    const history = useHistory();

    return (
        <Layout className="u-text-center">
            <Layout.Item>
                <Heading level="1" className="u-text-brand u-mt">
                    {t('successPage.headline')}
                </Heading>
            </Layout.Item>
            <Layout.Item>
                <i className="c-icon  c-icon--[semantic-checkmark]  c-icon--xxlarge u-text-success" />
            </Layout.Item>
            <Layout.Item m="1/1" default="2/3">
                <Heading level="6" className="u-text-brand">
                    {t('successPage.subHeadline')}
                </Heading>
            </Layout.Item>
            <Layout.Item m="1/1" default="1/2">
                <ButtonContainer center>
                    <Button testId="backToDashboardButton" onClick={() => history.push(dashboardPagePath())}>
                        {t('successPage.button')}
                    </Button>
                </ButtonContainer>
            </Layout.Item>
            <Layout.Item m="1/1" default="2/3">
                <p>{t('successPage.additionalInformation')}</p>
            </Layout.Item>
        </Layout>
    );
};
