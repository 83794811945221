import React from 'react';
import {
    HeroTeaserLoadingPlaceholder,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
    useAuthentication,
} from '@cp-shared-6/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { LandingPageTeaserUi } from './ui';
import { useLandingPageTeaser } from './useLandingPageTeaser';
import { useLogin } from '../../auth/useLogin';

const LandingPageTeaserWithHandlers = withLoadingAndNoConnectionHandler(LandingPageTeaserUi);

export const LandingPageTeaser: React.FC = () => {
    const { cmsContent: landingPageTeaser, isLoading, loadingError } = useLandingPageTeaser();
    const { isAuthenticated } = useAuthentication();
    const login = useLogin();
    const { onAction } = useAnalyticsActionTracker('login');
    useAnalyticsPageViewTracker('landingPage', !!landingPageTeaser, isAuthenticated);

    const onClick = (): void => {
        onAction();
        login();
    };
    return (
        <LandingPageTeaserWithHandlers
            isLoading={isLoading}
            landingPageTeaser={landingPageTeaser}
            clickHandler={onClick}
            hasError={!!loadingError}
            loadingPlacehloder={<HeroTeaserLoadingPlaceholder withPretitle withSubTitle withButton />}
        />
    );
};
