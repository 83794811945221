import React from 'react';
import { useContract } from '../contracts/useContract';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { ContractEndOptionsLoadingPlaceholder } from './ContractEndOptionsLoadingPlaceholder';
import { ContractEndOptionsUi } from './ui';

const ContractEndOptionsWithHandlers = withLoadingAndNoConnectionHandler(ContractEndOptionsUi);

export const ContractEndOptions: React.FC<{ encryptedContractNumber: string }> = ({ encryptedContractNumber }) => {
    const {
        data: contract,
        isLoading: isLoadingContract,
        loadingError: contractLoadingError,
    } = useContract(encryptedContractNumber, { encryptedContractNumber: true });

    return (
        <ContractEndOptionsWithHandlers
            contract={contract}
            isLoading={isLoadingContract}
            hasError={!!contractLoadingError}
            loadingPlacehloder={<ContractEndOptionsLoadingPlaceholder />}
        />
    );
};
