import { createBubble, DefaultInfoBubbleProps, HereMapMarkerType } from '@vwfs-its/sf-heremaps-frontend';
import { Brand } from '@vwfs-its/sf-heremaps-frontend/dist/components/HereMaps/HereMap';
import { currentBrand } from 'config';
import { TFunction } from 'i18next';

export type Coordinates = {
    latitude: number;
    longitude: number;
};

export type MarkersInfo = {
    coordinates: Coordinates;
    bubbleInfo: BubbleInfo;
};

export type BubbleInfo = {
    infoProps: DefaultInfoBubbleProps;
    dealerCode?: string;
};

export const mapToHereMapsMarkers = (
    markersInfo: MarkersInfo[],
    hasBubble: boolean,
    t?: TFunction,
): HereMapMarkerType[] => {
    return markersInfo.map(m => ({
        markerId: m.bubbleInfo.dealerCode || ' ',
        regionId: 'regionId',
        latitude: m.coordinates.latitude,
        longitude: m.coordinates.longitude,
        infoBubble: hasBubble
            ? createBubble({
                  addressLabel: t ? `${t('info-bubble.address-label')}` : ' ',
                  city: m.bubbleInfo.infoProps.city,
                  buttonLabel: t ? `${t('info-bubble.button-label')}` : ' ',
                  companyName: m.bubbleInfo.infoProps.companyName,
                  mobilePhoneNumber: m.bubbleInfo.infoProps.mobilePhoneNumber,
                  mobilePhoneNumberLabel: t ? `${t('info-bubble.phone-label')}` : ' ',
                  street: m.bubbleInfo.infoProps.street,
                  houseNumber: m.bubbleInfo.infoProps.houseNumber,
                  zipCode: m.bubbleInfo.infoProps.zipCode,
              })
            : '',
        brand: currentBrand as Brand,
        buttonLabel: t ? `${t('info-bubble.button-label')}` : ' ',
    }));
};
