import React from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatedSelect, ValidatedSelectItem } from '@cp-shared-6/frontend-ui';
import { useField } from 'formik';
import { ReasonOther } from './reason-other/ReasonOther';

export const ReasonSelection: React.FC = () => {
    const { t } = useTranslation('request');
    const translationPrefix = 'category-selection.balance-enquiry-form';
    const fieldName = 'balanceEnquiryForm.reasonForSettlement';
    const [field] = useField(fieldName);

    const keyValueMap = new Map([
        ['trading', 'Trading up for a new vehicle'],
        ['keeping', 'Keeping the vehicle'],
        ['moving-within-vw', 'Moving to a vehicle within the VW Group (VW, Audi, Skoda, Seat or VW CV)'],
        ['moving-to-non-vw', 'Moving to a non VW Group vehicle'],
        ['other', 'Other'],
    ]);

    const selectItems = Array.from(keyValueMap).map(([key, value]: [string, string]) => {
        return { value, label: t(`${translationPrefix}.reason-selection.options.${key}`) };
    }) as ValidatedSelectItem[];

    const mandatoryLabel = `${t(`${translationPrefix}.reason-selection.label`)} *`;

    const isOtherSelected = (): boolean => {
        return field.value === keyValueMap.get('other');
    };

    return (
        <>
            <ValidatedSelect
                label={mandatoryLabel}
                name={fieldName}
                id={'reason-selection'}
                testId={'reason-selection'}
                selectItems={selectItems}
                placeholder={t(`${translationPrefix}.reason-selection.placeholder`)}
                disablePlaceholder={true}
            />
            {isOtherSelected() && (
                <ReasonOther
                    label={t(`${translationPrefix}.reason-other.reson-label`)}
                    name="balanceEnquiryForm.otherReasonForSettlement"
                />
            )}
        </>
    );
};
