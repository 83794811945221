import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { Button, ButtonContainer, DataOverview, Fieldset, Form, Layout, Tooltip } from '@vwfs-bronson/bronson-react';
import { Contact, getContactDetailsEndpoint, irelandCountryCode, Phone } from '@cp-ie/common';
import {
    Notification,
    NotificationStatus,
    preventSubmit,
    UiBlockingSpinner,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    useAnalyticsPageViewTracker,
    ValidatedInput,
} from '@cp-shared-6/frontend-ui';
import { validationSchema } from './validationSchema';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { NoConnectionView } from '../no-connection-view/NoConnectionView';
import { CpDataApi } from '../../../../cp-xhr';
import { EditStatus } from '../EditStatus';
import { PhoneInput } from './phone-input/PhoneInput';
import { useScrollTo } from '../../../../utils/use-scroll-to';
import { getPhoneValidationEndpoint } from '@cp-shared-6/apis';

export type EditViewProps = {
    contact?: Contact;
    cancelEditing: () => void;
    finishEditing: (newEditStatus: EditStatus) => void;
};

export const EditView: React.FC<EditViewProps> = ({ contact, cancelEditing, finishEditing }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { t } = useTranslation('my-profile-contact-details');
    const scrollToRef = useScrollTo(isSubmitting ? 'SUBMITTING_VIEW' : 'EDIT_VIEW', 'EDIT_VIEW', 100);

    const trackingSection = 'Contact';
    useAnalyticsPageViewTracker('editProfileSectionDisplayed', true, trackingSection);
    const { onTyping } = useAnalyticsFormTracker({
        startTyping: 'onEditProfileContactTypedIn',
    });
    const { onAction: onValidationError } = useAnalyticsActionTracker('onEditProfileContactValidationError');
    const getInitialErrors = (values: { [k: string]: string | Phone | undefined }): string =>
        ['email', 'mobilePhone', 'otherPhone'].filter(element => !values[element] || values[element] === '').join(', ');
    const getErrors = (errors: { [k: string]: string | undefined }): string => Object.keys(errors).join(`, `);

    const emptyPhoneField: Phone = { number: '', countryCode: irelandCountryCode };

    const { email, mobilePhone, otherPhone } = contact || {
        email: '',
        mobilePhone: emptyPhoneField,
        otherPhone: emptyPhoneField,
    };
    const getPhoneInitialValues = (phone?: Phone): Phone => {
        const { number, countryCode } = phone || emptyPhoneField;
        return { number, countryCode };
    };

    const initialValues: Contact = {
        email,
        mobilePhone: getPhoneInitialValues(mobilePhone),
        otherPhone: getPhoneInitialValues(otherPhone),
    };

    const validatePhone = (value: Phone): Promise<boolean> => {
        return CpDataApi.post(getPhoneValidationEndpoint(), {
            telephoneNumber: value.number,
            telephoneCountryCode: '+' + value.countryCode,
        })
            .then(response => {
                if (response.data.isValid === false) {
                    finishEditing(EditStatus.PHONE_VALIDATION_ERROR);
                    return false;
                } else {
                    return true;
                }
            })
            .catch(() => {
                finishEditing(EditStatus.PHONE_VALIDATION_ERROR);
                return false;
            });
    };
    const promiseChecker = (arr: boolean[]) => arr.every(v => v);

    const onSubmit = (values: Contact): void => {
        setIsSubmitting(true);

        const promises: Promise<boolean>[] = [];

        promises.push(
            CpDataApi.put(getContactDetailsEndpoint(), values)
                .then(response => {
                    if (response.status === 200) {
                        return true;
                    } else {
                        finishEditing(EditStatus.ERROR);
                        return false;
                    }
                })
                .catch(() => {
                    finishEditing(EditStatus.ERROR);
                    return false;
                }),
        );

        if (values.mobilePhone?.number) {
            promises.push(validatePhone(values.mobilePhone));
        }

        if (values.otherPhone?.number) {
            promises.push(validatePhone(values.otherPhone));
        }

        Promise.all(promises)
            .then(promiseValidations => {
                if (promiseChecker(promiseValidations)) {
                    setIsSubmitting(false);
                    finishEditing(EditStatus.SUCCESS);
                }
            })
            .catch(() => {
                setIsSubmitting(false);
                finishEditing(EditStatus.ERROR);
            });
    };

    const notificationInfoText = (
        <span
            dangerouslySetInnerHTML={{
                __html: t('edit-view.notification-info'),
            }}
        />
    );

    return (
        <section ref={scrollToRef}>
            <DataOverview title={t('edit-view.title')}>
                {!contact ? (
                    <NoConnectionView cancelEditing={cancelEditing} />
                ) : (
                    <>
                        <Notification status={NotificationStatus.info} className={`u-mb`}>
                            {notificationInfoText}
                        </Notification>
                        <UiBlockingSpinner isBlocking={isSubmitting}>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema(t)}
                                onSubmit={onSubmit}
                            >
                                {({ submitForm, errors, touched, values }): JSX.Element => (
                                    <Form
                                        onSubmit={preventSubmit()}
                                        data-testid="edit-form"
                                        onChange={(): void => onTyping(errors, touched)}
                                    >
                                        <Fieldset>
                                            <Fieldset.Row>
                                                <Layout>
                                                    <Layout.Item>
                                                        <ValidatedInput
                                                            label={t('edit-view.email.label')}
                                                            tooltip={t('edit-view.email.tooltip')}
                                                            name="email"
                                                            testId="email"
                                                            type="email"
                                                        />
                                                    </Layout.Item>
                                                </Layout>
                                            </Fieldset.Row>
                                            <PhoneInput
                                                inputName={'mobilePhone.number'}
                                                selectName={'mobilePhone.countryCode'}
                                                tooltip={t('edit-view.mobile-phone.tooltip')}
                                                testId={'mobile-phone'}
                                                label={t('edit-view.mobile-phone.label')}
                                            />
                                            <PhoneInput
                                                inputName={'otherPhone.number'}
                                                selectName={'otherPhone.countryCode'}
                                                testId={'other-phone'}
                                                label={t('edit-view.other-phone.label')}
                                            />
                                        </Fieldset>
                                        <Fieldset>
                                            <Fieldset.Row>
                                                <ButtonContainer center>
                                                    <Button
                                                        secondary
                                                        onClick={cancelEditing}
                                                        testId="cancel-button"
                                                        type="button"
                                                    >
                                                        {t('translation:editable-section-nav.cancel')}
                                                    </Button>
                                                    <Button
                                                        onClick={async (): Promise<void> => {
                                                            await submitForm();
                                                            const initialErrors = getInitialErrors(values);
                                                            if (!isEmpty(errors)) {
                                                                const errorsList = getErrors(errors);
                                                                onValidationError(errorsList);
                                                            } else if (!!initialErrors) {
                                                                onValidationError(initialErrors);
                                                            }
                                                        }}
                                                        testId="submit-button"
                                                        type="submit"
                                                    >
                                                        <Tooltip content={t('edit-view.confirm-button.tooltip')}>
                                                            <span> {t('edit-view.confirm-button.label')} </span>
                                                        </Tooltip>
                                                    </Button>
                                                </ButtonContainer>
                                            </Fieldset.Row>
                                        </Fieldset>
                                    </Form>
                                )}
                            </Formik>
                        </UiBlockingSpinner>
                    </>
                )}
            </DataOverview>
        </section>
    );
};
