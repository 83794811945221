import React from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatedSelect, ValidatedSelectItem } from '@cp-shared-6/frontend-ui';
import { ReasonOther } from '../reason-selection/reason-other/ReasonOther';
import { useField } from 'formik';

export const FormSelection: React.FC = () => {
    const { t } = useTranslation('request');

    const translationPrefix = 'category-selection.balance-enquiry-form';
    const fieldName = 'balanceEnquiryForm.formOfSettlement';
    const [field] = useField(fieldName);
    const keyValueMap = new Map([
        ['debit-credit-card', 'Debit/ Credit Card'],
        ['eft', 'EFT'],
        ['other', 'Other'],
    ]);

    const selectItems = Array.from(keyValueMap).map(([key, value]: [string, string]) => {
        return { value, label: t(`${translationPrefix}.form-selection.options.${key}`) };
    }) as ValidatedSelectItem[];

    const mandatoryLabel = `${t(`${translationPrefix}.form-selection.label`)} *`;

    const isOtherSelected = (): boolean => {
        return field.value === keyValueMap.get('other');
    };

    return (
        <>
            <ValidatedSelect
                label={mandatoryLabel}
                name={'balanceEnquiryForm.formOfSettlement'}
                id={'form-selection'}
                testId={'form-selection'}
                selectItems={selectItems}
                placeholder={t(`${translationPrefix}.form-selection.placeholder`)}
                disablePlaceholder={true}
            />
            {isOtherSelected() && (
                <ReasonOther
                    label={t(`${translationPrefix}.reason-other.form-label`)}
                    name="balanceEnquiryForm.otherFormOfSettlement"
                />
            )}
        </>
    );
};
