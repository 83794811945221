import { TFunction } from 'i18next';
import { Namespace, useTranslation, UseTranslationOptions, UseTranslationResponse } from 'react-i18next';

import {
    formatAsCurrency,
    formatAsDate,
    formatAsNumber,
    formatAsNumeral,
    formatAsEmission,
    numberFormat,
    numberFormatRoundedOne,
} from '@cp-ie/common';

export enum TranslationFormat {
    DISTANCE,
    DAYS,
    NUMERAL,
    NUMBER,
    NUMBER_ROUNDED_ONE,
    CURRENCY,
    DATE,
    EMISSION,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormatterFunction = (value: any, format: TranslationFormat) => string;

type UseFormattingResponse = {
    f: FormatterFunction;
};
type UseTranslationWithFormattingResponse = UseTranslationResponse & UseFormattingResponse;

function createFormatter(t: TFunction): FormatterFunction {
    return (value, format) => {
        switch (format) {
            case TranslationFormat.DISTANCE:
                return typeof value === 'number' ? t('translation:metrics.distance', { value }) : '';
            case TranslationFormat.CURRENCY:
                return formatAsCurrency(value);
            case TranslationFormat.DATE:
                return formatAsDate(value);
            case TranslationFormat.DAYS:
                return typeof value === 'number' ? t('translation:metrics.day', { value }) : '';
            case TranslationFormat.EMISSION:
                return formatAsEmission(value);
            case TranslationFormat.NUMBER:
                return formatAsNumber(value, numberFormat);
            case TranslationFormat.NUMBER_ROUNDED_ONE:
                return formatAsNumber(value, numberFormatRoundedOne);
            case TranslationFormat.NUMERAL:
                return formatAsNumeral(value);
        }
    };
}

export function useTranslationWithFormatting(
    ns?: Namespace,
    options?: UseTranslationOptions,
): UseTranslationWithFormattingResponse {
    const useTranslationResponse = useTranslation(ns, options);
    const f = createFormatter(useTranslationResponse.t);
    return Object.assign({ f }, useTranslationResponse);
}
