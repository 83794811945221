import React from 'react';
import { UploadItem, Link } from '@vwfs-bronson/bronson-react';
import { formatAsFileSize } from '@cp-ie/common';
import { useTranslation } from 'react-i18next';

export type FileUploadItemProps = {
    maxFileSize?: number;
    validFileFormats?: string[];
    files?: File[];
    removeFile: Function;
    progressValue?: string;
    sizeError?: string | React.ReactNode;
    typeError?: string | React.ReactNode;
    sizeAndTypeError?: string | React.ReactNode;
};

export const FileUploadItems: React.FC<FileUploadItemProps> = ({
    files,
    removeFile,
    maxFileSize,
    validFileFormats,
    progressValue = '100',
    sizeError,
    typeError,
    sizeAndTypeError,
}) => {
    const { t } = useTranslation();
    if (!files) {
        return null;
    }

    const getFileError = (fileSize: number, fileType: string, index: number): string | React.ReactNode => {
        const isSizeInvalid = maxFileSize ? maxFileSize <= fileSize : false;
        const isTypeInvalid = validFileFormats ? !validFileFormats.some((type: string) => type === fileType) : false;
        if (!isSizeInvalid && !isTypeInvalid) {
            return '';
        }
        const error = isSizeInvalid && isTypeInvalid ? sizeAndTypeError : isSizeInvalid ? sizeError : typeError;
        return (
            <>
                {error}
                <br />
                <Link onClick={(): void => removeFile(index)} normalLink>
                    {t('editable-section-nav.cancel')}
                </Link>
            </>
        );
    };

    const getFileType = (name: string): string => {
        const lastDot = name.lastIndexOf('.');
        return name.substring(lastDot + 1).toLowerCase() || '';
    };

    return (
        <>
            {files.map(({ name, size }, index) => {
                const type = getFileType(name);
                const error = getFileError(size, type, index);
                return (
                    <UploadItem
                        className={'u-mb-small'}
                        key={index}
                        icon="semantic-file"
                        fileName={name}
                        error={!!error}
                        fileSize={formatAsFileSize(size, 2)}
                        fileType={type}
                        message={error}
                        hideMainButton
                        onSecondaryButtonClick={(): void => removeFile(index)}
                        progressValue={progressValue}
                    />
                );
            })}
        </>
    );
};
