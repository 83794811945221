import React, { useCallback } from 'react';
import { ContractType, ContractInfo } from '@cp-ie/common';
import { useTranslation } from 'react-i18next';
import { Combobox, FormField, ErrorMessage } from '@vwfs-bronson/bronson-react';
import { getInitialValues } from '../initialValues';
import { useField } from 'formik';
import { ComboboxOption, ComboboxState } from '../../../../types/bronson-react';

export type ContractSelectionProps = {
    contracts?: ContractInfo[];
};

export const ContractSelection: React.FC<ContractSelectionProps> = ({ contracts }) => {
    const { t } = useTranslation('request');
    const [field, meta, helpers] = useField('contractSelection');

    const setValueChange = useCallback((selectedContractsNumbers: ComboboxOption[]): void => {
        const { isActive, brand, contractNumber, licensePlate } = contracts?.find(
            ({ contractNumber }) => contractNumber === selectedContractsNumbers[0].key,
        ) || { brand: '', contractNumber: '', licensePlate: '' };

        const contractSelectionValues = {
            isActive,
            brand,
            contractNumber,
            registrationNumber: licensePlate,
        };

        return helpers.setValue(contractSelectionValues || getInitialValues().contractSelection);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (!contracts) {
        return null;
    }

    const options = contracts.map(({ model, brand, licensePlate, contractNumber, contractType, isActive }) => {
        const getContractTypeValue = (): string => {
            const type = !isActive
                ? 'expired'
                : contractType === ContractType.HIRE_PURCHASE
                ? 'hire-purchase'
                : 'personal-contract-plan';
            return t(`contract-selection.contract-type.${type}`);
        };

        const carInformation = `${brand} ${model}, ${licensePlate}`;

        return (
            <Combobox.Item
                testId={`contract-selection-item-${contractNumber}`}
                optionValue={contractNumber}
                optionKey={contractNumber}
                key={contractNumber}
            >
                <div>
                    <b>
                        {getContractTypeValue()} - {contractNumber}
                    </b>
                    <br />
                    {brand && model && licensePlate && <span>{carInformation}</span>}
                </div>
            </Combobox.Item>
        );
    });

    const placeholderText = t('contract-selection.placeholder');
    const placeholder = (
        <Combobox.Item
            testId={'contract-selection-placeholder'}
            optionValue={placeholderText}
            optionKey={'placeholder'}
            key={'placeholder'}
        >
            {placeholderText}
        </Combobox.Item>
    );

    const optionsWithPlaceholder = !!field.value.contractNumber ? options : [placeholder, ...options];

    const defaultState: ComboboxState = {
        value: [
            {
                value: placeholderText,
                key: 'placeholder',
            },
        ],
    };

    return (
        <FormField
            type="select"
            testId={'contract-selection'}
            id={'contract-selection'}
            labelText={t('contract-selection.label')}
            notion
            errorMessage={
                <ErrorMessage testId={'contract-selection-error-message'}>
                    {meta.touched && meta.error ? meta.error : ''}
                </ErrorMessage>
            }
        >
            <Combobox
                testId={'custom-contract-select'}
                icon="semantic-expand"
                defaultState={defaultState}
                onChange={setValueChange}
            >
                {optionsWithPlaceholder}
            </Combobox>
        </FormField>
    );
};
