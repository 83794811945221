import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { IconFooterUi } from './ui';
import { useIconFooter } from './useIconFooter';
import { IconFooterLoadingPlaceholder } from '@cp-shared-6/frontend-ui';

const IconFooterWithHandlers = withLoadingAndNoConnectionHandler(IconFooterUi);

export const IconFooter: React.FC = () => {
    const { cmsContent: iconFooter, isLoading, loadingError } = useIconFooter();

    return (
        <IconFooterWithHandlers
            isLoading={isLoading}
            iconFooter={iconFooter}
            hasError={!!loadingError}
            loadingPlacehloder={<IconFooterLoadingPlaceholder summaryElements={2} />}
        />
    );
};
