import { formatCpDate } from '@cp-shared-6/common-utilities';
import { useAnalyticsHookPageViewTracker } from '@cp-shared-6/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useMarketingPreferences } from 'components/my-profile/marketing-preferences-sections';
import { HookPageRedirectionPath, loginAnalyticsPath } from 'components/navigation/paths';
import React, { useEffect } from 'react';
import { useLastLocation } from 'react-router-last-location';
import { lastContactDetailsHookPageKey } from '../../../../config';
import { useMyProfile } from '../../../my-profile';
import { MarketingPreferencesUi } from './ui/MarketingPreferencesUi';

const MarketingPreferencesWithHandlers = withLoadingAndNoConnectionHandler(MarketingPreferencesUi);

export const MarketingPreferences: React.FC = () => {
    const {
        data: marketingPreferences,
        isLoading: isMarketingPreferencesLoading,
        loadingError: loadingMarketingPreferencesError,
    } = useMarketingPreferences();
    const {
        data: personalDetails,
        isLoading: isMyProfileLoading,
        loadingError: loadingMyProfileError,
    } = useMyProfile();

    const lastLocation = useLastLocation();
    const lastPathname =
        lastLocation?.pathname === HookPageRedirectionPath() ? loginAnalyticsPath() : lastLocation?.pathname;

    useAnalyticsHookPageViewTracker(
        'marketingAgreement',
        !!marketingPreferences,
        lastPathname || '',
        marketingPreferences,
    );

    useEffect(() => {
        window.localStorage.setItem(lastContactDetailsHookPageKey, formatCpDate().toCpDate());
    }, []);

    const isLoading = isMarketingPreferencesLoading || isMyProfileLoading;
    const hasError: boolean = !!loadingMarketingPreferencesError || !!loadingMyProfileError;
    const isContactEmailDefined = !!personalDetails?.contactDetails.email;

    return (
        <MarketingPreferencesWithHandlers
            marketingPreferences={marketingPreferences}
            isContactEmailDefined={isContactEmailDefined}
            isLoading={isLoading}
            hasError={hasError}
        />
    );
};
