export enum FetchContentError {
    DEFAULT_ERROR = 'DEFAULT_ERROR',
}

export type ContentEndpoint = (locale: string, brand: string) => string;

function getContentEndpoint(locale: string, brand: string, contentType: string): string {
    return `/${brand}/${locale}/${contentType}.json`;
}

export const getLandingPageTeaserEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'landing-page-teaser');
};

export const getIntroductionPageTeaserEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'introduction-page-teaser');
};

export const getNotFoundErrorPageEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'not-found-error');
};

export const getForbiddenErrorPageEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'forbidden-error');
};

export const getGenericErrorEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'generic-error');
};

export const getLegalNoticeEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'legal-notice');
};

export const getUnauthorizedErrorPageEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'unauthorized-error');
};

export const getLegalFooterEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'legal-footer');
};

export const getIconFooterEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'icon-footer');
};

export const getFaqEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'faq');
};

export const getThirdPartyLicensesEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'third-party-licenses');
};

export const getMarketingCardsEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'marketing-cards');
};

export const getLogoutSuccessEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'logout-success');
};
