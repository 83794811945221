import React from 'react';
import {
    HeroImage,
    openInSameWindow,
    StaticPageLoadingPlaceholder,
    useAnalyticsErrorPageTracker,
} from '@cp-shared-6/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useForbiddenErrorPage } from './useForbiddenErrorPage';

const HeroImageWithHandlers = withLoadingAndNoConnectionHandler(HeroImage);

export const ForbiddenErrorPage: React.FC = () => {
    const { cmsContent: forbiddenErrorPage, isLoading, loadingError } = useForbiddenErrorPage();

    useAnalyticsErrorPageTracker('accessDeniedError', !!forbiddenErrorPage);

    return (
        <HeroImageWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            title={forbiddenErrorPage?.title || ''}
            subTitle={forbiddenErrorPage?.subTitle}
            buttonText={forbiddenErrorPage?.buttonText}
            clickHandler={openInSameWindow(forbiddenErrorPage?.buttonUrl)}
            inverted
            loadingPlacehloder={
                <StaticPageLoadingPlaceholder withPretitle withSubTitle withButton>
                    <></>
                </StaticPageLoadingPlaceholder>
            }
        />
    );
};
