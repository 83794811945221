import {
    AbstractDataState,
    hasFetchedSuccessfully,
    useGetContractBasedApiData,
} from '@cp-shared-6/frontend-integration';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-6/common-utilities';
import { fetchDealerDetails } from './DealerDetailsSlice';
import { DealersDataSelector } from './DealersDataSelector';
import { useContract } from '../useContract';
import { useMemo } from 'react';
import { DealerDetails } from '@cp-ie/common/src';

export function useDealerDetails(
    contractNumber: string,
): AbstractDataState<DealerDetails, DefaultBusinessMarketApiErrorCode> {
    const contractState = useContract(contractNumber);
    const dealerDetailsState = useGetContractBasedApiData(
        contractNumber,
        fetchDealerDetails,
        DealersDataSelector,
        contractState.data?._links?.dealerDetails,
    );
    return useMemo(
        () => ({
            isLoading: contractState.isLoading || dealerDetailsState.isLoading,
            hasReceivedResponse: contractState.loadingError
                ? contractState.hasReceivedResponse
                : contractState.hasReceivedResponse && dealerDetailsState.hasReceivedResponse,
            failedLoadingAttempts: hasFetchedSuccessfully(contractState)
                ? dealerDetailsState.failedLoadingAttempts
                : contractState.failedLoadingAttempts,
            data: dealerDetailsState.data,
            loadingError: hasFetchedSuccessfully(contractState)
                ? dealerDetailsState.loadingError
                : contractState.loadingError,
        }),
        [contractState, dealerDetailsState],
    );
}
