import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { LegalFooterUi } from './ui';
import { useLegalFooter } from './useLegalFooter';
import { LegalFooterLoadingPlaceholder } from '@cp-shared-6/frontend-ui';

const LegalFooterWithHandlers = withLoadingAndNoConnectionHandler(LegalFooterUi);

export const LegalFooter: React.FC = () => {
    const { cmsContent: legalFooter, isLoading, loadingError } = useLegalFooter();

    return (
        <LegalFooterWithHandlers
            isLoading={isLoading}
            legalFooter={legalFooter}
            hasError={!!loadingError}
            loadingPlacehloder={<LegalFooterLoadingPlaceholder />}
        />
    );
};
