import { ContractType, FinancialDetails, formatAsCurrency } from '@cp-ie/common';
import { DefinitionList, DefinitionListItem } from '@cp-shared-6/frontend-ui';
import { ErrorMessage } from '@vwfs-bronson/bronson-react';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { compact } from 'lodash';
import React from 'react';

export const BaseSection: React.FC<{ details: FinancialDetails; contractType?: ContractType }> = ({
    details,
    contractType,
}) => {
    const { t, f } = useTranslationWithFormatting('financial-details');
    const {
        isActive,
        startDate,
        endDate,
        term,
        totalDeposit,
        financedAmount,
        guaranteedMinimumFutureValue,
        remainingNumberOfPayments,
        finalPaymentDate,
        finalPaymentAmount,
        currentSettlementAmount,
        nextPaymentAmount,
    } = details;
    const errorMessage = <ErrorMessage>{t('base-section.empty-data-field-error')}</ErrorMessage>;

    const itemList: DefinitionListItem[] = [
        {
            label: t('base-section.start-date'),
            value: startDate ? f(startDate, TranslationFormat.DATE) : errorMessage,
            testId: 'base-section-start-date',
        },
        {
            label: t('base-section.end-date'),
            value: endDate ? f(endDate, TranslationFormat.DATE) : errorMessage,
            testId: 'base-section-end-date',
        },
        ...(isActive
            ? compact([
                  totalDeposit !== undefined
                      ? {
                            label: t('base-section.total-deposit.label'),
                            tooltip: t('base-section.total-deposit.tooltip'),
                            value: f(totalDeposit, TranslationFormat.CURRENCY),
                            testId: 'base-section-totalDeposit',
                        }
                      : undefined,
                  financedAmount !== undefined
                      ? {
                            label: t('base-section.financed-amount'),
                            value: f(financedAmount, TranslationFormat.CURRENCY),
                            testId: 'base-section-financed-amount',
                        }
                      : undefined,
                  term !== undefined
                      ? {
                            label: t('base-section.term.label'),
                            tooltip: t('base-section.term.tooltip'),
                            value: `${term}`,
                            testId: 'base-section-term',
                        }
                      : undefined,
                  guaranteedMinimumFutureValue !== undefined && contractType === ContractType.PERSONAL_CONTRACT_PLAN
                      ? {
                            label: t('base-section.guaranteed-minimum-future-value.label'),
                            tooltip: t('base-section.guaranteed-minimum-future-value.tooltip'),
                            value: f(guaranteedMinimumFutureValue, TranslationFormat.CURRENCY),
                            testId: 'base-section-guaranteed-minimum-future-value',
                        }
                      : undefined,
                  remainingNumberOfPayments !== undefined && nextPaymentAmount !== undefined
                      ? {
                            label: t('base-section.remaining-monthly-payments.label'),
                            value: t('base-section.remaining-monthly-payments.value', {
                                payments: remainingNumberOfPayments,
                                cost: formatAsCurrency(nextPaymentAmount),
                            }),
                            testId: 'base-section-remaining-monthly-payments',
                        }
                      : undefined,
                  finalPaymentDate !== undefined &&
                  finalPaymentAmount !== undefined &&
                  contractType === ContractType.PERSONAL_CONTRACT_PLAN
                      ? {
                            label: t('base-section.final-payment-due.label'),
                            tooltip: t('base-section.final-payment-due.tooltip'),
                            value: t('base-section.final-payment-due.value', {
                                amount: formatAsCurrency(finalPaymentAmount),
                                date: f(finalPaymentDate, TranslationFormat.DATE),
                            }),
                            testId: 'base-section-final-payment-due',
                        }
                      : undefined,
                  currentSettlementAmount !== undefined
                      ? {
                            label: t('base-section.settlement-amount.label'),
                            tooltip: t('base-section.settlement-amount.tooltip'),
                            value: formatAsCurrency(currentSettlementAmount),
                            testId: 'base-section-settlement-amount',
                        }
                      : undefined,
              ])
            : []),
    ];

    return <DefinitionList split list={itemList} />;
};
