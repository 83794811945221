import { useAuthentication } from '@cp-shared-6/frontend-ui';
import { dashboardPagePath } from 'components/navigation/paths';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LandingPageTeaser } from '../../components/landing-page-teaser';

export const LandingPage: React.FC = () => {
    const { isAuthenticated } = useAuthentication();
    const history = useHistory();

    useEffect(() => {
        if (isAuthenticated) {
            history.push(dashboardPagePath());
        }
    }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <LandingPageTeaser />
        </div>
    );
};
