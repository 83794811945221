import { formatCpDate } from '@cp-shared-6/common-utilities';
import { Modal } from '@vwfs-bronson/bronson-react';
import { useMyProfile } from 'components/my-profile';
import { EditStatus } from 'components/my-profile/contact-section/EditStatus';
import { dashboardPagePath } from 'components/navigation/paths';
import { lastContactDetailsHookPageKey } from 'config';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { CommonUserDetailsComponent } from '../CommonUserDetailsComponent';
import { EditView } from './edit-view/EditView';
import { useAnalyticsActionTracker, useAnalyticsHookPageViewTracker } from '@cp-shared-6/frontend-ui';

export const ContactDetails: React.FC = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [editStatus, setEditStatus] = useState(EditStatus.NOT_PERFORMED);

    const history = useHistory();
    const { t } = useTranslation('contact-details-hookpage');

    const { data: personalDetails, isLoading: isMyProfileLoading } = useMyProfile();

    useAnalyticsHookPageViewTracker('contactDetailsHookPage', true, '');
    const { onAction: onSubmitChanges } = useAnalyticsActionTracker('onContactDetailsHookPageSubmitChanges');

    const onPrimaryClick = (isSubmitting: boolean, editStatus: EditStatus): void => {
        setIsSubmitting(isSubmitting);
        setEditStatus(editStatus);
        if (editStatus === EditStatus.SUCCESS) onSubmitChanges();
    };
    const onModalButtonClick = (): void => history.push(dashboardPagePath());

    useEffect(() => {
        window.localStorage.setItem(lastContactDetailsHookPageKey, formatCpDate().toCpDate());
    }, []);

    return (
        <>
            <CommonUserDetailsComponent
                title={t('title')}
                subsubtitle={t('subsubtitle')}
                isLoadingSpinner={isSubmitting || isMyProfileLoading}
            >
                <EditView
                    contact={personalDetails?.contactDetails}
                    setIsSubmitting={setIsSubmitting}
                    onPrimaryClick={onPrimaryClick}
                    editStatus={editStatus}
                />
            </CommonUserDetailsComponent>
            <Modal
                shown={editStatus === EditStatus.SUCCESS}
                status={'success'}
                title={t('message.success.title')}
                onConfirm={onModalButtonClick}
                buttonConfirmText={t('modal.button')}
                testId={'success-modal'}
                hideCloseButton
            >
                {t(`message.success.content`)}
            </Modal>
        </>
    );
};
