import { Contact, getContactDetailsEndpoint, irelandCountryCode, Phone } from '@cp-ie/common';
import {
    Notification,
    NotificationStatus,
    preventSubmit,
    useAnalyticsActionTracker,
    ValidatedInput,
} from '@cp-shared-6/frontend-ui';
import { Button, ButtonContainer, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { ConfirmationModal } from 'components/hook-page/ConfirmationModal';
import { PhoneInput } from 'components/my-profile/contact-section/edit-view/phone-input/PhoneInput';
import { validationSchema } from 'components/my-profile/contact-section/edit-view/validationSchema';
import { EditStatus } from 'components/my-profile/contact-section/EditStatus';
import { CpDataApi } from 'cp-xhr';
import { Formik, FormikHelpers } from 'formik';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from '../../../../navigation/paths';
import { getPhoneValidationEndpoint } from '@cp-shared-6/apis';
import { SetFieldError } from '../type';

export type EditViewProps = {
    contact?: Contact;
    setIsSubmitting: Dispatch<SetStateAction<boolean>>;
    onPrimaryClick: (isSubmitting: boolean, editStatus: EditStatus) => void;
    editStatus: EditStatus;
};

export const EditView: React.FC<EditViewProps> = ({ contact, setIsSubmitting, onPrimaryClick, editStatus }) => {
    const { t } = useTranslation('contact-details-hookpage');
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);

    const { onAction: onCancelWithoutChanges } = useAnalyticsActionTracker('onContactDetailsHookPageNoChangesCancel');
    const { onAction: onCancelWithChanges } = useAnalyticsActionTracker('onContactDetailsHookPageChangesCancel');
    const { onAction: onCancelAborted } = useAnalyticsActionTracker('onContactDetailsHookPageCancelAborted');

    if (!contact) {
        return null;
    }

    const emptyPhoneField: Phone = { number: '', countryCode: irelandCountryCode };

    const { email, mobilePhone, otherPhone } = contact || {
        email: '',
        mobilePhone: emptyPhoneField,
        otherPhone: emptyPhoneField,
    };

    const validatePhone = (
        value: Phone,
        field: string,
        errorMsg: string,
        setFieldError: SetFieldError,
    ): Promise<boolean> => {
        return CpDataApi.post(getPhoneValidationEndpoint(), {
            telephoneNumber: value.number,
            telephoneCountryCode: '+' + value.countryCode,
        })
            .then(response => {
                if (response.data.isValid === false) {
                    setFieldError(field, errorMsg);
                    onPrimaryClick(false, EditStatus.PHONE_VALIDATION_ERROR);
                    return false;
                } else {
                    return true;
                }
            })
            .catch(() => {
                onPrimaryClick(false, EditStatus.PHONE_VALIDATION_ERROR);
                return false;
            });
    };

    const getPhoneInitialValues = (phone?: Phone): Phone => {
        const { number, countryCode } = phone || emptyPhoneField;
        return { number, countryCode };
    };

    const initialValues: Contact = {
        email,
        mobilePhone: getPhoneInitialValues(mobilePhone),
        otherPhone: getPhoneInitialValues(otherPhone),
    };

    const promiseChecker = (arr: boolean[]) => arr.every(v => v);

    const invalidMobilePhoneError = 'edit-view.mobile-phone.validation.invalid';
    const invalidOtherPhoneError = 'edit-view.other-phone.validation.invalid';

    const onSubmit = (values: Contact, formikHelpers: FormikHelpers<Contact>) => {
        setIsSubmitting(true);
        const promises: Promise<boolean>[] = [];

        promises.push(
            CpDataApi.put(getContactDetailsEndpoint(), values)
                .then(response => {
                    if (response.status === 200) {
                        return true;
                    } else {
                        onPrimaryClick(false, EditStatus.ERROR);
                        return false;
                    }
                })
                .catch(() => {
                    onPrimaryClick(false, EditStatus.ERROR);
                    return false;
                }),
        );

        if (values.mobilePhone?.number) {
            promises.push(
                validatePhone(
                    values.mobilePhone,
                    'mobilePhone.number',
                    t(invalidMobilePhoneError),
                    formikHelpers.setFieldError,
                ),
            );
        }

        if (values.otherPhone?.number) {
            promises.push(
                validatePhone(
                    values.otherPhone,
                    'otherPhone.number',
                    t(invalidOtherPhoneError),
                    formikHelpers.setFieldError,
                ),
            );
        }

        Promise.all(promises)
            .then(promiseValidations => {
                if (promiseChecker(promiseValidations)) {
                    onPrimaryClick(false, EditStatus.SUCCESS);
                }
            })
            .catch(() => {
                onPrimaryClick(false, EditStatus.ERROR);
            });
    };

    const onDashboardButtonClick = (hasChanges: boolean): void => {
        if (hasChanges) {
            onCancelWithChanges();
        } else {
            onCancelWithoutChanges();
        }
        history.push(dashboardPagePath());
    };

    const onSecondaryClick = (dirty: boolean, isValid: boolean) => {
        if (dirty && isValid) setShowModal(true);
        else onDashboardButtonClick(dirty);
    };

    return (
        <>
            {editStatus === EditStatus.ERROR && (
                <Notification testId={'errorNotificationHookpage'} status={NotificationStatus.error} className={'u-mb'}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('message.error.content'),
                        }}
                    />
                </Notification>
            )}

            {editStatus === EditStatus.PHONE_VALIDATION_ERROR && (
                <Notification
                    testId={'errorPhoneNotificationHookpage'}
                    status={NotificationStatus.error}
                    className={'u-mb'}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('message.error.phone'),
                        }}
                    />
                </Notification>
            )}
            <Formik initialValues={initialValues} validationSchema={validationSchema(t)} onSubmit={onSubmit}>
                {({ submitForm, dirty, isValid }): JSX.Element => (
                    <>
                        <Form onSubmit={preventSubmit()} data-testid="edit-form">
                            <Fieldset>
                                <Fieldset.Row>
                                    <Layout>
                                        <Layout.Item>
                                            <ValidatedInput
                                                label={t('edit-view.email.label')}
                                                tooltip={t('edit-view.email.tooltip')}
                                                name="email"
                                                testId="email"
                                                type="email"
                                                className={'u-font-size-fs6 '}
                                            />
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                                <PhoneInput
                                    inputName={'mobilePhone.number'}
                                    selectName={'mobilePhone.countryCode'}
                                    tooltip={t('edit-view.mobile-phone.tooltip')}
                                    testId={'mobile-phone'}
                                    label={t('edit-view.mobile-phone.label')}
                                />
                                <PhoneInput
                                    inputName={'otherPhone.number'}
                                    selectName={'otherPhone.countryCode'}
                                    testId={'other-phone'}
                                    label={t('edit-view.other-phone.label')}
                                />
                            </Fieldset>
                            <ButtonContainer center>
                                <Button secondary testId="backButton" onClick={() => onSecondaryClick(dirty, isValid)}>
                                    {t('button.secondary')}
                                </Button>
                                <Button onClick={submitForm} testId="submit-button" type="submit" disabled={!dirty}>
                                    {t('button.primary')}
                                </Button>
                            </ButtonContainer>
                        </Form>
                        <ConfirmationModal
                            showModal={showModal}
                            title={t('edit-view.modal.heading')}
                            text={t('edit-view.modal.text')}
                            onSecondaryClick={() => onDashboardButtonClick(dirty)}
                            onSubmitClick={submitForm}
                            onModalClose={() => {
                                onCancelAborted();
                                setShowModal(false);
                            }}
                            testId={'contact-details-modal-testId'}
                        />
                    </>
                )}
            </Formik>
        </>
    );
};
