export function getPersonalDetailsEndpoint(): string {
    return '/personal-details';
}

export function getContactDetailsEndpoint(): string {
    return `${getPersonalDetailsEndpoint()}/contact-details`;
}

export function getAddressDetailsEndpoint(): string {
    return `${getPersonalDetailsEndpoint()}/address-details`;
}
