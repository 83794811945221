import React from 'react';
import { LicensePlate as LicensePlateShared } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

export type LicensePlateProps = {
    registrationNumber?: string;
    size?: 'large' | 'medium' | 'small' | 'xsmall';
    className?: string;
};

export const LicensePlate: React.FC<LicensePlateProps> = ({ registrationNumber, size = 'small', className }) => {
    const { t } = useTranslation();

    if (!registrationNumber) {
        return null;
    }

    return (
        <LicensePlateShared
            className={className}
            countryCode={t('country-code')}
            size={size}
            registrationNumber={registrationNumber}
            horizontalStripEu={true}
            euStars={true}
        />
    );
};
