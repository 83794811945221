import React from 'react';
import { LogoutSuccess } from '../../components/logout-success';

type LogoutSuccessPageProps = {
    manualLogout?: boolean;
};

export const LogoutSuccessPage: React.FC<LogoutSuccessPageProps> = ({ manualLogout = false }) => (
    <LogoutSuccess manualLogout={manualLogout} />
);
