import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useMarketingCards } from './useMarketingCards';
import { MarketingCardsUi } from './ui';
import { useContracts } from '../contracts';
import { MarketingCardLoadingPlaceholder } from '@cp-shared-6/frontend-ui';
import { areContractsOneBrand, getMostRecentContractWithBrand } from 'utils';
import { useProductRouting } from './useProductRouting';
import { useDealerDetails } from '../contracts/dealer-details/useDealerDetails';

const MarketingCardsWithHandlers = withLoadingAndNoConnectionHandler(MarketingCardsUi);

export const MarketingCards: React.FC = () => {
    const { cmsContent: marketingCards, isLoading: marketingCardsIsLoading, loadingError } = useMarketingCards();
    const { data: contracts, isLoading: contractsIsLoading } = useContracts();
    const mostRecentContract = getMostRecentContractWithBrand(contracts);
    let unbrandedOSPCard = false;
    const activeContracts = contracts?.filter(contract => contract.isActive);
    const oneBrandContracts = areContractsOneBrand(activeContracts);
    if (!oneBrandContracts) {
        unbrandedOSPCard = true;
    }

    const { data: dealerDetails, isLoading: dealerDetailsIsLoading } = useDealerDetails(
        mostRecentContract?.contractNumber || '',
    );

    const { data: productRouting, isLoading: productRoutingIsLoading } = useProductRouting(mostRecentContract);

    return (
        <MarketingCardsWithHandlers
            isLoading={
                marketingCardsIsLoading || contractsIsLoading || productRoutingIsLoading || dealerDetailsIsLoading
            }
            marketingCards={marketingCards}
            hasError={!!loadingError}
            dealerDetails={dealerDetails}
            onlineServicePlanUrl={unbrandedOSPCard ? 'https://www.vwfs.ie/service-plans/vwfs' : productRouting?.url}
            mostRecentContractBrand={mostRecentContract?.brand}
            unbrandedOSPCard={unbrandedOSPCard}
            loadingPlacehloder={<MarketingCardLoadingPlaceholder />}
        />
    );
};
