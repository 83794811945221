import { Notification, ValidatedInput, useAnalyticsPageViewTracker } from '@cp-shared-6/frontend-ui';
import { Fieldset, Layout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const MarketingPreferencesEmail: React.FC = () => {
    const { t } = useTranslation('marketing-preferences-email');
    useAnalyticsPageViewTracker('marketingAgreementNoEmail');

    return (
        <Fieldset>
            <Fieldset.Row>
                <Layout>
                    <Layout.Item>
                        <Notification
                            headline={t('notification.headline')}
                            text={t('notification.text')}
                            className={'u-mb-small'}
                        >
                            <Fieldset.Row>
                                <ValidatedInput
                                    label={t('email-address')}
                                    isMandatory
                                    name="emailAddress"
                                    testId="email-address"
                                    type="email"
                                />
                            </Fieldset.Row>
                        </Notification>
                    </Layout.Item>
                </Layout>
            </Fieldset.Row>
        </Fieldset>
    );
};
