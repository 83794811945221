import {
    Notification,
    NotificationStatus,
    useAnalyticsActionTracker,
    useAnalyticsDashboardPageTrackerWithoutUnpaid,
} from '@cp-shared-6/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { Button } from '@vwfs-bronson/bronson-react';
import { changeIbanPath } from 'components/navigation/paths';
import { useHistory } from 'react-router-dom';

export const DashboardIbanNotification: React.FC = () => {
    const { t } = useTranslation('financial-details');
    const history = useHistory();
    const contracts = useSelector((state: RootState) => state.contracts);
    const activeContracts = contracts?.data?.filter(item => item.isActive);

    const { onAction: onBankAccountUpdate } = useAnalyticsActionTracker('onBankAccountUpdate');
    useAnalyticsDashboardPageTrackerWithoutUnpaid(true, '', undefined, true);

    const goToIbanEditView = (): void => {
        onBankAccountUpdate();
        history.push(changeIbanPath(activeContracts?.[0].encryptedContractNumber));
    };

    return (
        <Notification
            status={NotificationStatus.warning}
            text={t('iban-notification.text')}
            testId="dashboard-warning-notification"
            className="u-mb-small"
        >
            <Button className=" u-mt-small" onClick={goToIbanEditView}>
                {t('iban-notification.btn-text')}
            </Button>
        </Notification>
    );
};
