import { Notification, NotificationStatus } from '@cp-shared-6/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { Button } from '@vwfs-bronson/bronson-react';
import { changeIbanPath } from 'components/navigation/paths';
import { useHistory } from 'react-router-dom';
import { getAllActiveContractsIbans, isBankLeavingIban } from '../../../../../utils';

export type ContractIbanNotificationProps = {
    encryptedContractNumber: string;
    iban?: string;
    isIbanChanged?: boolean;
};

export const ContractIbanNotification: React.FC<ContractIbanNotificationProps> = ({
    encryptedContractNumber,
    iban,
    isIbanChanged,
}) => {
    const { t } = useTranslation('financial-details');
    const history = useHistory();
    const financialDetails = useSelector((state: RootState) => state.financialDetails);

    if (!iban) return null;

    const goToIbanEditView = (): void => {
        history.push(changeIbanPath(encryptedContractNumber));
    };

    const ibans = getAllActiveContractsIbans(financialDetails);

    const showNotification = isBankLeavingIban(iban || '') && !ibans.every(val => val === ibans[0]);

    return (
        <>
            {isIbanChanged && (
                <Notification
                    status={NotificationStatus.info}
                    text={t('iban-notification.after-change-text')}
                    testId="dashboard-warning-notification"
                    className="u-mb-small"
                />
            )}
            {!isIbanChanged && showNotification && (
                <Notification
                    status={NotificationStatus.warning}
                    text={t('iban-notification.text')}
                    testId="dashboard-warning-notification"
                    className="u-mb-small"
                >
                    <Button className=" u-mt-small" onClick={goToIbanEditView}>
                        {t('iban-notification.btn-text')}
                    </Button>
                </Notification>
            )}
        </>
    );
};
