import { CPDate } from '@cp-shared-6/common-utilities';
import { Brand } from './brand';

export type ContractData = {
    isActive: boolean;
    brand?: Brand;
    model?: string;
    contractNumber?: string;
    nextPaymentAmount?: number;
    licensePlate?: string;
    vin?: string;
    encryptedVin?: string;
    contractType?: ContractType;
    paymentDate?: CPDate;
    endDate?: CPDate;
    startDate?: CPDate;
    outstandingBalance?: number;
    isEligibility?: boolean;
    isDealerSelection?: boolean;
    inArrears?: boolean;
};

export enum ContractType {
    PERSONAL_CONTRACT_PLAN = 'PERSONAL_CONTRACT_PLAN',
    HIRE_PURCHASE = 'HIRE_PURCHASE',
}

export interface ContractTypeChecker {
    readonly isPersonalPlanContract: boolean;
    readonly isHirePurchaseContract: boolean;
}

function contractTypeCheckers(contractType?: ContractType): ContractTypeChecker {
    return {
        isPersonalPlanContract: contractType === ContractType.PERSONAL_CONTRACT_PLAN,
        isHirePurchaseContract: contractType === ContractType.HIRE_PURCHASE,
    };
}

function extractLinks(contract: ContractDataWithLinks): ResourceLinks {
    return {
        _links: contract._links,
    };
}

export function buildContract(contract: ContractDataWithLinks): Contract {
    return {
        ...contract,
        ...extractLinks(contract),
        ...contractTypeCheckers(contract.contractType),
    };
}

export type ResourceLinks = {
    _links?: ContractLinks;
};

export type ContractLinks = {
    financialDetails?: string;
    vehicleDetails?: string;
    dealerDetails?: string;
};

export type EncryptedContractNumber = {
    encryptedContractNumber?: string;
};

export type ContractDataWithLinks = ContractData & ResourceLinks & EncryptedContractNumber;
export type Contract = ContractDataWithLinks & ContractTypeChecker;
