import { getContractsEndpoint } from '@cp-ie/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-6/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-6/frontend-storybook-extensions';
import { CustomerHappinessIndexMock } from 'components/customer-happiness-index/CustomerHappinessIndexResponseMocks';
import { MyProfileMock } from 'components/my-profile';
import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { DealerDetailsMock } from './dealer-details';
import { ContractsWithAllValues, ContractsWithInvalidContracts, NoContractsAvailable } from './ExampleData';
import { FinancialDetailsMock } from './financial-details';
import { IbanValidationMock } from './financial-details/bank-account-section/edit-view/iban-validation-view/IbanValidationResponseMocks';
import {
    VehicleDetailsResponseMock,
    VehicleImageResponseMock,
} from './vehicle-details-and-image/VehicleDetailsAndImageResponseMocks';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: ContractsWithAllValues,
        delay: 1000,
    },
    'With Invalid Contracts': {
        status: 200,
        responseBody: ContractsWithInvalidContracts,
    },
    'No Contracts Available': {
        status: 200,
        responseBody: NoContractsAvailable,
    },
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const ContractsMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Contracts Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getContractsEndpoint(),
};

export const ContractsResponseMockWrapper = (storyFn: () => React.ReactNode): JSX.Element => {
    setupMockResponses(CpDataApi, [
        ContractsMock,
        FinancialDetailsMock,
        DealerDetailsMock,
        MyProfileMock,
        VehicleImageResponseMock,
        VehicleDetailsResponseMock,
        IbanValidationMock,
        CustomerHappinessIndexMock,
    ]);

    return <div>{storyFn()}</div>;
};
