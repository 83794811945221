import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { GreetingUi } from './ui';
import { useGreeting } from './useGreeting';
import { GreetingLoadingPlaceholder } from '@cp-shared-6/frontend-ui';

const GreetingWithHandlers = withLoadingAndNoConnectionHandler(GreetingUi);

export const Greeting: React.FC = () => {
    const { data: greeting, isLoading } = useGreeting();

    return (
        <GreetingWithHandlers
            isLoading={isLoading}
            greeting={greeting}
            hasError={false}
            loadingPlacehloder={<GreetingLoadingPlaceholder />}
        />
    );
};
