import React from 'react';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { useParams } from 'react-router-dom';
import { ContractEndOptions } from '../../components/contract-end-options';

export const ContractEndOptionsPage: React.FC = () => {
    const { encryptedContractNumber } = useParams();
    return (
        <ContentSection pageWrapSize="medium">
            <ContractEndOptions encryptedContractNumber={encryptedContractNumber} />
        </ContentSection>
    );
};
