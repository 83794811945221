import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, useAnalyticsActionTracker } from '@cp-shared-6/frontend-ui';
import { FinancialDetails } from '../../financial-details';
import { ContractType } from '@cp-ie/common';

export type BodyProps = {
    contractNumber: string;
    encryptedContractNumber: string;
    isActive: boolean;
    contractType?: ContractType;
    outstandingBalance?: number;
    inArrears?: boolean;
};

export const Body: React.FC<BodyProps> = ({
    contractNumber,
    encryptedContractNumber,
    contractType,
    outstandingBalance,
    inArrears,
}) => {
    const { onAction } = useAnalyticsActionTracker('onOpenContractAccordion');
    const { t } = useTranslation('contracts');

    if (!contractNumber) {
        return null;
    }

    const getViewChange = (currentViewName: string): string | undefined => {
        switch (currentViewName) {
            case t('financial-details:headline'):
                return 'Financial details';
            case t('vehicle-details:headline'):
                return 'Vehicle details';
        }
    };

    let openItems = [];
    const handleOnChange = (refs: string[]): void => {
        if (openItems.length > refs.length || !refs.length) {
            openItems = [...refs];
            return;
        }
        openItems = [...refs];
        const viewChange = getViewChange(refs[refs.length - 1]);
        onAction(viewChange);
    };

    return (
        <Accordion lazyRender={true} onChange={handleOnChange}>
            <FinancialDetails
                contractNumber={contractNumber}
                encryptedContractNumber={encryptedContractNumber}
                contractType={contractType}
                outstandingBalance={outstandingBalance}
                inArrears={inArrears}
            />
        </Accordion>
    );
};
