import React from 'react';
import { Address } from '@cp-ie/common';
import { AddressList } from './AddressList';
import { useTranslation } from 'react-i18next';
import { DataOverview } from '@vwfs-bronson/bronson-react';
import { EditStatus } from '../EditStatus';
import { Notification, NotificationStatus } from '@cp-shared-6/frontend-ui';
import { ErrorNotification } from '../../../notifications/error/ErrorNotification';

export type ConsultViewProps = {
    startEditing: () => void;
    address: Address;
    lastEditStatus: EditStatus;
};

export const ConsultView: React.FC<ConsultViewProps> = ({ startEditing, address, lastEditStatus }) => {
    const { t } = useTranslation('my-profile-address-details');

    if (!address) {
        return null;
    }

    const notification =
        lastEditStatus === EditStatus.SUCCESS ? (
            <Notification
                status={NotificationStatus.warning}
                headline={t('edit-view.notification-success.headline')}
                text={t('edit-view.notification-success.text')}
                testId={'success-notification'}
            />
        ) : (
            <ErrorNotification
                text={
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('edit-view.notification-error.text'),
                        }}
                    />
                }
                testId={'error-notification'}
            />
        );

    return (
        <DataOverview
            title={t('consult-view.title')}
            buttonLabel={t('translation:editable-section-nav.start')}
            buttonProps={{ onClick: startEditing, testId: 'edit-button', secondary: true, icon: '', link: false }}
        >
            <AddressList address={address} />
            {lastEditStatus !== EditStatus.NOT_PERFORMED && notification}
        </DataOverview>
    );
};
